import * as React from 'react'
import { connect } from 'react-redux'
import { push as pushRoute } from 'react-router-redux'
import { StateShape as ReduxStateShape } from '../reducers'
import { ThunkDispatch } from '../actions/util'
import { updateEmailConfirmationToken } from '../actions/emailConfirmationTokens'
import { match as MatchShape } from 'react-router'
import TopBar from './TopBar'
import './EmailConfirmedPage.scss'

interface StateShape {
    errorMessage: string | null
}

interface PassedProps {
    match: MatchShape<{ token: string }>
}

interface InjectedProps {
    dispatch: ThunkDispatch
}

type PropsShape = PassedProps & InjectedProps

class EmailConfirmedPage extends React.Component<PropsShape, StateShape> {
    state = {
        errorMessage: null,
    }

    componentDidMount() {
        this.confirmEmail()
    }

    render() {
        const { errorMessage } = this.state
        return (
            <>
                <TopBar withSearch={true} logo="main" />
                <div className="EmailConfirmedPage">
                    {errorMessage ? (
                        <p className="error-message"> {errorMessage} </p>
                    ) : (
                        <p className="success-message">
                            Thank you, your email has been confirmed.
                        </p>
                    )}

                    <a className="continue-home-button" href="/">
                        Continue To Home
                    </a>
                </div>
            </>
        )
    }

    private confirmEmail = () => {
        const { dispatch, match } = this.props
        dispatch(updateEmailConfirmationToken(match.params.token))
            .catch((e) => this.setState({ errorMessage: e.message }))
            .then(() => dispatch(pushRoute('/upgrade')))
    }
}

function mapStateToProps(state: ReduxStateShape, props: PropsShape) {
    return {}
}

export default connect(mapStateToProps)(EmailConfirmedPage)
