import { Action, ActionWithPayload } from './Action'
import { QuestionFilters } from '../reducers/app'

export const UPDATE_QUESTION_FILTERS = 'UPDATE_QUESTION_FILTERS'
export type UPDATE_QUESTION_FILTERS = typeof UPDATE_QUESTION_FILTERS

export const UPDATE_TEST_PACK_FILTERS = 'UPDATE_TEST_PACK_FILTERS'
export type UPDATE_TEST_PACK_FILTERS = typeof UPDATE_TEST_PACK_FILTERS

export const DISPLAY_SIGNUP = 'DISPLAY_SIGNUP'
export type DISPLAY_SIGNUP = typeof DISPLAY_SIGNUP

export const CLOSE_MODAL = 'CLOSE_MODAL'
export type CLOSE_MODAL = typeof CLOSE_MODAL

export const DISPLAY_LOGIN = 'DISPLAY_LOGIN'
export type DISPLAY_LOGIN = typeof DISPLAY_LOGIN

export const DISPLAY_UPGRADE = 'DISPLAY_UPGRADE'
export type DISPLAY_UPGRADE = typeof DISPLAY_UPGRADE

export const DISPLAY_TEST_GENERATOR = 'DISPLAY_TEST_GENERATOR'
export type DISPLAY_TEST_GENERATOR = typeof DISPLAY_TEST_GENERATOR

export const DISPLAY_PASSWORD_RESET = 'DISPLAY_PASSWORD_RESET'
export type DISPLAY_PASSWORD_RESET = typeof DISPLAY_PASSWORD_RESET

export const REVEAL_DROPDOWN_MENU = 'REVEAL_DROPDOWN_MENU'
export type REVEAL_DROPDOWN_MENU = typeof REVEAL_DROPDOWN_MENU

export const HIDE_DROPDOWN_MENU = 'HIDE_DROPDOWN_MENU'
export type HIDE_DROPDOWN_MENU = typeof HIDE_DROPDOWN_MENU

export type UpdateFiltersAction = ActionWithPayload<
    UPDATE_QUESTION_FILTERS,
    Partial<QuestionFilters>
>
export type UpdateTestPackFiltersAction = ActionWithPayload<
    UPDATE_TEST_PACK_FILTERS,
    Partial<QuestionFilters>
>
export type CloseModalAction = Action<CLOSE_MODAL>
export type DisplaySignupAction = Action<DISPLAY_SIGNUP>
export type DisplayPasswordReset = Action<DISPLAY_PASSWORD_RESET>
export type DisplayLoginAction = Action<DISPLAY_LOGIN>
export type DisplayUpgradeAction = Action<DISPLAY_UPGRADE>
export type RevealDropdownMenuAction = Action<REVEAL_DROPDOWN_MENU>
export type HideDropdownMenuAction = Action<HIDE_DROPDOWN_MENU>
export type DisplayTestGeneratorAction = Action<DISPLAY_TEST_GENERATOR>

export type AppAction =
    | UpdateFiltersAction
    | UpdateTestPackFiltersAction
    | CloseModalAction
    | DisplaySignupAction
    | DisplayLoginAction
    | DisplayUpgradeAction
    | DisplayPasswordReset
    | RevealDropdownMenuAction
    | HideDropdownMenuAction
    | DisplayTestGeneratorAction

export function updateFilters(
    filterUpdate: Partial<QuestionFilters>
): UpdateFiltersAction {
    return { type: UPDATE_QUESTION_FILTERS, payload: filterUpdate }
}

export function updateTestPackFilters(
    filterUpdate: Partial<QuestionFilters>
): UpdateTestPackFiltersAction {
    return { type: UPDATE_TEST_PACK_FILTERS, payload: filterUpdate }
}

export function displaySignup() {
    return { type: DISPLAY_SIGNUP }
}

export function displayLogin() {
    return { type: DISPLAY_LOGIN }
}

export function displayUpgrade() {
    return { type: DISPLAY_UPGRADE }
}

export function displayTestGenerator() {
    return { type: DISPLAY_TEST_GENERATOR }
}

export function displayPasswordReset() {
    return { type: DISPLAY_PASSWORD_RESET }
}

export function closeModal() {
    return { type: CLOSE_MODAL }
}

export function revealDropdownMenu() {
    return { type: REVEAL_DROPDOWN_MENU }
}

export function hideDropdownMenu() {
    return { type: HIDE_DROPDOWN_MENU }
}
