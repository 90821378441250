import * as React from 'react'
import { StrictMode } from 'react'
import { ThunkDispatch } from '../actions/util'
import { StateShape as ReduxStateShape } from '../reducers'
import { UserFactory, User } from '../models'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import { revealDropdownMenu, hideDropdownMenu } from '../actions/app'
import FiltersBar from './FiltersBar'
import TopBar from './TopBar'
import * as H from 'history'
import DropdownMenu from './DropdownMenu'
import FloatingMenuPill from '../components/FloatingMenuPill'
import FiltersPage from './FiltersPage'
import { CSSTransition } from 'react-transition-group'
import TestPackBar from './TestPackBar'
import { featuredCourses } from './HomePage'
import { fetchCourse } from '../actions/courses'
import './App.scss'

interface StateShape {
    showFiltersOverlay: boolean
}

interface PropsShape {
    dispatch: ThunkDispatch
    location: H.Location
    isDropdownMenuExpanded: boolean
    authedUser: User | null
}

class App extends React.Component<
    PropsShape & RouteComponentProps<null>,
    StateShape
> {
    state: StateShape = {
        showFiltersOverlay: false
    }

    componentDidMount() {
        const { dispatch } = this.props

        for (let course of featuredCourses) {
            dispatch(fetchCourse(course))
        }
    }

    render() {
        const { isDropdownMenuExpanded } = this.props
        const { showFiltersOverlay } = this.state
        return (
            <div className="App">
                <StrictMode>
                    <TopBar withSearch={true} logo="main" />

                    <CSSTransition
                        classNames="appear"
                        timeout={300}
                        in={isDropdownMenuExpanded}
                        unmountOnExit={true}
                    >
                        <DropdownMenu
                            onMenuItemClick={this.toggleDropdownMenu}
                        />
                    </CSSTransition>

                    <TestPackBar />
                    <FiltersBar />

                    <CSSTransition
                        classNames="slide-in-from-bottom"
                        timeout={300}
                        appear={true}
                        in={showFiltersOverlay}
                        unmountOnExit={true}
                    >
                        <FiltersPage
                            closeFiltersPage={() =>
                                this.setState({
                                    showFiltersOverlay: false
                                })
                            }
                        />
                    </CSSTransition>

                    <FloatingMenuPill
                        toggleFiltersPage={this.toggleFiltersPage}
                    />
                </StrictMode>
            </div>
        )
    }

    private toggleDropdownMenu = () => {
        const { isDropdownMenuExpanded, dispatch } = this.props
        if (isDropdownMenuExpanded) {
            dispatch(hideDropdownMenu())
        } else {
            dispatch(revealDropdownMenu())
        }
    }

    private toggleFiltersPage = () => {
        const { showFiltersOverlay } = this.state
        this.setState({ showFiltersOverlay: !showFiltersOverlay })
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const isDropdownMenuExpanded = state.app.isDropdownMenuExpanded
    const authedUserId = state.auth && state.auth.user_id
    let authedUser = null
    if (authedUserId) {
        authedUser = UserFactory.fromState(state, authedUserId) ?? null
    }

    return { isDropdownMenuExpanded, authedUser }
}

export default withRouter(connect(mapStateToProps)(App))
