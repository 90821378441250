import * as React from 'react'
import { Input } from 'semantic-ui-react'

import "./TextFilter.scss"

interface StateShape {}

interface PropsShape {
    currentSelection: string
    onChange: (selection: string) => void
    placeholder: string
    value?: string;
    disabled?: boolean;
}

export default class TextFilter extends React.Component<
    PropsShape,
    StateShape
> {
    render() {
        const { currentSelection, placeholder, disabled, value } = this.props
        return (
            <div className="TextFilter">
                <Input
                    onChange={this.handleChange}
                    placeholder={placeholder}
                    value={currentSelection || value}
                    disabled={disabled}
                />
            </div>
        )
    }

    handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const { onChange } = this.props
        onChange(e.currentTarget.value)
    }
}