import * as React from 'react'
import { Container } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Switch, Route } from 'react-router'
import * as qs from 'qs'
import config from '../app.config'

import './Sitemap.scss'

interface StateShape {
    links: { url: string; text: string }[]
    page?: number
    pageCount?: number
}
interface PropsShape {}

class Sitemap extends React.Component<PropsShape, StateShape> {
    state: StateShape = {
        links: []
    }

    componentDidMount() {
        this.fetchQuestions()
    }

    render() {
        return (
            <Switch>
                <Route
                    path="/sitemap/questions"
                    render={() => {
                        return (
                            <Container className="Sitemap">
                                {this.questions}
                            </Container>
                        )
                    }}
                />
                <Route
                    path="/sitemap"
                    exact={true}
                    render={() => {
                        return (
                            <Container className="Sitemap">
                                <Container className="sitemap-heading">
                                    <h1> IntellyQ: Sitemap </h1>
                                </Container>

                                <h2> Home Page </h2>
                                <ul>
                                    <li>
                                        <Link to="/">Home Page</Link>
                                    </li>
                                </ul>

                                <h2> Features </h2>
                                <ul>
                                    <li>
                                        <Link to="/browse">Browse</Link>
                                    </li>
                                    <li>
                                        <Link to="/test-yourself">
                                            Test Yourself
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/contribute">Contribute</Link>
                                    </li>
                                    <li>
                                        <Link to="/upgrade">Upgrade</Link>
                                    </li>
                                </ul>

                                <h2>Questions</h2>
                                <ul>
                                    <li>
                                        <Link to="/sitemap/questions">
                                            Questions
                                        </Link>
                                    </li>
                                </ul>
                            </Container>
                        )
                    }}
                />
            </Switch>
        )
    }

    private get questions() {
        const { links, page, pageCount } = this.state
        return (
            <ul className="question-links">
                {links.map(l => (
                    <li key={l.url}>
                        <a href={l.url}>{l.text}</a>
                    </li>
                ))}
                {page && pageCount && pageCount > page && (
                    <a href={`/sitemap/questions?page=${page + 1}`}>next</a>
                )}
            </ul>
        )
    }

    private fetchQuestions = () => {
        const queryParams = qs.parse(window.location.search, {
            ignoreQueryPrefix: true
        })
        let page = queryParams.page ? parseInt(queryParams.page as string, 10) : 1
        fetch(`${config.apiUrl}/sitemap/questions.json?page=${page}`)
            .then(resp => Promise.all([resp.json(), resp.headers]))
            .then(([j, h]) => {
                const pageCount = parseInt(h.get('X-Pages') as string, 10)
                this.setState({ links: j, page, pageCount })
            })
    }
}

export default Sitemap
