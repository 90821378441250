import * as React from 'react'
import * as lodash from 'lodash'
import { Button } from 'semantic-ui-react'
import { DocumentInfo } from '../../containers/ContributionForm'
import { ReactSVG } from 'react-svg'

interface PropsShape {
    documentInfo: DocumentInfo
    updateDocumentInfo: (update: Partial<DocumentInfo>) => void
}

export default class DocumentPreview extends React.Component<PropsShape> {
    render() {
        return <div className="DocumentPreview">{this.previewFile}</div>
    }

    get previewFile() {
        const { documentInfo } = this.props
        const imageButtonPairs = lodash.map(
            documentInfo.documentPreview as { [k: string]: string },
            (v, k) => {
                if (v.includes('data:application/pdf')) {
                    return [
                        <div className="preview-pdf">
                            <ReactSVG
                                wrapper="span"
                                className="preview-pdf-icon"
                                src="/checkmark.svg"
                            />
                            {k}
                        </div>,
                        <Button
                            className="preview-image-remove-button pdf"
                            key={`Button:${k}`}
                            onClick={() => this.removeFile(k)}
                        >
                            X
                        </Button>,
                    ]
                }
                return [
                    <img
                        className="preview-image"
                        key={k}
                        src={v}
                        alt="Preview"
                    />,
                    <Button
                        className="preview-image-remove-button"
                        key={`Button:${k}`}
                        onClick={() => this.removeFile(k)}
                    >
                        X
                    </Button>,
                ]
            }
        )
        return lodash.flatten(imageButtonPairs)
    }

    removeFile = (imageName: string) => {
        const { updateDocumentInfo, documentInfo } = this.props
        updateDocumentInfo({
            document: lodash.omit(
                documentInfo.document as { [k: string]: File },
                imageName
            ),
            documentPreview: lodash.omit(
                documentInfo.documentPreview as { [k: string]: string },
                imageName
            ),
        })
    }
}
