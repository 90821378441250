import * as React from 'react'
import { QuestionDetailed, QuestionBare } from '../../models'
import { Container, Grid } from 'semantic-ui-react'

import './KeyDetails.scss'

interface StateShape {}
interface PropsShape {
    question: QuestionDetailed | QuestionBare
}

export default class KeyDetails extends React.Component<
    PropsShape,
    StateShape
> {
    render() {
        const { question } = this.props
        return (
            <Container className="KeyDetails">
                <h3 className="table-header"> Question Details </h3>
                <div className="table-content">
                    <Grid.Row stackable={true} columns={2} centered={true}>
                        <Grid.Column width={16}>
                            <span className="key-details-title">
                                University
                            </span>
                            <span className="key-details-value">
                                {question.data.course.university_name}
                            </span>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <span className="key-details-title">Course</span>
                            <span className="key-details-value">
                                {`${question.data.course.course_code} - ${
                                    question.data.course.name
                                }`}
                            </span>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <span className="key-details-title">Test Type</span>
                            <span className="key-details-value">
                                {question.data.test_type}
                            </span>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <span className="key-details-title">Year</span>
                            <span className="key-details-value">
                                {question.data.year}
                            </span>
                        </Grid.Column>
                        {question.data.semester && (
                            <Grid.Column width={16}>
                                <span className="key-details-title">
                                    Semester
                                </span>
                                <span className="key-details-value">
                                    {question.data.semester}
                                </span>
                            </Grid.Column>
                        )}
                        {question.data.subtopic && (
                            <Grid.Column width={16}>
                                <span className="key-details-title">
                                    Subtopic
                                </span>
                                <span className="key-details-value">
                                    {question.data.subtopic}
                                </span>
                            </Grid.Column>
                        )}
                    </Grid.Row>
                </div>
            </Container>
        )
    }
}
