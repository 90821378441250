import { makeRequest, RestSideEffect } from './util'
import * as api from '../rest/api'
import * as repr from '../rest/representers'
import { Action, ActionWithError, ActionWithPayload } from './Action'
import Resource from '../rest/Resource'
import { DocumentInfo } from '../containers/ContributionForm'

export const CREATE_CONTRIBUTION = 'CREATE_CONTRIBUTION'
export type CREATE_CONTRIBUTION = typeof CREATE_CONTRIBUTION

export const RECEIVE_CONTRIBUTION = 'RECEIVE_CONTRIBUTION'
export type RECEIVE_CONTRIBUTION = typeof RECEIVE_CONTRIBUTION

export const CREATE_CONTRIBUTION_FAILURE = 'CREATE_CONTRIBUTION_FAILURE'
export type CREATE_CONTRIBUTION_FAILURE = typeof CREATE_CONTRIBUTION_FAILURE

export type RequestContributionActionType = CREATE_CONTRIBUTION
export type ReceiveContributionActionType = RECEIVE_CONTRIBUTION
export type RequestContributionFailedActionType = CREATE_CONTRIBUTION_FAILURE

export type CreateContributionAction = Action<CREATE_CONTRIBUTION>
export type ReceiveContributionAction = ActionWithPayload<
    RECEIVE_CONTRIBUTION,
    Resource<repr.Contribution.Base>
>
export type CreateContributionFailedAction = ActionWithError<
    CREATE_CONTRIBUTION_FAILURE,
    Error
>

export type ContributionAction =
    | CreateContributionAction
    | ReceiveContributionAction
    | CreateContributionFailedAction

export function createContribution(
    formData: DocumentInfo
): RestSideEffect<repr.Contribution.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: CREATE_CONTRIBUTION },
                { action: RECEIVE_CONTRIBUTION },
                { action: CREATE_CONTRIBUTION_FAILURE },
                ({ authToken }) => api.postContribution(authToken, formData)
            )
        )
    }
}
