import { makeRequest, RestSideEffect } from './util'
import * as api from '../rest/api'
import * as repr from '../rest/representers'
import { Action, ActionWithError, ActionWithPayload } from './Action'
import Resource from '../rest/Resource'

export const FETCH_UNIVERSITIES = 'FETCH_UNIVERSITIES'
export type FETCH_UNIVERSITIES = typeof FETCH_UNIVERSITIES

export const RECEIVE_UNIVERSITIES = 'RECEIVE_UNIVERSITIES'
export type RECEIVE_UNIVERSITIES = typeof RECEIVE_UNIVERSITIES

export const FETCH_UNIVERSITIES_FAILURE = 'FETCH_UNIVERSITIES_FAILURE'
export type FETCH_UNIVERSITIES_FAILURE = typeof FETCH_UNIVERSITIES_FAILURE

export type RequestUniversitiesActionType = FETCH_UNIVERSITIES
export type ReceiveUniversitiesActionType = RECEIVE_UNIVERSITIES
export type RequestUniversitiesFailedActionType = FETCH_UNIVERSITIES_FAILURE

export type RequestUniversitiesAction = Action<FETCH_UNIVERSITIES>
export type ReceiveUniversitiesAction = ActionWithPayload<
    RECEIVE_UNIVERSITIES,
    Resource<repr.University.Base[]>
>
export type RequestUniversitiesFailedAction = ActionWithError<
    FETCH_UNIVERSITIES_FAILURE,
    Error
>

export type UniversityAction =
    | RequestUniversitiesAction
    | ReceiveUniversitiesAction
    | RequestUniversitiesFailedAction

export function fetchUniversities(): RestSideEffect<repr.University.Base[]> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: FETCH_UNIVERSITIES },
                { action: RECEIVE_UNIVERSITIES },
                { action: FETCH_UNIVERSITIES_FAILURE },
                () => api.getUniversities()
            )
        )
    }
}
