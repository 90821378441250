import * as React from 'react'
import { snakeCase } from 'lodash'
import * as repr from '../rest/representers'
import { Card } from 'semantic-ui-react'
import { ThunkDispatch } from '../actions/util'
import { QuestionDetailed, QuestionBare } from '../models'
import ViewAnswerButton from './ViewAnswerButton'
import './QuestionCard.scss'
import { ReactSVG } from 'react-svg'

interface PropsShape {
    dispatch: ThunkDispatch
    auth: repr.Auth.Base | null
    question: QuestionDetailed | QuestionBare
    clickable: boolean
}

interface StateShape {}

export default class QuestionCard extends React.PureComponent<
    PropsShape,
    StateShape
> {
    render() {
        const { clickable, question, auth, dispatch } = this.props
        return (
            <Card
                className={`QuestionCard ${clickable ? 'clickable' : ''}`}
                as="a"
                {...(clickable
                    ? { href: this.questionUrl, target: '_blank' }
                    : {})}
            >
                <div className="card-details-top">
                    <div className="main-details">
                        <div className="card-detail">
                            <ReactSVG
                                wrapper="span"
                                className="university-icon"
                                src="/college-cap.svg"
                            />
                            {question.data.course.university_name}
                        </div>

                        <div className="card-detail">
                            <ReactSVG
                                wrapper="span"
                                className="course-icon"
                                src="/books-stack.svg"
                            />
                            {question.data.course.course_code} -{' '}
                            {question.data.course.name}
                        </div>
                    </div>

                    <div className="card-detail additional-details">
                        {this.additionalDetails}
                    </div>
                </div>
                <Card.Header>
                    <h5 className="question noselect">
                        {question.data.question}
                    </h5>
                </Card.Header>
                <Card.Description>
                    {question.data.answers.map((a, i) => (
                        <div
                            className={`answer noselect ${
                                i === question.data.answers.length - 1
                                    ? 'last-answer'
                                    : ''
                            }`}
                            key={a.id}
                        >
                            {a.answer}
                        </div>
                    ))}
                </Card.Description>

                <ViewAnswerButton
                    auth={auth}
                    dispatch={dispatch}
                    correctAnswer={
                        question instanceof QuestionDetailed
                            ? question.data.correct_answer
                            : null
                    }
                    solution={
                        question instanceof QuestionDetailed
                            ? question.data.solution[0] || null
                            : null
                    }
                />
            </Card>
        )
    }

    get questionUrl() {
        const { question } = this.props
        const url = window.location.origin
        let universityName = snakeCase(question.data.course.university_name)
        universityName = universityName.split('_').join('-')
        return `${url}/question/${universityName}-${
            question.data.course.course_code
        }-${question.data.year ? `${question.data.year}-` : ''}${
            question.data.id
        }`
    }

    get testType() {
        const { question } = this.props
        if (!question.data.test_type) {
            return ''
        }

        if (question.data.test_type === 'TT1') {
            return 'Term Test 1'
        } else if (question.data.test_type === 'TT2') {
            return 'Term Test 2'
        } else if (question.data.test_type === 'TT3') {
            return 'Term Test 3'
        } else {
            return question.data.test_type
        }
    }

    get additionalDetails() {
        const { question } = this.props
        if (question.data.year && this.testType) {
            return (
                <>
                    {this.testType}{' '}
                    <span className="bottom-details-seperator">·</span>{' '}
                    {question.data.year}
                </>
            )
        } else if (question.data.year) {
            return question.data.year
        } else if (this.testType) {
            return this.testType
        }
        return ''
    }
}
