import * as React from 'react'
import { ThunkDispatch } from '../actions/util'
import { snakeCase } from 'lodash'
import * as repr from '../rest/representers'
import { StateShape as ReduxStateShape } from '../reducers'
import { Search, SearchCategoryProps, SearchProps } from 'semantic-ui-react'
import { push as pushRoute } from 'react-router-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import { search } from '../actions/search'
import {
    CourseFactory,
    Course,
    UniversityFactory,
    University,
    QuestionBareFactory,
    QuestionBare,
} from '../models'
import { connect } from 'react-redux'

import './SearchBar.scss'
import { updateTestPackFilters } from '../actions/app'

const universityIcon = '/college-cap.svg'
const courseIcon = '/books-stack.svg'
const subtopicIcon = '/open-book.svg'

interface StateShape {
    searchText: string
}

interface PropsShape {
    dispatch: ThunkDispatch
    questionsForSearch: QuestionBare[]
    coursesForSearch: Course[]
    universitiesForSearch: University[]
}

interface Result {
    title: string
    description?: string
    content?: string
    key: string
    _onClick: () => void
}

interface ResultCategory {
    [key: string]: {
        name: string
        results: Result[]
    }
}

class SearchBar extends React.Component<
    PropsShape & RouteComponentProps<null>,
    StateShape
> {
    state = {
        searchText: '',
    }

    timeoutId: number = 0

    render() {
        const { searchText } = this.state
        return (
            <>
                <Search
                    category={true}
                    className="SearchBar"
                    categoryRenderer={this.renderCategory}
                    input={{
                        icon: 'search',
                        iconPosition: undefined,
                        placeholder: 'Try searching for a question...',
                    }}
                    onSearchChange={this.handleChange}
                    onResultSelect={(e, p) => {
                        p.result._onClick()
                    }}
                    results={this.results}
                    selectFirstResult={true}
                    value={searchText}
                />
            </>
        )
    }

    renderCategory = (props: SearchCategoryProps) => {
        let icon
        if (props.name === 'Questions') {
            icon = subtopicIcon
        } else if (props.name === 'Courses') {
            icon = courseIcon
        } else {
            icon = universityIcon
        }

        return (
            <div
                className={`category ${
                    props.name === 'Questions' ? 'question' : ''
                }`}
            >
                <img
                    className="icon"
                    style={{ verticalAlign: 'middle' }}
                    src={icon}
                    alt=""
                />
                <span className="category-label">{props.name}</span>
            </div>
        )
    }

    handleChange = (
        e: React.MouseEvent<HTMLElement>,
        { value }: SearchProps
    ) => {
        if (value) {
            this.fetchSearchResults(value)
        } else {
            this.setState({ searchText: '' })
        }
    }

    fetchSearchResults = (searchText: string) => {
        const { dispatch } = this.props
        window.clearTimeout(this.timeoutId)

        this.timeoutId = window.setTimeout(() => {
            dispatch(search(searchText))
        }, 500)
        this.setState({ searchText: searchText })
    }

    get results() {
        let results: ResultCategory = {}

        if (this.courseSearchItems.length > 0) {
            results.courses = {
                name: 'Courses',
                results: this.courseSearchItems,
            }
        }

        if (this.questionSearchItems.length > 0) {
            results.questions = {
                name: 'Questions',
                results: this.questionSearchItems,
            }
        }

        /* if (this.universitySearchItems.length > 0) {
            results.universities = {
                name: 'Universities',
                results: this.universitySearchItems
            }
        } */

        return results
    }

    get courseSearchItems(): Result[] {
        const { coursesForSearch } = this.props
        return coursesForSearch.map((c) => ({
            title: `${c.data.course_code} - ${c.data.name}`,
            description: `${c.data.university_name}`,
            key: c.data.id,
            _onClick: () => this.handleSelectCourse(c),
        }))
    }

    handleSelectCourse = (c: Course) => {
        const { dispatch } = this.props
        dispatch(
            updateTestPackFilters({ course: c.data.course_code, testType: [] })
        )
        dispatch(pushRoute('/browse'))
        this.setState({ searchText: '' })
    }

    get universitySearchItems(): Result[] {
        const { universitiesForSearch } = this.props
        return universitiesForSearch.map((u) => ({
            title: `${u.data.name}`,
            description: '',
            key: u.data.name,
            _onClick: () => this.handleSelectUniversity(u),
        }))
    }

    handleSelectUniversity = (u: University) => {
        const { dispatch } = this.props
        dispatch(pushRoute('/app?university=' + u.data.name))
        this.setState({ searchText: '' })
    }

    get questionSearchItems(): Result[] {
        const { questionsForSearch } = this.props
        return questionsForSearch.map((q) => ({
            title: `${q.data.question}`,
            description: this.buildAnswerOptions(q.data.answers),
            key: q.data.id,
            _onClick: () => this.handleSelectQuestion(q),
        }))
    }

    buildAnswerOptions(answers: repr.Answer.Base[]) {
        let answerOptions: string = ''
        let curr = 0
        answers.forEach((a) => {
            if (curr < 2) {
                answerOptions += `${a.answer}\n`
            } else if (curr === 2) {
                answerOptions += `...`
            }
            curr += 1
        })
        return answerOptions
    }

    handleSelectQuestion = (q: QuestionBare) => {
        const { dispatch } = this.props
        dispatch(pushRoute(this.buildQuestionUrl(q)))
        this.setState({ searchText: '' })
    }

    private buildQuestionUrl = (question: QuestionBare) => {
        let universityName = snakeCase(question.data.course.university_name)
        universityName = universityName.split('_').join('-')
        return `/question/${universityName}-${
            question.data.course.course_code
        }-${question.data.year ? `${question.data.year}-` : ''}${
            question.data.id
        }`
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const {
        courseIdsForSearch,
        universityNamesForSearch,
        questionIdsForSearch,
    } = state.remote.sets
    const coursesForSearch = CourseFactory.fromState(state, courseIdsForSearch)
    const universitiesForSearch = UniversityFactory.fromState(
        state,
        universityNamesForSearch
    )
    const questionsForSearch = QuestionBareFactory.fromState(
        state,
        questionIdsForSearch
    )

    return {
        coursesForSearch,
        universitiesForSearch,
        questionsForSearch,
    }
}

export default withRouter(connect(mapStateToProps)(SearchBar))
