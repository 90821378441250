import * as React from 'react'
import * as lodash from 'lodash'
import { ThunkDispatch } from '../actions/util'
import {
    Container,
    Header,
    Button,
    Dropdown,
    Menu,
    Checkbox,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { StateShape as ReduxStateShape } from '../reducers'
import { closeModal } from '../actions/app'
import { createTest } from '../actions/tests'
import { fetchCourses } from '../actions/courses'
import { CourseFactory, Course } from '../models'
import { TestType } from '../reducers/app'

import './TestGenerator.scss'

interface StateShape {
    errorMessage: string | null
    courseId: string
    testType: TestType[]
}

interface PropsShape {
    dispatch: ThunkDispatch
    onClose?: () => void
    courses: Course[]
}

class TestGenerator extends React.Component<PropsShape, StateShape> {
    state = {
        courseId: '',
        testType: [],
        errorMessage: null,
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(fetchCourses())
        document.addEventListener('keydown', this.handleEnterKeyPress, false)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEnterKeyPress, false)
    }

    render() {
        const { errorMessage, courseId } = this.state
        return (
            <div className="TestGenerator">
                <img
                    className="close-icon"
                    src="/close.svg"
                    alt="close icon"
                    onClick={this.closeModal}
                />
                <div className="content">
                    <Header.Subheader as="h2" className="modal-header">
                        Test Yourself
                    </Header.Subheader>
                    <Container className="simulator-message" text={true}>
                        Select your criteria and let IntellyQ generate a custom
                        test for you!
                    </Container>

                    <h4 className="criteria-header">Course</h4>
                    <Dropdown
                        className="course-dropdown"
                        placeholder="Select Course"
                        fluid
                        selection
                        options={this.courseOptions}
                        value={courseId}
                        onChange={(e, c) =>
                            this.setState({
                                courseId: c.value as string,
                            })
                        }
                    />

                    <h4 className="criteria-header">Test Type</h4>
                    {this.testTypeFilter}

                    <Button
                        className={`generate-test-button ${
                            this.isSelectionComplete() && 'active'
                        }`}
                        onClick={this.createTest}
                    >
                        Generate Test
                    </Button>

                    {errorMessage && (
                        <p className="error-message"> {errorMessage} </p>
                    )}
                </div>
            </div>
        )
    }

    private get courseOptions() {
        const { courses } = this.props
        let courseOptions: { [k: string]: string }[] = []
        courses.forEach((c) => {
            courseOptions.push({
                key: c.data.id,
                text: `${c.data.course_code} - ${c.data.university_name}`,
                value: c.data.id,
            })
        })
        return courseOptions
    }

    private isSelectionComplete = () => {
        const { courseId, testType } = this.state
        return courseId !== '' && testType.length > 0
    }

    private hasEnoughQuestionsForTest = () => {
        const { testType, courseId } = this.state
        const { courses } = this.props
        const course = courses.find((c) => c.data.id === courseId)

        if (!course) {
            return false
        }

        let numberOfQuestions = 0
        lodash.forEach(testType, (t) => {
            numberOfQuestions +=
                course.data.number_of_questions_by_test_type[
                    t as 'TT1' | 'TT2' | 'TT3' | 'Midterm' | 'Final'
                ]
        })

        return numberOfQuestions >= 10
    }

    private handleTestTypeChange = (selectedTestTypes: TestType) => {
        const { testType } = this.state
        let testList
        if (lodash.includes(testType, selectedTestTypes)) {
            testList = lodash.reject(testType, (t) => t === selectedTestTypes)
        } else {
            testList = [...testType, selectedTestTypes]
        }
        this.setState({ testType: testList })
    }

    private get testTypeFilter() {
        const { testType, courseId } = this.state
        const { courses } = this.props
        const course = courses.find((c) => c.data.id === courseId)
        return (
            <div className="TestTypeFilter">
                <Menu borderless={true}>
                    <Menu.Item>
                        <Checkbox
                            label="Term Test 1"
                            checked={lodash.includes(testType, TestType.TT1)}
                            onChange={() =>
                                this.handleTestTypeChange(TestType.TT1)
                            }
                        />
                        {course && (
                            <div className="number-of-questions">
                                {`
                                    (${course.data.number_of_questions_by_test_type.TT1} questions)
                                `}
                            </div>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        <Checkbox
                            label="Midterm"
                            checked={lodash.includes(
                                testType,
                                TestType.Midterm
                            )}
                            onChange={() =>
                                this.handleTestTypeChange(TestType.Midterm)
                            }
                        />
                        {course && (
                            <div className="number-of-questions">
                                {`
                                    (${course.data.number_of_questions_by_test_type.Midterm} questions)
                                `}
                            </div>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        <Checkbox
                            label="Term Test 2"
                            checked={lodash.includes(testType, TestType.TT2)}
                            onChange={() =>
                                this.handleTestTypeChange(TestType.TT2)
                            }
                        />
                        {course && (
                            <div className="number-of-questions">
                                {`
                                    (${course.data.number_of_questions_by_test_type.TT2} questions)
                                `}
                            </div>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        <Checkbox
                            label="Term Test 3"
                            checked={lodash.includes(testType, TestType.TT3)}
                            onChange={() =>
                                this.handleTestTypeChange(TestType.TT3)
                            }
                        />
                        {course && (
                            <div className="number-of-questions">
                                {`
                                    (${course.data.number_of_questions_by_test_type.TT3} questions)
                                `}
                            </div>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        <Checkbox
                            label="Final"
                            checked={lodash.includes(testType, TestType.Final)}
                            onChange={() =>
                                this.handleTestTypeChange(TestType.Final)
                            }
                        />
                        {course && (
                            <div className="number-of-questions">
                                {`
                                    (${course.data.number_of_questions_by_test_type.Final} questions)

                                `}
                            </div>
                        )}
                    </Menu.Item>
                </Menu>
            </div>
        )
    }

    private closeModal = () => {
        const { dispatch, onClose } = this.props
        if (onClose) {
            onClose()
        }
        dispatch(closeModal())
    }

    private createTest = () => {
        const { dispatch } = this.props
        const { courseId, testType } = this.state

        if (!this.isSelectionComplete()) {
            return
        }

        if (!this.hasEnoughQuestionsForTest()) {
            this.setState({
                errorMessage:
                    "There aren't enough questions in your criteria to build a test.",
            })
            return
        }

        dispatch(createTest(courseId, testType as TestType[]))
            .then(() => {
                dispatch(closeModal())
            })
            .catch((e) => this.setState({ errorMessage: e.message }))
    }

    private handleEnterKeyPress = (evt: KeyboardEvent) => {
        if (evt.keyCode === 13) {
            this.createTest()
        }
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const courses = CourseFactory.fromState(state)
    return { courses }
}

export default connect(mapStateToProps)(TestGenerator)
