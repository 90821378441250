import * as repr from '../rest/representers'
import * as api from '../rest/api'
import Resource from '../rest/Resource'
import { makeRequest, RestSideEffect } from './util'
import { Action, ActionWithError, ActionWithPayload } from './Action'

export const CREATE_USER = 'CREATE_USER'
export type CREATE_USER = typeof CREATE_USER

export const RECEIVE_USER = 'RECEIVE_USER'
export type RECEIVE_USER = typeof RECEIVE_USER

export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'
export type CREATE_USER_FAILURE = typeof CREATE_USER_FAILURE

export const FETCH_USER = 'FETCH_USER'
export type FETCH_USER = typeof FETCH_USER

export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export type FETCH_USER_FAILURE = typeof FETCH_USER_FAILURE

export const UPDATE_USER = 'UPDATE_USER'
export type UPDATE_USER = typeof UPDATE_USER

export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'
export type UPDATE_USER_FAILURE = typeof UPDATE_USER_FAILURE

export type RequestUserActionType = CREATE_USER | FETCH_USER | UPDATE_USER
export type ReceiveUserActionType = RECEIVE_USER
export type RequestUserFailedActionType =
    | CREATE_USER_FAILURE
    | FETCH_USER_FAILURE
    | UPDATE_USER_FAILURE

export type CreateUserAction = Action<CREATE_USER>
export type ReceiveUserAction = ActionWithPayload<
    RECEIVE_USER,
    Resource<repr.User.Base>
>
export type CreateUserFailedAction = ActionWithError<CREATE_USER_FAILURE, Error>

export type FetchUserAction = Action<FETCH_USER>
export type FetchUserFailedAction = ActionWithError<FETCH_USER_FAILURE, Error>

export type UpdateUserAction = Action<UPDATE_USER>
export type UpdateUserFailedAction = ActionWithError<UPDATE_USER_FAILURE, Error>

export type UserAction =
    | CreateUserAction
    | ReceiveUserAction
    | CreateUserFailedAction
    | FetchUserAction
    | FetchUserFailedAction
    | UpdateUserAction
    | UpdateUserFailedAction

export function createUser(
    email: string,
    password: string
): RestSideEffect<repr.User.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: CREATE_USER },
                { action: RECEIVE_USER },
                { action: CREATE_USER_FAILURE },
                () => api.postUser(email, password)
            )
        )
    }
}

export function fetchUser(): RestSideEffect<repr.User.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: FETCH_USER },
                { action: RECEIVE_USER },
                { action: FETCH_USER_FAILURE },
                ({ authToken }) => api.getUser(authToken)
            )
        )
    }
}

export function updateUser(
    firstName: string,
    lastName: string,
    password: string
): RestSideEffect<repr.User.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: UPDATE_USER },
                { action: RECEIVE_USER },
                { action: UPDATE_USER_FAILURE },
                ({ authToken }) =>
                    api.putUser(firstName, lastName, password, authToken)
            )
        )
    }
}
