import * as repr from './representers'
import config from '../app.config'
import Resource from './Resource'
import * as lodash from 'lodash'
import { QuestionFilters } from '../reducers/app'
import { TestType } from '../reducers/app'

export interface RequestData {
    [key: string]:
        | string
        | number
        | boolean
        | null
        | { [k: string]: File }
        | Array<string | number | null | RequestData>
        | RequestData
}

export interface Pagination {
    page: number
    pageSize: number
}

interface RequestOpts {
    params?: RequestData
    authToken?: string | null
    bodyType?: 'json' | 'formData'
}

class RestError extends Error {
    errorCode: string

    constructor(json: repr.RestError.Base) {
        super(json.message)
        this.errorCode = json.error_code
    }
}

const requestOpsDefaults = { bodyType: 'json' as 'json' }

function encodeQueryParams(qp: RequestData) {
    return Object.keys(qp)
        .map(
            (k) =>
                encodeURIComponent(k) +
                '=' +
                encodeURIComponent(qp[k] as string | number | boolean)
        )
        .join('&')
}

export function get<T>(
    uri: string,
    opts: RequestOpts = {}
): Promise<Resource<T>> {
    return makeHTTPRequest(uri, 'GET', null, opts)
}

export function post<T>(
    uri: string,
    body?: RequestData | null,
    _opts: RequestOpts = {}
): Promise<Resource<T>> {
    const opts: RequestOpts = { ...requestOpsDefaults, ..._opts }
    return makeHTTPRequest(uri, 'POST', body, opts)
}

export function delete_<T>(
    uri: string,
    body?: RequestData | null,
    _opts: RequestOpts = {}
): Promise<Resource<T>> {
    const opts: RequestOpts = { ...requestOpsDefaults, ..._opts }
    return makeHTTPRequest(uri, 'DELETE', body, opts)
}

export function put<T>(
    uri: string,
    body?: RequestData | null,
    _opts: RequestOpts = {}
): Promise<Resource<T>> {
    const opts: RequestOpts = { ...requestOpsDefaults, ..._opts }
    return makeHTTPRequest(uri, 'PUT', body, opts)
}

function makeHTTPRequest<T>(
    uri: string,
    method: 'GET' | 'POST' | 'DELETE' | 'PUT',
    body?: RequestData | null,
    opts: RequestOpts = {}
): Promise<Resource<T>> {
    let url = `${config.apiUrl}${uri}`
    if (opts.params) {
        url += '?' + encodeQueryParams(opts.params)
    }
    let headers: HeadersInit = {}
    let init: RequestInit = {
        method,
        credentials: 'include',
        headers: headers,
    }

    if (body && opts.bodyType === 'json') {
        headers['Content-Type'] = 'application/json'
        init.body = JSON.stringify(body)
    } else if (body && opts.bodyType === 'formData') {
        const formData = new FormData()
        lodash.forEach(
            body.file_upload as {
                [k: string]: File
            },
            (v, k) => {
                formData.set(k, v)
            }
        )
        lodash.forEach(body, (v, k) => {
            if (k !== 'file_upload') {
                if (lodash.isArray(v)) {
                    v = v.join(',')
                } else if (!lodash.isString(v)) {
                    v = JSON.stringify(v)
                }
                formData.set(k, v as string | Blob)
            }
        })
        init.body = formData
    }

    if (opts.authToken) {
        headers.Authorization = `Bearer ${opts.authToken}`
    }

    return fetch(url, init)
        .then((r) => {
            return Promise.all([r, r.json(), r.headers])
        })
        .then(([resp, json, respHeaders]) => {
            if (resp.status > 400) {
                throw new RestError(json as repr.RestError.Base)
            }
            return new Resource(json as T, respHeaders)
        })
}

function prepareParams(filters: Partial<QuestionFilters>) {
    const params: RequestData = {}

    if (filters.course) {
        params.course = filters.course
    }

    if (filters.university) {
        params.university = filters.university
    }

    if (
        !lodash.isNull(filters.hasAnswer) &&
        !lodash.isUndefined(filters.hasAnswer)
    ) {
        params.has_answer = filters.hasAnswer
    }

    if (filters.subtopic) {
        params.subtopic = filters.subtopic
    }

    if (filters.testType && filters.testType.length > 0) {
        params.test_type = filters.testType.join(',')
    }

    if (filters.year) {
        params.year = filters.year
    }

    return params
}

export function prepareParamsForPagination(p: Pagination) {
    return { page: p.page, page_size: p.pageSize }
}

export function prepareParamsForPostNewContribution(
    specifications: RequestData
) {
    const params: RequestData = {}
    // specifications line up with the backend parameters but need to be converted to snake_case
    lodash.forEach(specifications, (value, key) => {
        params[lodash.snakeCase(key)] = value
    })

    return params
}

export function getQuestions(
    criteria: Partial<QuestionFilters>,
    pagination: Pagination = { page: 1, pageSize: 30 },
    authToken: string | null
): Promise<Resource<(repr.Question.Bare | repr.Question.Detailed)[]>> {
    let params = prepareParams(criteria)
    if (pagination) {
        params = lodash.merge(
            {},
            params,
            prepareParamsForPagination(pagination)
        )
    }

    return get(`/questions`, { params, authToken })
}

export function getQuestion(
    questionId: string,
    authToken: string | null
): Promise<Resource<repr.Question.Bare | repr.Question.Detailed>> {
    return get(`/questions/${questionId}`, { authToken })
}

export function getSimilarQuestions(
    questionId: string,
    authToken: string | null
): Promise<Resource<(repr.Question.Bare | repr.Question.Detailed)[]>> {
    return get(`/questions/${questionId}/similar`, { authToken })
}

export function search(
    searchText: string
): Promise<
    Resource<(repr.Course.Bare | repr.University.Base | repr.Question.Bare)[]>
> {
    return get('/search', {
        params: { text: searchText },
    })
}

export function getCourses(): Promise<Resource<repr.Course.Base[]>> {
    return get(`/courses`)
}

export function getCourse(
    courseId: string
): Promise<Resource<repr.Course.Base>> {
    return get(`/course/${courseId}`)
}

export function getUniversities(): Promise<Resource<repr.University.Base[]>> {
    return get(`/universities`)
}

export function postContribution(
    authToken: string | null,
    formData: RequestData
): Promise<Resource<repr.Contribution.Base>> {
    const prepared = prepareParamsForPostNewContribution(formData)
    return post('/contributions', prepared, {
        bodyType: 'formData',
        authToken,
    })
}

export function postNewContributionDocument(
    authToken: string | null,
    contribution_id: string,
    document: { [x: string]: { [k: string]: File } }
): Promise<Resource<repr.ContributionDocument.Base>> {
    return post(
        `/contributions/${contribution_id}/contribution_document`,
        document,
        {
            bodyType: 'formData',
            authToken,
        }
    )
}

export function postUser(
    email: string,
    password: string
): Promise<Resource<repr.User.Base>> {
    return post('/users', { email, password })
}

export function getUser(
    authToken: string | null
): Promise<Resource<repr.User.Base>> {
    return get('/user', { authToken })
}

export function putUser(
    first_name: string,
    last_name: string,
    password: string,
    authToken: string | null
): Promise<Resource<repr.User.Base>> {
    return put('/user', { first_name, last_name, password }, { authToken })
}

export function postAuth(
    email: string,
    password: string
): Promise<Resource<repr.Auth.Base>> {
    return post('/auth', { email, password })
}

export function getAuth(): Promise<Resource<repr.Auth.Base>> {
    return get('/auth')
}

export function deleteAuth(): Promise<Resource<repr.Auth.Base>> {
    return delete_('/auth')
}

export function postSubscription(
    billing_plan: string,
    referral_code: string,
    stripe_token: string,
    authToken: string | null
): Promise<Resource<{}>> {
    return post(
        '/subscription',
        { billing_plan, referral_code, stripe_token },
        { authToken }
    )
}

export function deleteSubscription(
    authToken: string | null
): Promise<Resource<{}>> {
    return delete_('/subscription', {}, { authToken })
}

export function putSubscription(
    authToken: string | null
): Promise<Resource<{}>> {
    return put('/subscription', {}, { authToken })
}

export function postPasswordReset(
    email: string
): Promise<Resource<repr.PasswordReset.Base>> {
    return post('/password_reset_tokens', { email })
}

export function getPasswordReset(
    resetToken: string
): Promise<Resource<repr.PasswordReset.Base>> {
    return get(`/password_reset_tokens/${resetToken}`)
}

export function putPasswordReset(
    resetToken: string,
    password: string
): Promise<Resource<repr.PasswordReset.Base>> {
    return put(`/password_reset_tokens/${resetToken}`, { password })
}

export function postEmailConfirmation(
    email: string
): Promise<Resource<repr.EmailConfirmation.Base>> {
    return post('/email_confirmation_tokens', { email })
}

export function putEmailConfirmation(
    resetToken: string
): Promise<Resource<repr.EmailConfirmation.Base>> {
    return put(`/email_confirmation_tokens/${resetToken}`)
}

export function postTest(
    course_id: string,
    test_type: TestType[],
    authToken: string | null
): Promise<Resource<repr.Test.Base>> {
    return post(`/tests`, { course_id, test_type }, { authToken })
}

export function getTests(
    authToken: string | null
): Promise<Resource<repr.Test.Base[]>> {
    return get(`/tests`, { authToken })
}

export function postTestAttempt(
    test_id: string,
    authToken: string | null
): Promise<Resource<repr.TestAttempt.Base>> {
    return post(`/test_attempts/${test_id}`, {}, { authToken })
}

export function postTestAttemptResponse(
    test_attempt_id: string,
    response: string,
    test_question_id: string,
    authToken: string | null
): Promise<Resource<repr.TestAttemptResponse.Base>> {
    return post(
        `/test_attempt_responses`,
        { test_attempt_id, response, test_question_id },
        { authToken }
    )
}

export function postFlaggedQuestion(
    authToken: string | null,
    question_id: string,
    description: string,
    additional_details: string
): Promise<Resource<repr.FlaggedQuestion.Base>> {
    return post(
        `/flagged_questions`,
        { question_id, description, additional_details },
        { authToken }
    )
}
