import * as React from 'react'
import * as lodash from 'lodash'
import * as repr from '../rest/representers'
import { ThunkDispatch } from '../actions/util'
import { Test, TestFactory } from '../models'
import { connect } from 'react-redux'
import { StateShape as ReduxStateShape } from '../reducers'
import { fetchTests } from '../actions/tests'
import { displayTestGenerator } from '../actions/app'
import { Container, Transition } from 'semantic-ui-react'
import TopBar from './TopBar'
import { CSSTransition } from 'react-transition-group'
import DropdownMenu from './DropdownMenu'
import { revealDropdownMenu, hideDropdownMenu } from '../actions/app'
import TestPage from '../components/TestSimulator/TestPage'
import { displayLogin, displayUpgrade } from '../actions/app'

import './TestSimulatorPage.scss'

interface StateShape {
    selectedTestId: string
    showMenuBar: boolean
}

interface PropsShape {
    auth: repr.Auth.Base | null
    dispatch: ThunkDispatch
    tests: Test[]
    isDropdownMenuExpanded: boolean
}

class TestSimulatorPage extends React.PureComponent<PropsShape, StateShape> {
    constructor(props: PropsShape) {
        super(props)
        this.state = {
            selectedTestId:
                props.tests.length > 0 ? props.tests[0].data.id : '',
            showMenuBar: false,
        }
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(fetchTests())
    }

    componentDidUpdate(prevProps: PropsShape) {
        const { tests, auth, dispatch } = this.props
        if (prevProps.tests !== tests) {
            const testId = tests.length > 0 ? tests[0].data.id : ''
            this.setState({ selectedTestId: testId })
        }

        if (prevProps.auth !== auth) {
            dispatch(fetchTests())
        }
    }

    render() {
        const { isDropdownMenuExpanded, tests, auth } = this.props
        const { showMenuBar } = this.state
        return (
            <Container className="TestSimulatorPage">
                <TopBar withSearch={true} logo="main" />
                <CSSTransition
                    classNames="appear"
                    timeout={500}
                    in={isDropdownMenuExpanded}
                    unmountOnExit={true}
                >
                    <DropdownMenu onMenuItemClick={this.toggleDropdownMenu} />
                </CSSTransition>
                <div className="content-container">
                    <div className="test-menu-bar desktop">
                        {this.testMenuBar}
                    </div>

                    <div
                        className={`show-menu-bar-button-mobile ${
                            !auth || tests.length === 0 ? 'no-tests' : ''
                        }`}
                        onClick={() =>
                            this.setState({ showMenuBar: !showMenuBar })
                        }
                    >
                        Show all tests{' '}
                        <img
                            className={`show-tests-chevron ${
                                showMenuBar ? 'up' : ''
                            }`}
                            src="/chevron_down.svg"
                            alt="Down icon"
                        />
                    </div>

                    <Transition
                        visible={showMenuBar}
                        animation="slide down"
                        duration={500}
                    >
                        <div
                            className={`test-menu-bar mobile ${
                                !auth || tests.length === 0 ? 'no-tests' : ''
                            }`}
                        >
                            {this.testMenuBar}
                        </div>
                    </Transition>

                    <div className="test-container">
                        {!auth
                            ? this.loginMessage
                            : tests.length === 0
                            ? this.noTests
                            : this.test}
                    </div>
                </div>
            </Container>
        )
    }

    private get test() {
        const { tests, dispatch, auth } = this.props
        const { selectedTestId } = this.state
        const test = tests.find((t) => t.data.id === selectedTestId)
        if (!test) {
            return null
        }

        return <TestPage test={test} dispatch={dispatch} auth={auth} />
    }

    private showSimulatorModal = () => {
        const { dispatch, auth } = this.props
        if (!auth) {
            dispatch(displayLogin())
            return
        }
        dispatch(displayTestGenerator())
    }

    private toggleDropdownMenu = () => {
        const { isDropdownMenuExpanded, dispatch } = this.props
        if (isDropdownMenuExpanded) {
            dispatch(hideDropdownMenu())
        } else {
            dispatch(revealDropdownMenu())
        }
    }

    private get loginMessage() {
        const { dispatch } = this.props
        return (
            <div className="loginMessage">
                <img
                    className="illustration"
                    src="/not-found.svg"
                    alt="Not found icon"
                />
                <h3 className="login-message">
                    You must{' '}
                    <span
                        onClick={() => dispatch(displayLogin())}
                        className="login-button"
                    >
                        login
                    </span>{' '}
                    to access the test simulator.
                </h3>
            </div>
        )
    }

    private get noTests() {
        return (
            <div className="noTests">
                <img
                    className="illustration"
                    src="/not-found.svg"
                    alt="Not found icon"
                />
                <div>
                    <button
                        className="create-test-button"
                        onClick={this.showSimulatorModal}
                    >
                        Create New Test
                    </button>
                </div>
            </div>
        )
    }

    private get testMenuBar() {
        const { selectedTestId } = this.state
        const { tests, auth, dispatch } = this.props
        const isUserPremium =
            auth &&
            auth.user.stripe_subscription &&
            auth.user.stripe_subscription.is_active
        return (
            <>
                <div className="test-menu-header-container">
                    <div className="test-menu-header">Saved Tests</div>
                </div>

                <div className="test-list">
                    {tests.map((t) => (
                        <div
                            className={`test-info ${
                                t.data.id === selectedTestId ? 'active' : ''
                            }`}
                            key={t.data.id}
                            onClick={() => {
                                if (!isUserPremium) {
                                    dispatch(displayUpgrade())
                                    return
                                }
                                this.setState({
                                    selectedTestId: t.data.id,
                                })
                            }}
                        >
                            {`${t.data.course.course_code} - ${t.data.course.name}`}
                            <div className="test-date">
                                Created:{' '}
                                {new Date(
                                    t.data.created_at
                                ).toLocaleDateString()}
                            </div>
                            {!isUserPremium && (
                                <img
                                    className="lock-icon"
                                    src="/lock.svg"
                                    alt="lock icon"
                                />
                            )}
                        </div>
                    ))}
                    <div className="new-test-container">
                        <button
                            className="new-test-button"
                            onClick={this.showSimulatorModal}
                        >
                            Add Test
                        </button>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const auth = state.auth
    const isDropdownMenuExpanded = state.app.isDropdownMenuExpanded
    let tests = TestFactory.fromState(state)
    tests = lodash.orderBy(tests, ['data.created_at'], 'desc')

    return {
        auth,
        tests,
        isDropdownMenuExpanded,
    }
}

export default connect(mapStateToProps)(TestSimulatorPage)
