import { makeRequest, RestSideEffect } from './util'
import * as api from '../rest/api'
import * as repr from '../rest/representers'
import { Action, ActionWithError, ActionWithPayload } from './Action'
import Resource from '../rest/Resource'

export const CREATE_TEST_ATTEMPT_RESPONSE = 'CREATE_TEST_ATTEMPT_RESPONSE'
export type CREATE_TEST_ATTEMPT_RESPONSE = typeof CREATE_TEST_ATTEMPT_RESPONSE

export const RECEIVE_TEST_ATTEMPT_RESPONSE = 'RECEIVE_TEST_ATTEMPT_RESPONSE'
export type RECEIVE_TEST_ATTEMPT_RESPONSE = typeof RECEIVE_TEST_ATTEMPT_RESPONSE

export const CREATE_TEST_ATTEMPT_RESPONSE_FAILURE =
    'CREATE_TEST_ATTEMPT_RESPONSE_FAILURE'
export type CREATE_TEST_ATTEMPT_RESPONSE_FAILURE = typeof CREATE_TEST_ATTEMPT_RESPONSE_FAILURE

export type RequestTestAttemptResponseActionType = CREATE_TEST_ATTEMPT_RESPONSE
export type ReceiveTestAttemptResponseActionType = RECEIVE_TEST_ATTEMPT_RESPONSE
export type RequestTestAttemptResponseFailedActionType = CREATE_TEST_ATTEMPT_RESPONSE_FAILURE

export type RequestTestAttemptResponseAction = Action<
    CREATE_TEST_ATTEMPT_RESPONSE
>
export type ReceiveTestAttemptResponseAction = ActionWithPayload<
    RECEIVE_TEST_ATTEMPT_RESPONSE,
    Resource<repr.TestAttemptResponse.Base>
>
export type RequestTestAttemptResponseFailedAction = ActionWithError<
    CREATE_TEST_ATTEMPT_RESPONSE_FAILURE,
    Error
>

export type TestAttemptResponseAction =
    | RequestTestAttemptResponseAction
    | ReceiveTestAttemptResponseAction
    | RequestTestAttemptResponseFailedAction

export function createTestAttemptResponse(
    testAttemptId: string,
    response: string,
    testQuestionId: string
): RestSideEffect<repr.TestAttemptResponse.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: CREATE_TEST_ATTEMPT_RESPONSE },
                { action: RECEIVE_TEST_ATTEMPT_RESPONSE },
                { action: CREATE_TEST_ATTEMPT_RESPONSE_FAILURE },
                ({ authToken }) =>
                    api.postTestAttemptResponse(
                        testAttemptId,
                        response,
                        testQuestionId,
                        authToken
                    )
            )
        )
    }
}
