import { AnyAction } from 'redux'
import { ThunkDispatch as _ThunkDispatch, ThunkAction } from 'redux-thunk'
import Resource from '../rest/Resource'
import {
    ActionWithPayload,
    ActionWithError,
    ActionMetaData,
    Action
} from './Action'
import { StateShape as ReduxStoreShape } from '../reducers'

import {
    RequestQuestionsActionType,
    ReceiveQuestionsActionType,
    RequestQuestionsFailedActionType,
    RequestQuestionActionType,
    ReceiveQuestionActionType,
    RequestQuestionFailedActionType
} from './questions'

import {
    RequestSearchActionType,
    ReceiveSearchActionType,
    RequestSearchFailedActionType
} from './search'

import {
    RequestCoursesActionType,
    ReceiveCoursesActionType,
    RequestCoursesFailedActionType,
    RequestCourseActionType,
    ReceiveCourseActionType,
    RequestCourseFailedActionType
} from './courses'

import {
    RequestUniversitiesActionType,
    ReceiveUniversitiesActionType,
    RequestUniversitiesFailedActionType
} from './universities'

import {
    RequestAuthActionType,
    ReceiveAuthActionType,
    RequestAuthFailedActionType
} from './auth'

import {
    RequestUserActionType,
    ReceiveUserActionType,
    RequestUserFailedActionType
} from './users'

import {
    RequestContributionActionType,
    ReceiveContributionActionType,
    RequestContributionFailedActionType
} from './contributions'

import {
    RequestContributionDocumentActionType,
    ReceiveContributionDocumentActionType,
    RequestContributionDocumentFailedActionType
} from './contributionDocuments'

import {
    RequestSubscriptionActionType,
    ReceiveSubscriptionActionType,
    RequestSubscriptionFailedActionType
} from './subscriptions'

import {
    RequestPasswordResetTokenActionType,
    ReceivePasswordResetTokenActionType,
    RequestPasswordResetTokenFailedActionType
} from './passwordResetTokens'

import {
    RequestEmailConfirmationTokenActionType,
    ReceiveEmailConfirmationTokenActionType,
    RequestEmailConfirmationTokenFailedActionType
} from './emailConfirmationTokens'

import {
    RequestTestActionType,
    ReceiveTestActionType,
    RequestTestFailedActionType
} from './tests'

import {
    RequestTestAttemptActionType,
    ReceiveTestAttemptActionType,
    RequestTestAttemptFailedActionType
} from './testAttempts'

import {
    RequestTestAttemptResponseActionType,
    ReceiveTestAttemptResponseActionType,
    RequestTestAttemptResponseFailedActionType
} from './testAttemptResponses'

import {
    RequestFlaggedQuestionActionType,
    ReceiveFlaggedQuestionActionType,
    RequestFlaggedQuestionFailedActionType
} from './flaggedQuestions'

export type ThunkDispatch = _ThunkDispatch<ReduxStoreShape, never, AnyAction>

type RequestActionType =
    | RequestQuestionsActionType
    | RequestQuestionActionType
    | RequestSearchActionType
    | RequestCoursesActionType
    | RequestCourseActionType
    | RequestUniversitiesActionType
    | RequestAuthActionType
    | RequestUserActionType
    | RequestContributionActionType
    | RequestContributionDocumentActionType
    | RequestSubscriptionActionType
    | RequestPasswordResetTokenActionType
    | RequestEmailConfirmationTokenActionType
    | RequestTestActionType
    | RequestTestAttemptActionType
    | RequestTestAttemptResponseActionType
    | RequestFlaggedQuestionActionType

type ReceiveActionType =
    | ReceiveQuestionsActionType
    | ReceiveQuestionActionType
    | ReceiveSearchActionType
    | ReceiveCoursesActionType
    | ReceiveCourseActionType
    | ReceiveUniversitiesActionType
    | ReceiveAuthActionType
    | ReceiveUserActionType
    | ReceiveContributionActionType
    | ReceiveContributionDocumentActionType
    | ReceiveSubscriptionActionType
    | ReceivePasswordResetTokenActionType
    | ReceiveEmailConfirmationTokenActionType
    | ReceiveTestActionType
    | ReceiveTestAttemptActionType
    | ReceiveTestAttemptResponseActionType
    | ReceiveFlaggedQuestionActionType

type RequestFailedActionType =
    | RequestQuestionsFailedActionType
    | RequestQuestionFailedActionType
    | RequestSearchFailedActionType
    | RequestCoursesFailedActionType
    | RequestCourseFailedActionType
    | RequestUniversitiesFailedActionType
    | RequestAuthFailedActionType
    | RequestUserFailedActionType
    | RequestContributionFailedActionType
    | RequestContributionDocumentFailedActionType
    | RequestSubscriptionFailedActionType
    | RequestPasswordResetTokenFailedActionType
    | RequestEmailConfirmationTokenFailedActionType
    | RequestTestFailedActionType
    | RequestTestAttemptFailedActionType
    | RequestTestAttemptResponseFailedActionType
    | RequestFlaggedQuestionFailedActionType

export type SideEffect<T> = ThunkAction<T, ReduxStoreShape, {}, AnyAction>
export type RestSideEffect<T> = SideEffect<Promise<Resource<T>>>

export function makeRequest<R>(
    requestActionParams: { action: RequestActionType; meta?: ActionMetaData },
    receiveActionParams: { action: ReceiveActionType; meta?: ActionMetaData },
    requestFailedActionParams: {
        action: RequestFailedActionType
        meta?: ActionMetaData
    },
    requestFunc: (opts: { authToken: string | null }) => Promise<Resource<R>>
): SideEffect<Promise<Resource<R>>> {
    return (dispatch, getState): Promise<Resource<R>> => {
        const state = getState()
        const auth = state.auth && state.auth.token
        dispatch(
            requestAction(requestActionParams.action, requestActionParams.meta)
        )

        return requestFunc({ authToken: auth })
            .then((r: Resource<R>) => {
                dispatch(
                    receiveAction(
                        receiveActionParams.action,
                        r,
                        receiveActionParams.meta
                    )
                )
                return r
            })
            .catch(e => {
                dispatch(
                    requestFailedAction(
                        requestFailedActionParams.action,
                        e,
                        requestFailedActionParams.meta
                    )
                )
                throw e
            })
    }
}

function requestAction(
    requestActionType: RequestActionType,
    meta?: ActionMetaData
): Action<RequestActionType> {
    return { type: requestActionType, meta }
}

function receiveAction<T>(
    receiveActionType: ReceiveActionType,
    resource: Resource<T>,
    meta?: ActionMetaData
): ActionWithPayload<ReceiveActionType, Resource<T>> {
    return { type: receiveActionType, payload: resource, meta }
}

function requestFailedAction(
    requestFailedActionType: RequestFailedActionType,
    error: Error,
    meta?: ActionMetaData
): ActionWithError<RequestFailedActionType, Error> {
    return { type: requestFailedActionType, error, meta }
}
