import * as React from 'react'
import { push as pushRoute } from 'react-router-redux'
import { ThunkDispatch } from '../actions/util'
import { closeModal } from '../actions/app'
import { Dimmer } from 'semantic-ui-react'
import { StateShape as ReduxStateShape } from '../reducers'
import { Modal } from '../reducers/app'
import { connect } from 'react-redux'
import Signup from './Signup'
import Login from './Login'
import PasswordReset from './PasswordReset'
import TestGenerator from './TestGenerator'
import UpgradeModal from './UpgradeModal'
import * as H from 'history'
import './ModalManager.scss'

interface StateShape {}
interface PropsShape {
    dispatch: ThunkDispatch
    modal: Modal
    location: H.Location | null
}

class ModalManager extends React.Component<PropsShape, StateShape> {
    componentDidMount() {
        document.addEventListener('keydown', this.handleEscapeKeyPress, false)
    }

    componentWillUnmount() {
        document.removeEventListener(
            'keydown',
            this.handleEscapeKeyPress,
            false
        )
    }

    render() {
        const { dispatch } = this.props
        let modalToShow = this.selectModalToShow()

        if (!modalToShow) {
            return null
        }

        return (
            <Dimmer
                active={true}
                page={true}
                onClickOutside={() => dispatch(closeModal())}
            >
                {modalToShow}
            </Dimmer>
        )
    }

    selectModalToShow = () => {
        const { modal, location, dispatch } = this.props

        if (location && location.pathname === '/app/login') {
            const from =
                location.state && ((location.state as any).from || '/browse')
            return (
                <Login
                    onClose={() => dispatch(pushRoute('/'))}
                    onLogin={() => dispatch(pushRoute(from))}
                    signupAsRedirect={true}
                    passwordResetAsRedirect={true}
                />
            )
        }

        if (location && location.pathname === '/app/signup') {
            return (
                <Signup
                    onClose={() => dispatch(pushRoute('/'))}
                    onSignup={() => dispatch(pushRoute('/upgrade'))}
                    loginAsRedirect={true}
                    location={location}
                />
            )
        }

        switch (modal) {
            case Modal.Signup:
                return (
                    <Signup onSignup={() => dispatch(pushRoute('/upgrade'))} />
                )
            case Modal.Login:
                return <Login />
            case Modal.PasswordReset:
                return <PasswordReset />
            case Modal.TestGenerator:
                return <TestGenerator />
            case Modal.UpgradeModal:
                return <UpgradeModal />
            default:
                return null
        }
    }

    handleEscapeKeyPress = (evt: KeyboardEvent) => {
        const { dispatch } = this.props
        if (evt.keyCode === 27) {
            dispatch(closeModal())
        }
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const modal = state.app.modal as Modal
    const location = state.router.location
    return { modal, location }
}

export default connect(mapStateToProps)(ModalManager)
