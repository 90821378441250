import * as React from 'react'

import './ProgressBar.scss'

interface PropsShape {
    step: number
}

export default class ProgressBar extends React.Component<PropsShape> {
    render() {
        const { step } = this.props

        const progressBarInfo = [
            {
                label: 'Document Info',
                step: 1,
                iconLabel: '1',
                key: 'document-info'
            },
            {
                label: 'Document Upload',
                step: 2,
                iconLabel: '2',
                key: 'document-upload'
            },
            {
                label: 'Success',
                iconLabel: '3',
                step: 3,
                key: 'submit'
            }
        ]
        let isActive: boolean = true
        const onSuccessPage = step === 3
        return (
            <div className="ProgressBar">
                {progressBarInfo.map(segment => {
                    if (
                        segment.step &&
                        segment.step > step &&
                        !onSuccessPage
                    ) {
                        isActive = false
                    }
                    return (
                        <>
                            <div
                                className="progress-bar-segment"
                                key={segment.key}
                            >
                                {segment.key !== 'document-info' && (
                                    <div
                                        className={`major-progress-bar-connectors ${
                                            isActive ? 'active' : ''
                                        }`}
                                    />
                                )}
                                <span
                                    className={`progress-bar-span ${
                                        isActive ? 'active' : ''
                                    }`}
                                >
                                    {segment.iconLabel}
                                </span>
                                <span className="segment-label-span">
                                    {segment.label}
                                </span>
                            </div>
                        </>
                    )
                })}
            </div>
        )
    }
}