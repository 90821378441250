import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { rootReducer, StateShape } from './reducers'
import { routerMiddleware } from 'react-router-redux'
import { History } from 'history';

export function configureStore(initialState: StateShape | undefined, history: History) {
    const middlewares = [thunk, routerMiddleware(history)]
    if (process.env.NODE_ENV === 'development') {
        const { logger } = require('redux-logger')
        middlewares.push(logger)
    }

    const enhance = compose(applyMiddleware(...middlewares))
    return createStore(
        rootReducer,
        {},
        enhance
    )
}