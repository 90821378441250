import * as React from 'react'
import { connect } from 'react-redux'
import { StateShape as ReduxStateShape } from '../reducers'
import { QuestionFilters, TestType } from '../reducers/app'
import './TestPackBar.scss'
import { Course, CourseFactory } from '../models'
import { featuredCourses } from './HomePage'
import { Link } from 'react-router-dom'

interface StateShape {}
interface PropsShape {
    filters: QuestionFilters
    highlightedCourses: Course[]
}

class TestPackBar extends React.PureComponent<PropsShape, StateShape> {
    render() {
        return (
            <div className="TestPackBar">
                <div className="test-packs-bar-header">
                    <h1 className="test-packs-bar-title">
                        Top Test Packs this week
                    </h1>
                    <div className="test-packs-bar-description">
                        Find pre-grouped questions for a specific test...
                    </div>
                    <hr />
                </div>
                <div className="test-packs-container">
                    {this.testPackList()}
                </div>
            </div>
        )
    }

    private testPackList() {
        const { highlightedCourses, filters } = this.props
        const testPacks: JSX.Element[] = []

        let filteredCourses = highlightedCourses

        if (filters.course) {
            filteredCourses = filteredCourses.filter(
                (c) =>
                    c.data.course_code.toLowerCase() ===
                        filters.course.toLowerCase() ||
                    c.data.name.toLowerCase() === filters.course.toLowerCase()
            )
        }

        filteredCourses.forEach((course) => {
            const numberOfQuestions =
                course.data.number_of_questions_by_test_type

            let testTypes = Object.keys(numberOfQuestions) as (
                | 'TT1'
                | 'TT2'
                | 'TT3'
                | 'Midterm'
                | 'Final'
            )[]

            if (filters.testType.length) {
                testTypes = testTypes.filter((tt) =>
                    filters.testType.includes(tt as TestType)
                )
            }

            testTypes.forEach((tt) => {
                if (numberOfQuestions[tt] > 0) {
                    testPacks.push(this.buildTestPackCard(course, tt))
                }
            })
        })

        return testPacks
    }

    private buildTestPackCard = (
        course: Course,
        termTest: 'TT1' | 'TT2' | 'TT3' | 'Midterm' | 'Final'
    ) => {
        return (
            <div
                className="test-pack-card"
                key={`${course.data.id}-${termTest}`}
            >
                <span className="course-code">{course.data.course_code}</span>
                <span className="course-name">{termTest} Test Pack</span>
                <Link
                    className="course-questions-link"
                    to={`/test-pack/university-of-toronto/${course.data.course_code}/${termTest}`}
                >{`${course.data.number_of_questions_by_test_type[termTest]} Questions`}</Link>
                <span className="university-name">
                    {course.data.university_name}
                </span>
            </div>
        )
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const filters = state.app.testPackFilters

    const highlightedCourses: Course[] = CourseFactory.fromState(
        state,
        featuredCourses
    )

    return {
        filters,
        highlightedCourses,
    }
}

export default connect(mapStateToProps)(TestPackBar)
