import * as lodash from 'lodash'
import * as icepick from 'icepick'

import {
    SearchAction,
    ReceiveSearchResultsAction,
    RECEIVE_SEARCH_RESULTS
} from '../../actions/search'

import {
    ReceiveQuestionsAction,
    RECEIVE_QUESTIONS,
    QuestionAction
} from '../../actions/questions'

export interface StateShape {
    courseIdsForSearch: string[]
    universityNamesForSearch: string[]
    questionIdsForSearch: string[]
    questionIdsForDisplay: string[]
    similarQuestionIds: string[]
}

const defaultState = {
    courseIdsForSearch: [],
    universityNamesForSearch: [],
    questionIdsForSearch: [],
    questionIdsForDisplay: [],
    similarQuestionIds: []
}

type Action = SearchAction | QuestionAction

export function setsReducer(state: StateShape = defaultState, action: Action) {
    switch (action.type) {
        case RECEIVE_SEARCH_RESULTS:
            return handleReceiveSearchResults(state, action)
        case RECEIVE_QUESTIONS:
            if (action.meta && action.meta.intent === 'similarQuestions') {
                return handleReceiveSimilarQuestions(state, action)
            }
            return handleReceiveQuestions(state, action)
    }

    return state
}

function handleReceiveQuestions(
    state: StateShape,
    action: ReceiveQuestionsAction
): StateShape {
    const ids = lodash.map(action.payload.data, 'id')
    return icepick.assign(state, { questionIdsForDisplay: ids })
}

function handleReceiveSimilarQuestions(
    state: StateShape,
    action: ReceiveQuestionsAction
): StateShape {
    const ids = lodash.map(action.payload.data, 'id')
    return icepick.assign(state, { similarQuestionIds: ids })
}

function handleReceiveSearchResults(
    state: StateShape,
    action: ReceiveSearchResultsAction
): StateShape {
    const groupedIds = groupSearchResultIds(action)
    return icepick.assign(state, groupedIds)
}

function groupSearchResultIds(action: ReceiveSearchResultsAction) {
    const accumulator: Partial<StateShape> = {
        courseIdsForSearch: [],
        universityNamesForSearch: [],
        questionIdsForSearch: []
    }
    lodash.reduce(
        action.payload.data,
        (a, v) => {
            if (v.type === 'course_bare') {
                a.courseIdsForSearch!.push(v.id)
            } else if (v.type === 'university') {
                a.universityNamesForSearch!.push(v.name)
            } else if (v.type === 'question_bare') {
                a.questionIdsForSearch!.push(v.id)
            }
            return a
        },
        accumulator
    )

    return accumulator
}
