const config = {
    development: {
        apiUrl: 'http://localhost:5000',
        stripeKey: 'pk_test_flLY8Kg2ooviHY3Z4FKPxVpr',
    },
    production: {
        apiUrl: 'https://api.intellyq.com',
        stripeKey: 'pk_live_uumcxEbV2YmsPNNXTFq6lttw',
    },
}

export default config[process.env.NODE_ENV]
