import * as React from 'react'
import './ValueProp.scss'

interface StateShape {}
interface PropsShape {}

export default class ValueProp extends React.Component<PropsShape, StateShape> {
    render() {
        return (
            <div className="valueproposition-sectionwrap">
                <h1 className="valueprops-intro-header">
                    Join the study cycle that helps you succeed
                </h1>
                <div className="value">
                    <div className="value-block value-block-text">
                        <h2 className="value-header">Step One: Solve</h2>
                        <p className="value-paragraph">
                            Test yourself and check your progress using past
                            exam questions, so you know exactly what to expect
                            on exam day.
                        </p>

                        <ul>
                            <li className="value-prop-list">
                                <b>Tailored questions</b> — for specific term
                                test, midterm, or final exams.
                            </li>
                            <li className="value-prop-list">
                                <b>Thousands of questions</b> — organized by
                                course, year, topic and more.
                            </li>
                            <li className="value-prop-list">
                                <b>Test packs</b> — pregrouped questions for a
                                specific test.
                            </li>
                        </ul>
                    </div>
                    <div className="value-block value-block-photo">
                        <img
                            className="search-illustration"
                            src="/searching-illustration.svg"
                            alt="Search icon"
                        />
                    </div>
                </div>
                <div className="value">
                    <div className="value-block value-block-photo desktop-only">
                        <img
                            className="solve-illustration"
                            src="/solving-illustration.svg"
                            alt="Solve icon"
                        />
                    </div>
                    <div className="value-block value-block-text">
                        <h2 className="value-header">Step Two: Feedback</h2>
                        <p className="value-paragraph">
                            Receive continuous feedback as you solve questions.
                            Track your progress with our test simulator.
                        </p>

                        <ul>
                            <li className="value-prop-list">
                                <b>Correct answers</b> — reveal the correct
                                answer after your attempt.
                            </li>
                            <li className="value-prop-list">
                                <b>Estimated grade</b> — use the test simulator
                                to get an estimated grade for your exam.
                            </li>
                            <li className="value-prop-list">
                                <b>Progress</b> — track your progress by
                                attempting a sample test more than once.
                            </li>
                        </ul>
                    </div>
                    <div className="value-block value-block-photo mobile-only">
                        <img
                            className="solve-illustration"
                            src="/solving-illustration.svg"
                            alt="Solve icon"
                        />
                    </div>
                </div>
                <div className="value contribute-earn">
                    <div className="value-block value-block-text">
                        <h2 className="value-header">Step 3: Improve</h2>
                        <p className="value-paragraph">
                            View the model answer for every question, put
                            together and explained by our one of a kind AI
                            integration
                        </p>

                        <ul>
                            <li className="value-prop-list">
                                <b>Worked solutions</b> — see all of the
                                necessary steps to a perfect answer.
                            </li>
                            <li className="value-prop-list">
                                <b>Request more explanation</b> — get an
                                unlimited number of explanations from our AI
                                teaching assistant until you're satisfied.
                            </li>
                            <li className="value-prop-list">
                                <b>Similar questions</b> — request similar
                                questions to solidify your understanding.
                            </li>
                        </ul>
                    </div>
                    <div className="value-block value-block-photo">
                        <img
                            className="save-illustration"
                            src="/ai-illustration.svg"
                            alt="AI icon"
                        />
                    </div>
                </div>
            </div>
        )
    }
}
