import { makeRequest, RestSideEffect } from './util'
import * as api from '../rest/api'
import { Pagination } from '../rest/api'
import * as repr from '../rest/representers'
import { Action, ActionWithError, ActionWithPayload } from './Action'
import { StateShape as ReduxStoreShape } from '../reducers'
import Resource from '../rest/Resource'
import { QuestionFilters } from '../reducers/app'

export const FETCH_QUESTIONS = 'FETCH_QUESTIONS'
export type FETCH_QUESTIONS = typeof FETCH_QUESTIONS

export const RECEIVE_QUESTIONS = 'RECEIVE_QUESTIONS'
export type RECEIVE_QUESTIONS = typeof RECEIVE_QUESTIONS

export const FETCH_QUESTIONS_FAILURE = 'FETCH_QUESTIONS_FAILURE'
export type FETCH_QUESTIONS_FAILURE = typeof FETCH_QUESTIONS_FAILURE

export const FETCH_QUESTION = 'FETCH_QUESTION'
export type FETCH_QUESTION = typeof FETCH_QUESTION

export const RECEIVE_QUESTION = 'RECEIVE_QUESTION'
export type RECEIVE_QUESTION = typeof RECEIVE_QUESTION

export const FETCH_QUESTION_FAILURE = 'FETCH_QUESTION_FAILURE'
export type FETCH_QUESTION_FAILURE = typeof FETCH_QUESTION_FAILURE

export type RequestQuestionsActionType = FETCH_QUESTIONS
export type ReceiveQuestionsActionType = RECEIVE_QUESTIONS
export type RequestQuestionsFailedActionType = FETCH_QUESTIONS_FAILURE

export type RequestQuestionActionType = FETCH_QUESTION
export type ReceiveQuestionActionType = RECEIVE_QUESTION
export type RequestQuestionFailedActionType = FETCH_QUESTION_FAILURE

export type RequestQuestionsAction = Action<FETCH_QUESTIONS>
export type ReceiveQuestionsAction = ActionWithPayload<
    RECEIVE_QUESTIONS,
    Resource<repr.Question.Base[]>
>
export type RequestQuestionsFailedAction = ActionWithError<
    FETCH_QUESTIONS_FAILURE,
    Error
>

export type RequestQuestionAction = Action<FETCH_QUESTION>
export type ReceiveQuestionAction = ActionWithPayload<
    RECEIVE_QUESTION,
    Resource<repr.Question.Base>
>
export type RequestQuestionFailedAction = ActionWithError<
    FETCH_QUESTION_FAILURE,
    Error
>

export type QuestionAction =
    | RequestQuestionsAction
    | ReceiveQuestionsAction
    | RequestQuestionsFailedAction
    | RequestQuestionAction
    | ReceiveQuestionAction
    | RequestQuestionFailedAction

export function fetchQuestion(
    questionId: string
): RestSideEffect<repr.Question.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: FETCH_QUESTION },
                { action: RECEIVE_QUESTION },
                { action: FETCH_QUESTION_FAILURE },
                ({ authToken }) => api.getQuestion(questionId, authToken)
            )
        )
    }
}

export function fetchQuestions(
    criteria: Partial<QuestionFilters>,
    pagination?: Pagination
): RestSideEffect<repr.Question.Base[]> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: FETCH_QUESTIONS },
                { action: RECEIVE_QUESTIONS },
                { action: FETCH_QUESTIONS_FAILURE },
                ({ authToken }) =>
                    api.getQuestions(criteria, pagination, authToken)
            )
        )
    }
}

export function fetchFilteredQuestions(
    pagination?: Pagination
): RestSideEffect<repr.Question.Base[]> {
    return (dispatch, getState: () => ReduxStoreShape) => {
        const state = getState()
        const questionFilters = state.app.questionFilters

        return dispatch(
            fetchQuestions(
                {
                    ...questionFilters
                },
                pagination
            )
        )
    }
}

export function fetchSimilarQuestions(
    questionId: string
): RestSideEffect<repr.Question.Base[]> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: FETCH_QUESTIONS },
                {
                    action: RECEIVE_QUESTIONS,
                    meta: { intent: 'similarQuestions' }
                },
                { action: FETCH_QUESTIONS_FAILURE },
                ({ authToken }) =>
                    api.getSimilarQuestions(questionId, authToken)
            )
        )
    }
}
