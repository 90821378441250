import { makeRequest, RestSideEffect } from './util'
import * as api from '../rest/api'
import * as repr from '../rest/representers'
import { Action, ActionWithError, ActionWithPayload } from './Action'
import Resource from '../rest/Resource'

export const CREATE_TEST_ATTEMPT = 'CREATE_TEST_ATTEMPT'
export type CREATE_TEST_ATTEMPT = typeof CREATE_TEST_ATTEMPT

export const RECEIVE_TEST_ATTEMPT = 'RECEIVE_TEST_ATTEMPT'
export type RECEIVE_TEST_ATTEMPT = typeof RECEIVE_TEST_ATTEMPT

export const CREATE_TEST_ATTEMPT_FAILURE = 'CREATE_TEST_ATTEMPT_FAILURE'
export type CREATE_TEST_ATTEMPT_FAILURE = typeof CREATE_TEST_ATTEMPT_FAILURE

export type RequestTestAttemptActionType = CREATE_TEST_ATTEMPT
export type ReceiveTestAttemptActionType = RECEIVE_TEST_ATTEMPT
export type RequestTestAttemptFailedActionType = CREATE_TEST_ATTEMPT_FAILURE

export type RequestTestAttemptAction = Action<CREATE_TEST_ATTEMPT>
export type ReceiveTestAttemptAction = ActionWithPayload<
    RECEIVE_TEST_ATTEMPT,
    Resource<repr.TestAttempt.Base>
>
export type RequestTestAttemptFailedAction = ActionWithError<
    CREATE_TEST_ATTEMPT_FAILURE,
    Error
>

export type TestAttemptAction =
    | RequestTestAttemptAction
    | ReceiveTestAttemptAction
    | RequestTestAttemptFailedAction

export function createTestAttempt(
    testId: string
): RestSideEffect<repr.TestAttempt.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: CREATE_TEST_ATTEMPT },
                { action: RECEIVE_TEST_ATTEMPT },
                { action: CREATE_TEST_ATTEMPT_FAILURE },
                ({ authToken }) => api.postTestAttempt(testId, authToken)
            )
        )
    }
}
