export default class Resource<T> {
    data: T
    pagination?: {
        page: number
        pageSize: number
        pages: number,
        totalCount: number
    }

    constructor(data: T, headers: Headers) {
        this.data = data
        const page = headers.get('X-Page')
        const pageSize = headers.get('X-Page-Size')
        const pages = headers.get('X-Pages')
        const totalCount = headers.get('X-Total-Count')

        if (page && pageSize && pages && totalCount) {
            this.pagination = {
                page: parseInt(page, 10),
                pageSize: parseInt(pageSize, 10),
                pages: parseInt(pages, 10),
                totalCount: parseInt(totalCount, 10)
            }
        }
    }
}