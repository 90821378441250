import * as React from 'react'
import * as ReactDOM from 'react-dom'
import Root from './containers/Root'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import { configureStore } from './store'
import 'semantic-ui-css/semantic.min.css'
import 'rc-slider/assets/index.css'
import './index.scss'

declare global {
    interface Window {
        gtag_report_conversion: (url: string, value: number) => void;
        fbq: (action: string, event: string, data: any) => void;
    }
}

const createHistory = require("history").createBrowserHistory
const history = createHistory()

const store = configureStore(undefined, history)

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter store={store} history={history}>
            <Root />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root') as HTMLElement
)