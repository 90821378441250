import * as React from 'react'
import { ThunkDispatch } from '../../actions/util'
import { displaySignup } from '../../actions/app'
import './HomePageHeader.scss'
import { Link } from 'react-router-dom'

interface StateShape {}
interface PropsShape {
    dispatch: ThunkDispatch
}

export default class HomePageHeader extends React.Component<
    PropsShape,
    StateShape
> {
    render() {
        return (
            <div className="Header">
                <div className="content">
                    <h1 className="site-title">
                        Join the study cycle that{' '}
                        <span className="site-title-highlight">
                            dramatically improves your grades
                        </span>
                    </h1>

                    <h2 className="catchphrase">
                        Thousands of exam questions, continuous realtime
                        feedback while solving, full solutions by AI ...all
                        tailored to your success.
                    </h2>

                    <div className="cta-container">
                        <Link className="cta-item" to="/test-yourself">
                            Exam Simulator
                        </Link>
                        <Link className="cta-item" to="/browse">
                            Browse
                        </Link>
                        <li className="cta-item" onClick={this.showSignupModal}>
                            Sign Up
                        </li>

                        <Link className="cta-item" to="/upgrade">
                            Upgrade
                        </Link>
                    </div>
                </div>
                <div className="header-image-container">
                    <img
                        className="header-image"
                        src="/test-simulator-example.png"
                        alt="Test simulator"
                    />
                </div>
            </div>
        )
    }

    private showSignupModal = () => this.props.dispatch(displaySignup())
}
