import * as repr from '../rest/representers'
import * as api from '../rest/api'
import Resource from '../rest/Resource'
import { Action, ActionWithError, ActionWithPayload } from './Action'
import { RestSideEffect, makeRequest } from './util'

export const CREATE_EMAIL_CONFIRMATION_TOKEN = 'CREATE_EMAIL_CONFIRMATION_TOKEN'
export type CREATE_EMAIL_CONFIRMATION_TOKEN = typeof CREATE_EMAIL_CONFIRMATION_TOKEN

export const RECEIVE_EMAIL_CONFIRMATION_TOKEN = 'RECEIVE_EMAIL_CONFIRMATION_TOKEN'
export type RECEIVE_EMAIL_CONFIRMATION_TOKEN = typeof RECEIVE_EMAIL_CONFIRMATION_TOKEN

export const CREATE_EMAIL_CONFIRMATION_TOKEN_FAILURE =
    'CREATE_EMAIL_CONFIRMATION_TOKEN_FAILURE'
export type CREATE_EMAIL_CONFIRMATION_TOKEN_FAILURE = typeof CREATE_EMAIL_CONFIRMATION_TOKEN_FAILURE

export const UPDATE_EMAIL_CONFIRMATION_TOKEN = 'UPDATE_EMAIL_CONFIRMATION_TOKEN'
export type UPDATE_EMAIL_CONFIRMATION_TOKEN = typeof UPDATE_EMAIL_CONFIRMATION_TOKEN

export const UPDATE_EMAIL_CONFIRMATION_TOKEN_FAILURE =
    'UPDATE_EMAIL_CONFIRMATION_TOKEN_FAILURE'
export type UPDATE_EMAIL_CONFIRMATION_TOKEN_FAILURE = typeof UPDATE_EMAIL_CONFIRMATION_TOKEN_FAILURE

export type RequestEmailConfirmationTokenActionType =
    | CREATE_EMAIL_CONFIRMATION_TOKEN
    | UPDATE_EMAIL_CONFIRMATION_TOKEN
export type ReceiveEmailConfirmationTokenActionType = RECEIVE_EMAIL_CONFIRMATION_TOKEN
export type RequestEmailConfirmationTokenFailedActionType =
    | CREATE_EMAIL_CONFIRMATION_TOKEN_FAILURE
    | UPDATE_EMAIL_CONFIRMATION_TOKEN_FAILURE

export type CreateEmailConfirmationTokenAction = Action<CREATE_EMAIL_CONFIRMATION_TOKEN>
export type ReceiveEmailConfirmationTokenAction = ActionWithPayload<
RECEIVE_EMAIL_CONFIRMATION_TOKEN,
    Resource<repr.EmailConfirmation.Base>
>
export type CreateEmailConfirmationTokenFailedAction = ActionWithError<
CREATE_EMAIL_CONFIRMATION_TOKEN_FAILURE,
    Error
>

export type EmailConfirmationTokenAction =
    | CreateEmailConfirmationTokenAction
    | ReceiveEmailConfirmationTokenAction
    | CreateEmailConfirmationTokenFailedAction

export function createEmailConfirmationToken(
    email: string
): RestSideEffect<repr.EmailConfirmation.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: CREATE_EMAIL_CONFIRMATION_TOKEN },
                { action: RECEIVE_EMAIL_CONFIRMATION_TOKEN },
                { action: CREATE_EMAIL_CONFIRMATION_TOKEN_FAILURE },
                () => api.postEmailConfirmation(email)
            )
        )
    }
}

export function updateEmailConfirmationToken(
    reset_token: string
): RestSideEffect<repr.EmailConfirmation.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: UPDATE_EMAIL_CONFIRMATION_TOKEN },
                { action: RECEIVE_EMAIL_CONFIRMATION_TOKEN },
                { action: UPDATE_EMAIL_CONFIRMATION_TOKEN_FAILURE },
                () => api.putEmailConfirmation(reset_token)
            )
        )
    }
}
