import * as React from 'react'
import * as repr from '../rest/representers'
import { ThunkDispatch } from '../actions/util'
import { displayLogin } from '../actions/app'
import { replace as replaceRoute } from 'react-router-redux'

import './ViewAnswerButton.scss'
import { Dimmer } from 'semantic-ui-react'
import SolutionModal from './SolutionModal'

interface PropsShape {
    dispatch: ThunkDispatch
    auth: repr.Auth.Base | null
    correctAnswer: string | null
    solution: repr.Solution | null
    onRevealCallback?: (answerRevealed: boolean) => void
}

interface StateShape {
    answerRevealed: boolean
    showSolutionModal: boolean
}

export default class ViewAnswerButton extends React.Component<
    PropsShape,
    StateShape
> {
    state: StateShape = {
        answerRevealed: false,
        showSolutionModal: false,
    }

    render() {
        const { showSolutionModal } = this.state

        return (
            <div className="ViewAnswerButton">
                {this.viewAnswerButton}{' '}
                <Dimmer
                    active={showSolutionModal}
                    onClickOutside={() =>
                        this.setState({ showSolutionModal: false })
                    }
                    page
                >
                    <SolutionModal
                        solution={this.props.solution?.solution || ''}
                        onClose={() =>
                            this.setState({ showSolutionModal: false })
                        }
                    />
                </Dimmer>
            </div>
        )
    }

    get viewAnswerButton() {
        const { auth, correctAnswer, solution } = this.props
        const { answerRevealed } = this.state

        if (auth && correctAnswer) {
            if (!correctAnswer) {
                return null
            }

            return (
                <>
                    <div>
                        <button
                            className="view-answer-button"
                            onClick={this.toggleAnswerVisibility}
                        >
                            {answerRevealed ? 'Hide' : 'View'} Answer
                        </button>
                        <span>{this.correctAnswer}</span>
                    </div>

                    {solution && answerRevealed && (
                        <span
                            className="view-explanation-button"
                            onClick={this.toggleSolutionVisibility}
                        >
                            Full Solution
                        </span>
                    )}
                </>
            )
        } else if (auth) {
            return (
                <span className="sign-up-message">
                    <span
                        className="sign-up-button"
                        onClick={(e) => this.selectUpgrade(e)}
                    >
                        Upgrade
                    </span>{' '}
                    to view answers
                </span>
            )
        }
        return (
            <span className="sign-up-message">
                <span className="sign-up-button" onClick={this.showLoginModal}>
                    Log in
                </span>{' '}
                to view answers
            </span>
        )
    }

    private toggleAnswerVisibility = (e: React.MouseEvent<HTMLElement>) => {
        const { onRevealCallback } = this.props
        const { answerRevealed } = this.state
        e.stopPropagation()
        e.preventDefault()
        onRevealCallback && onRevealCallback(!answerRevealed)
        this.setState({ answerRevealed: !answerRevealed })
    }

    private toggleSolutionVisibility = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        e.preventDefault()
        this.setState({ showSolutionModal: true })
    }

    private get correctAnswer() {
        const { correctAnswer } = this.props
        const { answerRevealed } = this.state
        if (answerRevealed) {
            return <span className="correct-answer">{correctAnswer}</span>
        }
        return this.blur
    }

    private get blur() {
        return <div className="blur" />
    }

    private selectUpgrade = (e: React.MouseEvent<HTMLElement>) => {
        const { dispatch } = this.props
        e.stopPropagation()
        e.preventDefault()
        dispatch(replaceRoute('/upgrade'))
    }

    private showLoginModal = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        e.preventDefault()
        this.props.dispatch(displayLogin())
    }
}
