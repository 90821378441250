import { makeRequest, RestSideEffect } from './util'
import * as api from '../rest/api'
import * as repr from '../rest/representers'
import { Action, ActionWithError, ActionWithPayload } from './Action'
import Resource from '../rest/Resource'
import { TestType } from '../reducers/app'

export const CREATE_TEST = 'CREATE_TEST'
export type CREATE_TEST = typeof CREATE_TEST

export const RECEIVE_TEST = 'RECEIVE_TEST'
export type RECEIVE_TEST = typeof RECEIVE_TEST

export const CREATE_TEST_FAILURE = 'CREATE_TEST_FAILURE'
export type CREATE_TEST_FAILURE = typeof CREATE_TEST_FAILURE

export const FETCH_TESTS = 'FETCH_TESTS'
export type FETCH_TESTS = typeof FETCH_TESTS

export const RECEIVE_TESTS = 'RECEIVE_TESTS'
export type RECEIVE_TESTS = typeof RECEIVE_TESTS

export const FETCH_TESTS_FAILURE = 'FETCH_TESTS_FAILURE'
export type FETCH_TESTS_FAILURE = typeof FETCH_TESTS_FAILURE

export type RequestTestActionType = CREATE_TEST | FETCH_TESTS
export type ReceiveTestActionType = RECEIVE_TEST | RECEIVE_TESTS
export type RequestTestFailedActionType =
    | CREATE_TEST_FAILURE
    | FETCH_TESTS_FAILURE

export type RequestTestAction = Action<CREATE_TEST>
export type ReceiveTestAction = ActionWithPayload<
    RECEIVE_TEST,
    Resource<repr.Test.Base>
>
export type RequestTestFailedAction = ActionWithError<
    CREATE_TEST_FAILURE,
    Error
>

export type FetchTestsAction = Action<FETCH_TESTS>
export type ReceiveTestsAction = ActionWithPayload<
    RECEIVE_TESTS,
    Resource<repr.University.Base[]>
>
export type FetchTestsFailedAction = ActionWithError<FETCH_TESTS_FAILURE, Error>

export type TestAction =
    | RequestTestAction
    | ReceiveTestAction
    | RequestTestFailedAction
    | FetchTestsAction
    | ReceiveTestsAction
    | FetchTestsFailedAction

export function createTest(
    courseId: string,
    testType: TestType[]
): RestSideEffect<repr.Test.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: CREATE_TEST },
                { action: RECEIVE_TEST },
                { action: CREATE_TEST_FAILURE },
                ({ authToken }) => api.postTest(courseId, testType, authToken)
            )
        )
    }
}

export function fetchTests(): RestSideEffect<repr.Test.Base[]> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: FETCH_TESTS },
                { action: RECEIVE_TESTS },
                { action: FETCH_TESTS_FAILURE },
                ({ authToken }) => api.getTests(authToken)
            )
        )
    }
}
