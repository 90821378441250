import * as React from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from '../actions/util'
import { StateShape as ReduxStateShape } from '../reducers'
import { Container, Header, Button } from 'semantic-ui-react'
import { closeModal } from '../actions/app'
import { replace as replaceRoute } from 'react-router-redux'
import './UpgradeModal.scss'

interface StateShape {}

interface PropsShape {
    dispatch: ThunkDispatch
    onClose?: () => void
}

class UpgradeModal extends React.Component<PropsShape, StateShape> {
    render() {
        return (
            <div className="UpgradeModal">
                <img
                    className="close-icon"
                    src="/close.svg"
                    alt="Close icon"
                    onClick={this.closeModal}
                />
                <div className="content">
                    <Header.Subheader as="h2" className="modal-header">
                        Upgrade
                    </Header.Subheader>
                    <Container className="upgrade-message" text={true}>
                        Upgrade to get full access to solutions, test
                        simulator's premium features and much more!
                    </Container>
                    <Button
                        className="upgrade-button"
                        onClick={this.selectUpgrade}
                    >
                        Upgrade to Premium
                    </Button>
                </div>
            </div>
        )
    }

    private selectUpgrade = () => {
        const { dispatch } = this.props
        dispatch(replaceRoute('/upgrade'))
        this.closeModal()
    }

    private closeModal = () => {
        const { dispatch, onClose } = this.props
        if (onClose) {
            onClose()
        }

        dispatch(closeModal())
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    return {}
}

export default connect(mapStateToProps)(UpgradeModal)
