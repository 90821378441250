import * as React from 'react'
import { Container } from 'semantic-ui-react'
import { replace as replaceRoute } from 'react-router-redux'
import ContactUsColumn from '../components/Footer/ContactUsColumn'
import AboutUsColumn from '../components/Footer/AboutUsColumn'
import CourseLinksColumn from '../components/Footer/CourseLinksColumn'
import { Link } from 'react-router-dom'
import { ThunkDispatch } from '../actions/util'
import { displaySignup, updateTestPackFilters } from '../actions/app'
import { StateShape as ReduxStateShape } from '../reducers'
import { connect } from 'react-redux'

import './Footer.scss'
import TestPackLinksColumn from '../components/Footer/TestPackLinksColumn'

interface StateShape {}
interface PropsShape {
    dispatch: ThunkDispatch
}

class Footer extends React.Component<PropsShape, StateShape> {
    render() {
        return (
            <Container className="Footer">
                <AboutUsColumn showSignupModal={this.showSignupModal} />
                <ContactUsColumn />
                <TestPackLinksColumn />
                <CourseLinksColumn
                    setCourseFilterAndGoToBrowse={
                        this.setCourseFilterAndGoToBrowse
                    }
                />
                <div className="copyright-name">IntellyQ &copy; 2023</div>
                <Link className="sitemap-link" to="/sitemap">
                    sitemap
                </Link>
            </Container>
        )
    }

    private setCourseFilterAndGoToBrowse = (
        courseName: string,
    ) => {
        const { dispatch } = this.props
        dispatch(updateTestPackFilters({ course: courseName, testType: [] }))
        dispatch(replaceRoute('/browse'))
    }

    private showSignupModal = () => this.props.dispatch(displaySignup())
}

const mapStateToProps = (state: ReduxStateShape) => {}

export default connect(mapStateToProps)(Footer)
