import * as React from 'react'
import { Container } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

interface StateShape {}
interface PropsShape {}

export default class TestPackLinksColumn extends React.Component<
    PropsShape,
    StateShape
> {
    render() {
        return (
            <Container className="TestPackLinksColumn">
                <h4 className="column-header">Test Packages</h4>
                <div className="column-content">
                    <Link
                        to="/test-pack/university-of-toronto/PSL300/Final"
                    >
                        PSL300 - Final
                    </Link>
                    <Link
                        to="/test-pack/university-of-toronto/BCH210/Final"
                    >
                        BCH210 - Final
                    </Link>
                    <Link
                        to="/test-pack/university-of-toronto/RSM250/TT1"
                    >
                        RSM250 - TT1
                    </Link>
                    <Link
                        to="/test-pack/university-of-toronto/PSY100/Final"
                    >
                        PSY100 - Final
                    </Link>
                    <Link
                        to="/test-pack/university-of-toronto/BIO230/Final"
                    >
                        BIO230 - Final
                    </Link>
                </div>
            </Container>
        )
    }
}
