import * as React from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from '../actions/util'
import { StateShape as ReduxStateShape } from '../reducers'
import { QuestionFilters } from '../reducers/app'
import { Container, Button } from 'semantic-ui-react'
import { TestType } from '../reducers/app'
import { updateTestPackFilters } from '../actions/app'
import TestTypeFilter from '../components/Filters/TestTypeFilter'
import TextFilter from '../components/Filters/TextFilter'

import './FiltersPage.scss'

interface StateShape {}
interface PropsShape {
    dispatch: ThunkDispatch
    filters: QuestionFilters
    closeFiltersPage: () => void
}

class FiltersPage extends React.Component<PropsShape, StateShape> {
    render() {
        const { closeFiltersPage } = this.props
        return (
            <div className="FiltersPage">
                <Container className="filters-segment">
                    <h3 className="filters-header">University</h3>
                    {this.universityFilter}
                </Container>

                <Container className="filters-segment">
                    <h3 className="filters-header">Course</h3>
                    {this.courseFilter}
                </Container>

                <Container className="filters-segment">
                    <h3 className="filters-header">Test Type</h3>
                    {this.testTypeFilter}
                </Container>

                <Container className="filters-page-bottombar">
                    <Button
                        className="show-questions"
                        onClick={closeFiltersPage}
                    >
                        Show filtered test packs
                    </Button>
                </Container>
            </div>
        )
    }

    private updateTestPackFilters = (filters: Partial<QuestionFilters>) => {
        const { dispatch } = this.props
        dispatch(updateTestPackFilters(filters))
    }

    private get testTypeFilter() {
        const { filters } = this.props
        return (
            <TestTypeFilter
                currentTestSelection={filters.testType}
                onChange={this.handleTestTypeChange}
            />
        )
    }

    private get universityFilter() {
        const { filters } = this.props
        return (
            <TextFilter
                currentSelection={filters.university}
                onChange={this.handleUniversityChange}
                placeholder="e.g University of Toronto"
                value="University of Toronto - UTSG"
                disabled={true}
            />
        )
    }

    private get courseFilter() {
        const { filters } = this.props
        return (
            <TextFilter
                currentSelection={filters.course}
                onChange={this.handleCourseChange}
                placeholder="e.g Psychology, PSY100"
            />
        )
    }

    private handleTestTypeChange = (testType: TestType[]) => {
        this.updateTestPackFilters({ testType })
    }

    private handleUniversityChange = (university: string) => {
        this.updateTestPackFilters({ university })
    }

    private handleCourseChange = (course: string) => {
        this.updateTestPackFilters({ course })
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const filters = state.app.testPackFilters

    return { filters }
}

export default connect(mapStateToProps)(FiltersPage)
