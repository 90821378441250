import * as repr from '../rest/representers'
import * as api from '../rest/api'
import Resource from '../rest/Resource'
import { Action, ActionWithError, ActionWithPayload } from './Action'
import { RestSideEffect, makeRequest } from './util'

export const CREATE_PASSWORD_RESET_TOKEN = 'CREATE_PASSWORD_RESET_TOKEN'
export type CREATE_PASSWORD_RESET_TOKEN = typeof CREATE_PASSWORD_RESET_TOKEN

export const RECEIVE_PASSWORD_RESET_TOKEN = 'RECEIVE_PASSWORD_RESET_TOKEN'
export type RECEIVE_PASSWORD_RESET_TOKEN = typeof RECEIVE_PASSWORD_RESET_TOKEN

export const CREATE_PASSWORD_RESET_TOKEN_FAILURE =
    'CREATE_PASSWORD_RESET_TOKEN_FAILURE'
export type CREATE_PASSWORD_RESET_TOKEN_FAILURE = typeof CREATE_PASSWORD_RESET_TOKEN_FAILURE

export const FETCH_PASSWORD_RESET_TOKEN = 'FETCH_PASSWORD_RESET_TOKEN'
export type FETCH_PASSWORD_RESET_TOKEN = typeof FETCH_PASSWORD_RESET_TOKEN

export const FETCH_PASSWORD_RESET_TOKEN_FAILURE =
    'FETCH_PASSWORD_RESET_TOKEN_FAILURE'
export type FETCH_PASSWORD_RESET_TOKEN_FAILURE = typeof FETCH_PASSWORD_RESET_TOKEN_FAILURE

export const UPDATE_PASSWORD_RESET_TOKEN = 'UPDATE_PASSWORD_RESET_TOKEN'
export type UPDATE_PASSWORD_RESET_TOKEN = typeof UPDATE_PASSWORD_RESET_TOKEN

export const UPDATE_PASSWORD_RESET_TOKEN_FAILURE =
    'UPDATE_PASSWORD_RESET_TOKEN_FAILURE'
export type UPDATE_PASSWORD_RESET_TOKEN_FAILURE = typeof UPDATE_PASSWORD_RESET_TOKEN_FAILURE

export type RequestPasswordResetTokenActionType =
    | CREATE_PASSWORD_RESET_TOKEN
    | FETCH_PASSWORD_RESET_TOKEN
    | UPDATE_PASSWORD_RESET_TOKEN
export type ReceivePasswordResetTokenActionType = RECEIVE_PASSWORD_RESET_TOKEN
export type RequestPasswordResetTokenFailedActionType =
    | CREATE_PASSWORD_RESET_TOKEN_FAILURE
    | FETCH_PASSWORD_RESET_TOKEN_FAILURE
    | UPDATE_PASSWORD_RESET_TOKEN_FAILURE

export type CreatePasswordResetTokenAction = Action<CREATE_PASSWORD_RESET_TOKEN>
export type ReceivePasswordResetTokenAction = ActionWithPayload<
    RECEIVE_PASSWORD_RESET_TOKEN,
    Resource<repr.PasswordReset.Base>
>
export type CreatePasswordResetTokenFailedAction = ActionWithError<
    CREATE_PASSWORD_RESET_TOKEN_FAILURE,
    Error
>

export type PasswordResetTokenAction =
    | CreatePasswordResetTokenAction
    | ReceivePasswordResetTokenAction
    | CreatePasswordResetTokenFailedAction

export function createPasswordResetToken(
    email: string
): RestSideEffect<repr.PasswordReset.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: CREATE_PASSWORD_RESET_TOKEN },
                { action: RECEIVE_PASSWORD_RESET_TOKEN },
                { action: CREATE_PASSWORD_RESET_TOKEN_FAILURE },
                () => api.postPasswordReset(email)
            )
        )
    }
}

export function fetchPasswordResetToken(
    reset_token: string
): RestSideEffect<repr.PasswordReset.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: FETCH_PASSWORD_RESET_TOKEN },
                { action: RECEIVE_PASSWORD_RESET_TOKEN },
                { action: FETCH_PASSWORD_RESET_TOKEN_FAILURE },
                () => api.getPasswordReset(reset_token)
            )
        )
    }
}

export function updatePasswordResetToken(
    reset_token: string,
    password: string
): RestSideEffect<repr.PasswordReset.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: UPDATE_PASSWORD_RESET_TOKEN },
                { action: RECEIVE_PASSWORD_RESET_TOKEN },
                { action: UPDATE_PASSWORD_RESET_TOKEN_FAILURE },
                () => api.putPasswordReset(reset_token, password)
            )
        )
    }
}
