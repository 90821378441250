import * as React from 'react'
import { Container } from 'semantic-ui-react'

interface StateShape {}
interface PropsShape {
    setCourseFilterAndGoToBrowse: (courseName: string) => void
}

export default class CourseLinksColumn extends React.Component<
    PropsShape,
    StateShape
> {
    render() {
        const { setCourseFilterAndGoToBrowse } = this.props
        return (
            <Container className="CourseLinksColumn">
                <h4 className="column-header">Courses</h4>
                <div className="column-content">
                    <div onClick={() => setCourseFilterAndGoToBrowse('ECO100')}>
                        ECO100
                    </div>
                    <div onClick={() => setCourseFilterAndGoToBrowse('PSY100')}>
                        PSY100
                    </div>
                    <div onClick={() => setCourseFilterAndGoToBrowse('HMB265')}>
                        HMB265
                    </div>
                    <div onClick={() => setCourseFilterAndGoToBrowse('PSL301')}>
                        PSL301
                    </div>
                    <div onClick={() => setCourseFilterAndGoToBrowse('PHY131')}>
                        PHY131
                    </div>
                </div>
            </Container>
        )
    }
}
