import * as React from 'react'
import { Menu, Checkbox } from 'semantic-ui-react'
import * as lodash from 'lodash'
import { TestType } from '../../reducers/app'

import './TestTypeFilter.scss'

interface StateShape {}

interface PropsShape {
    currentTestSelection: TestType[]
    onChange: (testTypeList: TestType[]) => void
}

export default class TestTypeFilter extends React.Component<
    PropsShape,
    StateShape
> {
    render() {
        const { currentTestSelection } = this.props
        return (
            <div className="TestTypeFilter">
                <Menu borderless={true}>
                    <Menu.Item>
                        <Checkbox
                            label="Term Test 1"
                            checked={lodash.includes(
                                currentTestSelection,
                                TestType.TT1
                            )}
                            onChange={() => this.handleTestTypeChange(TestType.TT1)}
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <Checkbox
                            label="Midterm"
                            checked={lodash.includes(
                                currentTestSelection,
                                TestType.Midterm
                            )}
                            onChange={() => this.handleTestTypeChange(TestType.Midterm)}
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <Checkbox
                            label="Term Test 2"
                            checked={lodash.includes(
                                currentTestSelection,
                                TestType.TT2
                            )}
                            onChange={() => this.handleTestTypeChange(TestType.TT2)}
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <Checkbox
                            label="Final"
                            checked={lodash.includes(
                                currentTestSelection,
                                TestType.Final
                            )}
                            onChange={() => this.handleTestTypeChange(TestType.Final)}
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <Checkbox
                            label="Term Test 3"
                            checked={lodash.includes(
                                currentTestSelection,
                                TestType.TT3
                            )}
                            onChange={() => this.handleTestTypeChange(TestType.TT3)}
                        />
                    </Menu.Item>
                </Menu>
            </div>
        )
    }

    handleTestTypeChange = (selectedTestTypes: TestType) => {
        const { currentTestSelection, onChange } = this.props
        let testList
        if (lodash.includes(currentTestSelection, selectedTestTypes)) {
            testList = lodash.reject(
                currentTestSelection,
                t => t === selectedTestTypes
            )
        } else {
            testList = [...currentTestSelection, selectedTestTypes]
        }
        onChange(testList)
    }
}