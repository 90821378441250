import * as React from 'react'
import { StateShape as ReduxStateShape } from '../reducers'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import './Page404.scss'

interface PropsShape {}
interface StateShape {}

export class Page404 extends React.Component<PropsShape, StateShape> {
    render() {
        return (
            <div className="Page404">
                <img
                    className="illustration"
                    src='/404-illustration.svg'
                    alt='404 icon'
                />
                <div className="message">Looks like this page does not exist!</div>
                <Link className="link-to-home" to="/">Back to Civilization</Link>
            </div>
        )
    }
}

function mapStateToProps(state: ReduxStateShape) {}

export default connect(mapStateToProps)(Page404)
