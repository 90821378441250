import { schema } from 'normalizr'

export const QuestionSchema = new schema.Entity('questions')
export const CourseSchema = new schema.Entity('courses')
export const UniversitySchema = new schema.Entity(
    'universities',
    {},
    { idAttribute: 'name' }
)
export const TestSchema = new schema.Entity('tests')
export const UserSchema = new schema.Entity('users')
export const AuthSchema = new schema.Entity('auth', { user: UserSchema })
