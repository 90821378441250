import * as React from 'react'
import { ThunkDispatch } from '../actions/util'
import { StateShape as ReduxStateShape } from '../reducers'
import { connect } from 'react-redux'
import { fetchAuth } from '../actions/auth'

interface StateShape {
    authCompleted: boolean
}
interface PropsShape {
    children: JSX.Element[]
    dispatch: ThunkDispatch
 }

class AuthManager extends React.Component<PropsShape, StateShape> {
    state = { authCompleted: false }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(fetchAuth())
            .then(() => this.setState({authCompleted: true}))
            .catch(() => this.setState({authCompleted: true}))
    }

    render() {
        const { authCompleted } = this.state
        if (!authCompleted) {
            return null
        }
        return this.props.children
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    return {}
}

export default connect(mapStateToProps)(AuthManager)