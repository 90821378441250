import * as React from 'react'
import * as lodash from 'lodash'
import { Container, Icon, Button } from 'semantic-ui-react'
import { DocumentInfo } from '../../containers/ContributionForm'
import DocumentPreview from './DocumentPreview'

import './DocumentUpload.scss'

interface PropsShape {
    documentInfo: DocumentInfo
    errorMessage: string
    submitForm: () => void
    prevStep: () => void
    updateDocumentInfo: (update: Partial<DocumentInfo>) => void
}

export default class DocumentUpload extends React.Component<PropsShape> {
    fileUploadRef: React.RefObject<HTMLInputElement>

    constructor(props: PropsShape) {
        super(props)
        this.fileUploadRef = React.createRef()
    }

    render() {
        const {
            prevStep,
            documentInfo,
            updateDocumentInfo,
            errorMessage,
            submitForm
        } = this.props
        return (
            <Container className="DocumentUpload">
                <h3>Upload your Document</h3>

                <Button
                    className="upload-button"
                    onClick={() => {
                        this.fileUploadRef.current &&
                            this.fileUploadRef.current.click()
                    }}
                >
                    <span className="plus-icon">+</span>
                </Button>
                <input
                    key="Upload Images"
                    type="file"
                    ref={this.fileUploadRef}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.uploadFile(e.target.files)
                    }
                />

                <DocumentPreview
                    documentInfo={documentInfo}
                    updateDocumentInfo={updateDocumentInfo}
                />
                <Container className="form-button-container">
                    <div className="back-button" onClick={() => prevStep()}>
                        <span className="back-arrow">
                            <Icon name="chevron left" />
                        </span>
                        Back
                    </div>
                    <div
                        onClick={() => {
                            this.isPageComplete() && submitForm()
                        }}
                        className={
                            this.isPageComplete()
                                ? 'next-button'
                                : 'next-button-disabled'
                        }
                    >
                        Submit
                    </div>
                </Container>
                {errorMessage && (
                    <p className="error-message"> {errorMessage} </p>
                )}
            </Container>
        )
    }

    uploadFile = (selectedFile: FileList | null) => {
        const { documentInfo, updateDocumentInfo } = this.props
        if (!selectedFile) {
            return
        }
        lodash.forEach(selectedFile, file => {
            const reader = new FileReader()
            reader.onloadend = () => {
                const dataURL = reader.result as string
                updateDocumentInfo({
                    documentPreview: {
                        ...(documentInfo.documentPreview as {
                            [k: string]: string
                        }),
                        [file.name]: dataURL
                    },
                    document: {
                        ...(documentInfo.document as {
                            [k: string]: File
                        }),
                        [file.name]: file
                    }
                })
            }
            reader.readAsDataURL(file)
        })
    }

    isPageComplete = () => {
        const { documentInfo } = this.props
        return !lodash.isEmpty(documentInfo.document)
    }
}
