import * as React from 'react'
import * as ReactDOM from 'react-dom'

import './ContentSlider.scss'
import { ReactSVG } from 'react-svg'

interface StateShape {
    isLeftChevronHidden: boolean
    isRightChevronHidden: boolean
    scrollOffset: number
}

interface PropsShape {
    itemWidth: number
    numerOfItems: number
    children: any
}

export default class ContentSlider extends React.Component<
    PropsShape,
    StateShape
> {
    slider = React.createRef<HTMLDivElement>()

    state: StateShape = {
        isLeftChevronHidden: true,
        isRightChevronHidden: false,
        scrollOffset: 0,
    }

    componentDidUpdate(prevProps: PropsShape, prevState: StateShape) {
        const { scrollOffset } = this.state
        if (prevState.scrollOffset !== scrollOffset) {
            this.setState({
                isLeftChevronHidden: scrollOffset === 0,
            })
        }
    }

    render() {
        const { isLeftChevronHidden, isRightChevronHidden } = this.state
        return (
            <>
                <div className="item-slider" ref={this.slider}>
                    {this.props.children}
                </div>

                <ReactSVG
                    wrapper="div"
                    src="/chevron_down.svg"
                    className={`scroll-button left ${
                        isLeftChevronHidden ? 'hidden' : ''
                    }`}
                    onClick={(e) => this.onLeftChevronClick(e)}
                />

                <ReactSVG
                    wrapper="div"
                    src="/chevron_down.svg"
                    className={`scroll-button right ${
                        isRightChevronHidden ? 'hidden' : ''
                    }`}
                    onClick={(e) => this.onRightChevronClick(e)}
                />
            </>
        )
    }

    private onRightChevronClick = (e: React.MouseEvent) => {
        const { itemWidth, numerOfItems } = this.props
        const { scrollOffset } = this.state
        const SLIDER_WIDTH = itemWidth * numerOfItems
        e.stopPropagation()
        const node = this.sliderNode
        if (!node) {
            return
        }
        const maxOffset = SLIDER_WIDTH - this.calculateWidthToScroll(node)
        let newScrollOffset = scrollOffset + this.calculateWidthToScroll(node)
        if (newScrollOffset >= maxOffset) {
            newScrollOffset = maxOffset
        }
        node.scrollTo(newScrollOffset, 0)
        this.setState({ scrollOffset: newScrollOffset })
    }

    private onLeftChevronClick = (e: React.MouseEvent) => {
        const { scrollOffset } = this.state
        e.stopPropagation()
        const node = this.sliderNode
        if (!node) {
            return
        }
        let newScrollOffset = scrollOffset - this.calculateWidthToScroll(node)
        if (newScrollOffset <= 0) {
            newScrollOffset = 0
        }
        node.scrollTo(newScrollOffset, 0)
        this.setState({ scrollOffset: newScrollOffset })
    }

    private calculateWidthToScroll = (node: Element) => {
        const { itemWidth } = this.props
        const width = node ? node.getBoundingClientRect().width : 0
        const numberOfCurationsToScroll = Math.floor(width / itemWidth)
        return numberOfCurationsToScroll * itemWidth
    }

    private get sliderNode() {
        const node = this.slider.current
            ? (ReactDOM.findDOMNode(this.slider.current) as Element)
            : null
        return node
    }
}
