import * as React from 'react'
import {
    injectStripe,
    ReactStripeElements,
    CardNumberElement,
    CardCVCElement,
    CardExpiryElement,
} from 'react-stripe-elements'
import * as repr from '../../rest/representers'
import { displayLogin } from '../../actions/app'
import { ThunkDispatch } from '../../actions/util'
import { Input, Dimmer, Loader } from 'semantic-ui-react'
import { createSubscription } from '../../actions/subscriptions'

interface PropsShape {
    dispatch: ThunkDispatch
    billingPlan: 'yearly' | 'monthly' | 'quarterly'
    referralCode: string
    auth: repr.Auth.Base | null
}

interface StateShape {
    errorMessage: string
    customerName: string
    isSubmitting: boolean
}

const priceList = {
    yearly: 59.88,
    monthly: 9.99,
    quarterly: 22.47,
}

class PaymentForm extends React.Component<
    PropsShape & ReactStripeElements.InjectedStripeProps,
    StateShape
> {
    state: StateShape = {
        errorMessage: '',
        customerName: '',
        isSubmitting: false,
    }

    render() {
        const { auth, dispatch } = this.props
        const { customerName, errorMessage, isSubmitting } = this.state
        return (
            <div className="checkout">
                {isSubmitting && (
                    <Dimmer active>
                        <Loader />
                    </Dimmer>
                )}

                <CardNumberElement placeholder="Card Number" />

                <Input
                    className="name-input"
                    placeholder="Name on Card"
                    onChange={(e) =>
                        this.setState({ customerName: e.currentTarget.value })
                    }
                    value={customerName}
                />

                <div className="additional-card-details">
                    <CardExpiryElement className="expiry-input" />
                    <CardCVCElement />
                </div>

                {errorMessage && (
                    <div className="error-message">{errorMessage}</div>
                )}

                <button
                    className="submit-button"
                    onClick={() => {
                        if (!auth) {
                            dispatch(displayLogin())
                            return
                        }

                        this.setState({ isSubmitting: true })
                        this.submit()
                    }}
                >
                    Complete
                </button>
            </div>
        )
    }

    private sendAnalytics = (
        billingPlan: 'yearly' | 'monthly' | 'quarterly'
    ) => {
        window.fbq('track', 'Subscribe', {
            value: priceList[billingPlan],
            currency: 'CAD',
        })

        window.gtag_report_conversion(
            window.location.href,
            priceList[billingPlan]
        )
    }

    private submit = () => {
        const { dispatch, billingPlan, referralCode } = this.props
        const { customerName } = this.state

        this.props
            .stripe!.createToken({ name: customerName })
            .then((response: any) => {
                if (response.token) {
                    dispatch(
                        createSubscription(
                            billingPlan,
                            referralCode,
                            response.token.id
                        )
                    )
                        .then(() => {
                            try {
                                this.sendAnalytics(billingPlan)
                            } catch (error) {
                                console.error(error)
                            }

                            setTimeout(() => window.location.reload(), 500)
                        })
                        .catch((e) =>
                            this.setState({
                                errorMessage: e.message,
                                isSubmitting: false,
                            })
                        )
                } else if (response.error) {
                    this.setState({
                        errorMessage: response.error.message as string,
                        isSubmitting: false,
                    })
                }
            })
    }
}

export default injectStripe(PaymentForm)
