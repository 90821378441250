import { AuthAction, RECEIVE_AUTH } from '../actions/auth'
import * as repr from '../rest/representers'

export type StateShape = repr.Auth.Base | null

export function authReducer(
    state: StateShape | null = null,
    action: AuthAction
): StateShape {
    switch (action.type) {
        case RECEIVE_AUTH:
            if (action.meta && action.meta.fromDelete) {
                return null
            }
            return action.payload.data
    }
    return state
}