import { combineReducers, Reducer } from 'redux'
import { entitiesReducer, StateShape as EntitiesStateShape } from './entities'
import { setsReducer, StateShape as SetsStateShape } from './sets'

import {
    paginationReducer,
    StateShape as PaginationStateShape
} from './pagination'

export interface StateShape {
    entities: EntitiesStateShape
    pagination: PaginationStateShape
    sets: SetsStateShape
}

export const remoteReducer: Reducer<StateShape> = combineReducers<StateShape>({
    entities: entitiesReducer,
    pagination: paginationReducer,
    sets: setsReducer
})
