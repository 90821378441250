import * as React from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from '../actions/util'
import { StateShape as ReduxStateShape } from '../reducers'
import { Input, Button, Dropdown } from 'semantic-ui-react'
import { createFlaggedQuestion } from '../actions/flaggedQuestions'
import './FlagQuestion.scss'

interface StateShape {
    description: string
    additionalDetails: string
    errorMessage: string | null
}

interface PropsShape {
    dispatch: ThunkDispatch
    questionId: string
    onClose: () => void
    onSubmit: () => void
}

const messageOptions = [
    {
        key: "Copyright infringement",
        text: "Copyright infringement",
        value: "Copyright infringement"
    },
    {
        key: 'Duplicate question',
        text: 'Duplicate question',
        value: 'Duplicate question'
    },
    {
        key: 'Contains inappropriate material',
        text: 'Contains inappropriate material',
        value: 'Contains inappropriate material'
    },
    {
        key: 'Other',
        text: 'Other',
        value: 'Other'
    }
]

class FlagQuestion extends React.Component<PropsShape, StateShape> {
    state = {
        description: '',
        additionalDetails: '',
        errorMessage: null
    }

    render() {
        const { description, additionalDetails, errorMessage } = this.state
        const { onClose } = this.props
        return (
            <div className="FlagQuestion">
                <img
                    className="close-icon"
                    src='/close.svg'
                    alt='close icon'
                    onClick={() => onClose()}
                />
                <div className="content">
                    <h2 className='flag-question-title'>Report this question</h2>

                    <div className="subheader">What's the issue?</div>
                    <Dropdown
                        placeholder="Enter message here"
                        fluid
                        selection
                        options={messageOptions}
                        onChange={(e, d) =>
                            this.setState({ description: d.value as string })
                        }
                        value={description}
                        className="description-dropdown"
                    />

                    <div className="subheader">Additional Details</div>
                    <Input
                        onChange={e =>
                            this.setState({
                                additionalDetails: e.currentTarget.value
                            })
                        }
                        placeholder="Enter message here"
                        value={additionalDetails}
                    />

                    {errorMessage && <div>{errorMessage}</div>}

                    <Button
                        className={`submit-button ${
                            description ? 'active' : ''
                        }`}
                        onClick={this.submitReport}
                    >
                        Submit Report
                    </Button>
                </div>
            </div>
        )
    }

    private submitReport = () => {
        const { dispatch, questionId, onSubmit, onClose } = this.props
        const { description, additionalDetails } = this.state
        if (!description) {
            return
        }

        dispatch(
            createFlaggedQuestion(questionId, description, additionalDetails)
        )
            .then(() => {
                onSubmit()
                onClose()
            })
            .catch(e => this.setState({ errorMessage: e.message }))
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    return {}
}

export default connect(mapStateToProps)(FlagQuestion)
