import * as React from 'react'
import { ThunkDispatch } from '../actions/util'
import { Input, Divider, Container, Header, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { StateShape as ReduxStateShape } from '../reducers'
import { createUser } from '../actions/users'
import { createAuth } from '../actions/auth'
import { closeModal, displayLogin } from '../actions/app'
import { replace as replaceRoute } from 'react-router-redux'
import { createEmailConfirmationToken } from '../actions/emailConfirmationTokens'
import * as H from 'history'
import './Signup.scss'

interface StateShape {
    email: string
    password: string
    errorMessage: string | null
}
interface PropsShape {
    dispatch: ThunkDispatch
    onClose?: () => void
    onSignup?: () => void
    loginAsRedirect?: boolean
    location?: H.Location
}

class Signup extends React.Component<PropsShape, StateShape> {
    state = {
        email: '',
        password: '',
        errorMessage: null,
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleEnterKeyPress, false)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEnterKeyPress, false)
    }

    render() {
        const { errorMessage, password } = this.state
        return (
            <div className="Signup">
                <img
                    className="close-icon"
                    src="/close.svg"
                    alt="close icon"
                    onClick={this.closeModal}
                />
                <div className="content">
                    <Header.Subheader as="h2" className="modal-header">
                        Join IntellyQ
                    </Header.Subheader>
                    <Container className="signup-message" text={true}>
                        Sign up to get access to solutions, daily questions and
                        much more!
                    </Container>
                    <Input
                        onChange={this.handleEmailChange}
                        placeholder="Email Address"
                        type="email"
                    />
                    <Input
                        onChange={this.handlePasswordChange}
                        placeholder="Password"
                        type="password"
                    />
                    {!this.isPasswordValid(password) && (
                        <div className="password-validation-message">
                            Your password must contain at least 8 characters
                        </div>
                    )}
                    <Button
                        className="signup-login-button"
                        onClick={this.createAccount}
                    >
                        Create Account
                    </Button>
                    <Container className="terms-message" text={true}>
                        By registering, I agree to the
                        <a
                            href="/terms-of-service"
                            className="terms-link"
                            target="_blank"
                        >
                            {' '}
                            Terms
                        </a>{' '}
                        and
                        <a
                            href="/privacy-policy"
                            className="terms-link"
                            target="_blank"
                        >
                            {' '}
                            Privacy Policy
                        </a>
                        .
                    </Container>
                    <Divider className="signup-divider" />
                    <Container className="switch-message" text={true}>
                        Already have an account?{' '}
                        <span className="log-in" onClick={this.showLoginModal}>
                            Log In
                        </span>
                    </Container>
                    {errorMessage && (
                        <p className="error-message"> {errorMessage} </p>
                    )}
                </div>
            </div>
        )
    }

    private isPasswordValid = (password: string) => {
        if (password === '') {
            return true
        }
        return password.length >= 8
    }

    private handleEmailChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({ email: e.currentTarget.value })
    }

    private handlePasswordChange = (
        e: React.SyntheticEvent<HTMLInputElement>
    ) => {
        this.setState({ password: e.currentTarget.value })
    }

    private closeModal = () => {
        const { dispatch, onClose } = this.props
        if (onClose) {
            onClose()
        }
        dispatch(closeModal())
    }

    private createAccount = () => {
        const { dispatch, onSignup } = this.props
        const { email, password } = this.state

        if (!this.isPasswordValid(password)) {
            return
        }

        const lowerCaseEmail = email.toLowerCase();

        dispatch(createUser(lowerCaseEmail, password))
            .then(() => dispatch(createAuth(lowerCaseEmail, password)))
            .then(() => {
                dispatch(createEmailConfirmationToken(lowerCaseEmail))
            })
            .then(() => {
                if (onSignup) {
                    onSignup()
                }
                dispatch(closeModal())
            })
            .catch((e) => this.setState({ errorMessage: e.message }))
    }

    private showLoginModal = () => {
        const { loginAsRedirect, dispatch } = this.props
        if (loginAsRedirect) {
            dispatch(replaceRoute('/app/login'))
        } else {
            this.props.dispatch(displayLogin())
        }
    }

    private handleEnterKeyPress = (evt: KeyboardEvent) => {
        if (evt.keyCode === 13) {
            this.createAccount()
        }
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    return {}
}

export default connect(mapStateToProps)(Signup)
