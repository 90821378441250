import { normalize, schema } from 'normalizr'
import * as repr from '../../rest/representers'
import * as icepick from 'icepick'
import * as lodash from 'lodash'
import * as models from '../../models'
import {
    QuestionAction,
    RECEIVE_QUESTIONS,
    ReceiveQuestionsAction,
    RECEIVE_QUESTION,
    ReceiveQuestionAction
} from '../../actions/questions'

import {
    CourseAction,
    RECEIVE_COURSES,
    ReceiveCoursesAction,
    RECEIVE_COURSE,
    ReceiveCourseAction
} from '../../actions/courses'

import {
    UniversityAction,
    RECEIVE_UNIVERSITIES,
    ReceiveUniversitiesAction
} from '../../actions/universities'

import {
    SearchAction,
    RECEIVE_SEARCH_RESULTS,
    ReceiveSearchResultsAction
} from '../../actions/search'

import {
    UserAction,
    ReceiveUserAction,
    RECEIVE_USER
} from '../../actions/users'

import { AuthAction, ReceiveAuthAction, RECEIVE_AUTH } from '../../actions/auth'

import {
    TestAction,
    ReceiveTestsAction,
    RECEIVE_TESTS,
    ReceiveTestAction,
    RECEIVE_TEST
} from '../../actions/tests'

type Action =
    | QuestionAction
    | CourseAction
    | UniversityAction
    | SearchAction
    | UserAction
    | AuthAction
    | TestAction

export interface StateShape {
    questions: { [id: string]: repr.Question.Base }
    courses: { [id: string]: repr.Course.Base }
    universities: { [name: string]: repr.University.Base }
    users: { [id: string]: repr.User.Base }
    auth: { [id: string]: repr.Auth.Base }
    tests: { [id: string]: repr.Test.Base }
}

const defaultState = {
    questions: {},
    courses: {},
    universities: {},
    users: {},
    auth: {},
    tests: {}
}

export function entitiesReducer(
    state: StateShape = defaultState,
    action: Action
): StateShape {
    switch (action.type) {
        case RECEIVE_QUESTIONS:
            return handleReceiveQuestions(state, action)
        case RECEIVE_QUESTION:
            return handleReceiveQuestion(state, action)
        case RECEIVE_COURSES:
            return handleReceiveCourses(state, action)
        case RECEIVE_COURSE:
            return handleReceiveCourse(state, action)
        case RECEIVE_UNIVERSITIES:
            return handleReceiveUniversities(state, action)
        case RECEIVE_SEARCH_RESULTS:
            return handleReceiveSearchResults(state, action)
        case RECEIVE_USER:
            return handleReceiveUser(state, action)
        case RECEIVE_AUTH:
            return handleReceiveAuth(state, action)
        case RECEIVE_TESTS:
            return handleReceiveTests(state, action)
        case RECEIVE_TEST:
            return handleReceiveTest(state, action)
    }
    return state
}

function handleReceiveQuestions(
    state: StateShape,
    action: ReceiveQuestionsAction
): StateShape {
    return handleReceiveEntity(
        state,
        models.QuestionFactory.schemaForArray,
        action.payload.data
    )
}

function handleReceiveQuestion(
    state: StateShape,
    action: ReceiveQuestionAction
): StateShape {
    return handleReceiveEntity(
        state,
        models.QuestionFactory.schemaForEntity,
        action.payload.data
    )
}

function handleReceiveTests(
    state: StateShape,
    action: ReceiveTestsAction
): StateShape {
    return handleReceiveEntity(
        state,
        models.TestFactory.schemaForArray,
        action.payload.data
    )
}

function handleReceiveTest(
    state: StateShape,
    action: ReceiveTestAction
): StateShape {
    return handleReceiveEntity(
        state,
        models.TestFactory.schemaForEntity,
        action.payload.data
    )
}

function handleReceiveCourses(
    state: StateShape,
    action: ReceiveCoursesAction
): StateShape {
    return handleReceiveEntity(
        state,
        models.CourseFactory.schemaForArray,
        action.payload.data
    )
}

function handleReceiveCourse(
    state: StateShape,
    action: ReceiveCourseAction
): StateShape {
    return handleReceiveEntity(
        state,
        models.CourseFactory.schemaForEntity,
        action.payload.data
    )
}

function handleReceiveUniversities(
    state: StateShape,
    action: ReceiveUniversitiesAction
): StateShape {
    return handleReceiveEntity(
        state,
        models.UniversityFactory.schemaForArray,
        action.payload.data
    )
}

function handleReceiveUser(
    state: StateShape,
    action: ReceiveUserAction
): StateShape {
    return handleReceiveEntity(
        state,
        models.UserFactory.schemaForEntity,
        action.payload.data
    )
}

function handleReceiveAuth(
    state: StateShape,
    action: ReceiveAuthAction
): StateShape {
    return handleReceiveEntity(
        state,
        models.AuthFactory.schemaForEntity,
        action.payload.data
    )
}

function handleReceiveSearchResults(
    state: StateShape,
    action: ReceiveSearchResultsAction
): StateShape {
    const denormQuestions = lodash.filter(
        action.payload.data,
        p => p.type === 'question_bare'
    )
    const denormCourses = lodash.filter(
        action.payload.data,
        p => p.type === 'course_bare'
    )
    const denormUniversities = lodash.filter(
        action.payload.data,
        p => p.type === 'university'
    )

    const normalizedQuestion = normalize(
        denormQuestions,
        models.QuestionFactory.schemaForArray
    ).entities as Partial<StateShape>
    const normalizedCourses = normalize(
        denormCourses,
        models.CourseFactory.schemaForArray
    ).entities as Partial<StateShape>
    const normalizedUniversities = normalize(
        denormUniversities,
        models.UniversityFactory.schemaForArray
    ).entities as Partial<StateShape>

    let nextState = icepick.merge(state, normalizedQuestion)
    nextState = icepick.merge(nextState, normalizedCourses)
    nextState = icepick.merge(nextState, normalizedUniversities)

    return nextState
}

function handleReceiveEntity<T>(
    state: StateShape,
    entitySchema: schema.Entity | schema.Array,
    entity: T
): StateShape {
    const normalized = normalize(entity, entitySchema).entities as Partial<
        StateShape
    >
    return icepick.merge(state, normalized)
}
