import * as React from 'react'
import './SolutionModal.scss'

interface StateShape {}

interface PropsShape {
    solution: string
    onClose: () => void
}

class SolutionModal extends React.Component<PropsShape, StateShape> {
    render() {
        const { onClose, solution } = this.props
        return (
            <div className="SolutionModal">
                <img
                    className="close-icon"
                    src="/close.svg"
                    alt="close icon"
                    onClick={() => onClose()}
                />
                <div className="content">
                    <h2 className="solution-title">Detailed Solution</h2>

                    <div
                        className="solution-text"
                        dangerouslySetInnerHTML={{ __html: solution }}
                    />
                </div>
            </div>
        )
    }
}

export default SolutionModal
