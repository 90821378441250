import * as React from 'react'
import { Container } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

interface StateShape {}
interface PropsShape {
    showSignupModal: () => void;
}

export default class AboutUsColumn extends React.Component<
    PropsShape,
    StateShape
> {
    render() {
        return (
            <Container className="AboutUsColumn">
                <h4 className="column-header">Resources</h4>
                <div className="column-content">
                    <Link to="https://intellyq.com">Home</Link>
                    <div onClick={this.props.showSignupModal}>Join</div>
                    <Link to="/contribute">Contribute</Link>
                </div>
            </Container>
        )
    }
}