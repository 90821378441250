import { makeRequest, RestSideEffect } from './util'
import * as api from '../rest/api'
import * as repr from '../rest/representers'
import { Action, ActionWithError, ActionWithPayload } from './Action'
import Resource from '../rest/Resource'

export const CREATE_FLAGGED_QUESTION = 'CREATE_FLAGGED_QUESTION'
export type CREATE_FLAGGED_QUESTION = typeof CREATE_FLAGGED_QUESTION

export const RECEIVE_FLAGGED_QUESTION = 'RECEIVE_FLAGGED_QUESTION'
export type RECEIVE_FLAGGED_QUESTION = typeof RECEIVE_FLAGGED_QUESTION

export const CREATE_FLAGGED_QUESTION_FAILURE = 'CREATE_FLAGGED_QUESTION_FAILURE'
export type CREATE_FLAGGED_QUESTION_FAILURE = typeof CREATE_FLAGGED_QUESTION_FAILURE

export type RequestFlaggedQuestionActionType = CREATE_FLAGGED_QUESTION
export type ReceiveFlaggedQuestionActionType = RECEIVE_FLAGGED_QUESTION
export type RequestFlaggedQuestionFailedActionType = CREATE_FLAGGED_QUESTION_FAILURE

export type RequestFlaggedQuestionAction = Action<CREATE_FLAGGED_QUESTION>
export type ReceiveFlaggedQuestionAction = ActionWithPayload<
    RECEIVE_FLAGGED_QUESTION,
    Resource<repr.FlaggedQuestion.Base>
>
export type RequestFlaggedQuestionFailedAction = ActionWithError<
    CREATE_FLAGGED_QUESTION_FAILURE,
    Error
>

export type FlaggedQuestionAction =
    | RequestFlaggedQuestionAction
    | ReceiveFlaggedQuestionAction
    | RequestFlaggedQuestionFailedAction

export function createFlaggedQuestion(
    questionId: string,
    description: string,
    additionalDetails: string
): RestSideEffect<repr.FlaggedQuestion.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: CREATE_FLAGGED_QUESTION },
                { action: RECEIVE_FLAGGED_QUESTION },
                { action: CREATE_FLAGGED_QUESTION_FAILURE },
                ({ authToken }) =>
                    api.postFlaggedQuestion(
                        authToken,
                        questionId,
                        description,
                        additionalDetails
                    )
            )
        )
    }
}
