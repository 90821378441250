import * as icepick from "icepick";

import {
  QuestionAction,
  RECEIVE_QUESTIONS,
  ReceiveQuestionsAction
} from "../../actions/questions";

export interface PaginationInfo {
  page: number;
  pageSize: number;
  pages: number;
  totalCount: number;
}

export interface StateShape {
  properties?: PaginationInfo;
}

type Action = QuestionAction;

export function paginationReducer(
  state: StateShape = {},
  action: Action
): StateShape {
  switch (action.type) {
    case RECEIVE_QUESTIONS:
      return handleReceiveQuestions(state, action);
  }

  return state;
}

function handleReceiveQuestions(
  state: StateShape,
  action: ReceiveQuestionsAction
) {
  const pagination = action.payload.pagination;
  if (!pagination) {
    return state;
  }

  return icepick.merge(state, { properties: { ...pagination } });
}
