import * as React from 'react'
import RootRouter from './RootRouter'
import ModalManager from './ModalManager'
import AuthManager from './AuthManager'
import Helmet from 'react-helmet'

interface StateShape {}
interface PropsShape {}

class Root extends React.Component<PropsShape, StateShape> {
    render() {
        return (
            <>
                <Helmet>
                    <title>
                        IntellyQ: Search and Solve Thousands of Questions
                    </title>
                    <meta
                        name="description"
                        content="Redefine the way you study by searching our intelligent question bank and solving questions using our interactive test simulator."
                    />
                </Helmet>
                <AuthManager>
                    <RootRouter />
                    <ModalManager />
                </AuthManager>
            </>
        )
    }
}

export default Root
