import * as React from 'react'
import * as lodash from 'lodash'
import * as repr from '../rest/representers'
import { ThunkDispatch } from '../actions/util'
import { QuestionDetailed, QuestionBare, QuestionFactory } from '../models'
import { connect } from 'react-redux'
import { StateShape as ReduxStateShape } from '../reducers'
import { withRouter, RouteComponentProps } from 'react-router'
import { fetchQuestion, fetchSimilarQuestions } from '../actions/questions'
import { Container, Dimmer, Loader } from 'semantic-ui-react'
import KeyDetails from '../components/QuestionDetailsPage/KeyDetails'
import SimilarQuestions from '../components/QuestionDetailsPage/SimilarQuestions'
import TopBar from './TopBar'
import { revealDropdownMenu, hideDropdownMenu } from '../actions/app'
import { CSSTransition } from 'react-transition-group'
import DropdownMenu from './DropdownMenu'
import QuestionAnalytics from '../components/QuestionDetailsPage/QuestionAnalytics'
import ViewAnswerButton from '../components/ViewAnswerButton'
import Footer from './Footer'
import Helmet from 'react-helmet'
import FlagQuestion from './FlagQuestion'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './QuestionDetailsPage.scss'
import { ReactSVG } from 'react-svg'

interface StateShape {
    showFlagQuestionModal: boolean
    answerRevealed: boolean
    isLoadingQuestions: boolean
}

interface InjectedProps {
    auth: repr.Auth.Base | null
    dispatch: ThunkDispatch
    question: QuestionDetailed | QuestionBare | undefined
    questionId: string
    similarQuestions: (QuestionDetailed | QuestionBare)[]
    isDropdownMenuExpanded: boolean
}

type PropsShape = InjectedProps & RouteComponentProps<{ questionText: string }>

function extractQuestionIdFromUrl(questionText: string) {
    const splitText = lodash.split(questionText, '-')
    return lodash.last(splitText) as string
}

class QuestionDetailsPage extends React.PureComponent<PropsShape, StateShape> {
    state: StateShape = {
        showFlagQuestionModal: false,
        answerRevealed: false,
        isLoadingQuestions: true,
    }

    componentDidMount() {
        const { dispatch, questionId } = this.props
        dispatch(fetchQuestion(questionId)).finally(() =>
            this.setState({ isLoadingQuestions: false })
        )

        dispatch(fetchSimilarQuestions(questionId))
    }

    componentDidUpdate(prevProps: PropsShape) {
        const { auth, questionId, dispatch } = this.props
        if (prevProps.auth !== auth) {
            dispatch(fetchQuestion(questionId)).finally(() =>
                this.setState({ isLoadingQuestions: false })
            )

            dispatch(fetchSimilarQuestions(questionId))
        }
    }

    render() {
        const {
            question,
            dispatch,
            auth,
            similarQuestions,
            isDropdownMenuExpanded,
        } = this.props
        const { showFlagQuestionModal, answerRevealed, isLoadingQuestions } =
            this.state

        if (!question && !isLoadingQuestions) {
            return (
                <Container className="QuestionDetailsPage">
                    <TopBar withSearch={true} logo="main" />

                    <CSSTransition
                        classNames="appear"
                        timeout={300}
                        in={isDropdownMenuExpanded}
                        unmountOnExit={true}
                    >
                        <DropdownMenu
                            onMenuItemClick={this.toggleDropdownMenu}
                        />
                    </CSSTransition>

                    <div className="question-not-found">
                        <ReactSVG
                            wrapper="div"
                            src="/empty.svg"
                            className="not-found-icon"
                        />
                        Looks like this question is no longer available! Don't
                        worry, you'll find similar questions by clicking the
                        link below.
                        <a href="/browse" className="browse-link">
                            Continue to Browse
                        </a>
                    </div>

                    <Footer />
                </Container>
            )
        } else if (isLoadingQuestions) {
            return (
                <Container className="QuestionDetailsPage">
                    <TopBar withSearch={true} logo="main" />

                    <CSSTransition
                        classNames="appear"
                        timeout={300}
                        in={isDropdownMenuExpanded}
                        unmountOnExit={true}
                    >
                        <DropdownMenu
                            onMenuItemClick={this.toggleDropdownMenu}
                        />
                    </CSSTransition>

                    <div className="loader-container">
                        <Loader active inline="centered" />
                    </div>
                    <Footer />
                </Container>
            )
        }

        return (
            <Container className="QuestionDetailsPage">
                <Helmet>
                    <title>
                        {`${question.data.course.course_code} ${question.data.question}`}
                    </title>
                    <meta
                        name="description"
                        content={`${
                            question.data.question
                        } ${question.data.answers
                            .map((a) => a.answer)
                            .join(' ')}`}
                    />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>

                <TopBar withSearch={true} logo="main" />

                <CSSTransition
                    classNames="appear"
                    timeout={300}
                    in={isDropdownMenuExpanded}
                    unmountOnExit={true}
                >
                    <DropdownMenu onMenuItemClick={this.toggleDropdownMenu} />
                </CSSTransition>

                <div className="header-container">
                    <div className="question-container">
                        <h1 className="question noselect">
                            {question.data.question}
                        </h1>
                        <div className="answers noselect">{this.answers}</div>

                        {!answerRevealed && (
                            <button
                                className="report-button"
                                onClick={() =>
                                    this.setState({
                                        showFlagQuestionModal: true,
                                    })
                                }
                            >
                                <img
                                    className="flag-illustration"
                                    src="/flag.svg"
                                    alt="Flag icon"
                                />{' '}
                                Report this question
                            </button>
                        )}

                        <ViewAnswerButton
                            dispatch={dispatch}
                            auth={auth}
                            solution={
                                question instanceof QuestionDetailed
                                    ? question.data.solution[0] || null
                                    : null
                            }
                            correctAnswer={
                                question instanceof QuestionDetailed
                                    ? question.data.correct_answer
                                    : null
                            }
                            onRevealCallback={(revealed: boolean) =>
                                this.setState({ answerRevealed: revealed })
                            }
                        />
                    </div>

                    <div className="key-details-container-desktop">
                        <KeyDetails question={question} />
                    </div>
                </div>

                <div className="page-sections">
                    <Container className="key-details-container">
                        <KeyDetails question={question} />
                    </Container>

                    <QuestionAnalytics />

                    <SimilarQuestions
                        questions={similarQuestions}
                        auth={auth}
                        dispatch={dispatch}
                    />
                </div>
                <Footer />

                <Dimmer
                    active={showFlagQuestionModal}
                    onClickOutside={() =>
                        this.setState({ showFlagQuestionModal: false })
                    }
                    page
                >
                    <FlagQuestion
                        questionId={question.data.id}
                        onClose={() =>
                            this.setState({ showFlagQuestionModal: false })
                        }
                        onSubmit={() =>
                            toast.success('Your report has been submitted!', {
                                position: toast.POSITION.TOP_CENTER,
                            })
                        }
                    />
                </Dimmer>
                <ToastContainer autoClose={5000} />
            </Container>
        )
    }

    private get answers() {
        const { question } = this.props

        if (!question) {
            return null
        }

        return question.data.answers.map((a) => (
            <div className="answer" key={a.id}>
                {a.answer}
            </div>
        ))
    }

    private toggleDropdownMenu = () => {
        const { isDropdownMenuExpanded, dispatch } = this.props
        if (isDropdownMenuExpanded) {
            dispatch(hideDropdownMenu())
        } else {
            dispatch(revealDropdownMenu())
        }
    }
}

const mapStateToProps = (
    state: ReduxStateShape,
    props: RouteComponentProps<{ questionText: string }>
) => {
    const similarQuestionIds = state.remote.sets.similarQuestionIds

    const isDropdownMenuExpanded = state.app.isDropdownMenuExpanded
    const auth = state.auth
    const questionId = extractQuestionIdFromUrl(props.match.params.questionText)
    const question = QuestionFactory.fromState(state, questionId)

    const similarQuestions = QuestionFactory.fromState(
        state,
        similarQuestionIds
    )

    return {
        auth,
        questionId,
        question,
        similarQuestions,
        isDropdownMenuExpanded,
    }
}

export default withRouter(connect(mapStateToProps)(QuestionDetailsPage))
