import * as repr from '../rest/representers'
import * as api from '../rest/api'
import Resource from '../rest/Resource'
import { Action, ActionWithError, ActionWithPayload } from './Action'
import { RestSideEffect, makeRequest } from './util'

export const CREATE_AUTH = 'CREATE_AUTH'
export type CREATE_AUTH = typeof CREATE_AUTH

export const RECEIVE_AUTH = 'RECEIVE_AUTH'
export type RECEIVE_AUTH = typeof RECEIVE_AUTH

export const CREATE_AUTH_FAILURE = 'CREATE_AUTH_FAILURE'
export type CREATE_AUTH_FAILURE = typeof CREATE_AUTH_FAILURE

export const FETCH_AUTH = 'FETCH_AUTH'
export type FETCH_AUTH = typeof FETCH_AUTH

export const FETCH_AUTH_FAILURE = 'FETCH_AUTH_FAILURE'
export type FETCH_AUTH_FAILURE = typeof FETCH_AUTH_FAILURE

export const DELETE_AUTH = 'DELETE_AUTH'
export type DELETE_AUTH = typeof DELETE_AUTH

export const DELETE_AUTH_FAILURE = 'DELETE_AUTH_FAILURE'
export type DELETE_AUTH_FAILURE = typeof DELETE_AUTH_FAILURE

export type RequestAuthActionType = CREATE_AUTH | FETCH_AUTH | DELETE_AUTH
export type ReceiveAuthActionType = RECEIVE_AUTH
export type RequestAuthFailedActionType =
    | CREATE_AUTH_FAILURE
    | FETCH_AUTH_FAILURE
    | DELETE_AUTH_FAILURE

export type CreateAuthAction = Action<CREATE_AUTH>
export type ReceiveAuthAction = ActionWithPayload<
    RECEIVE_AUTH,
    Resource<repr.Auth.Base>
>
export type CreateAuthFailedAction = ActionWithError<CREATE_AUTH_FAILURE, Error>

export type AuthAction =
    | CreateAuthAction
    | ReceiveAuthAction
    | CreateAuthFailedAction

export function createAuth(
    email: string,
    password: string
): RestSideEffect<repr.Auth.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: CREATE_AUTH },
                { action: RECEIVE_AUTH },
                { action: CREATE_AUTH_FAILURE },
                () => api.postAuth(email, password)
            )
        )
    }
}

export function fetchAuth(): RestSideEffect<repr.Auth.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: FETCH_AUTH },
                { action: RECEIVE_AUTH },
                { action: FETCH_AUTH_FAILURE },
                () => api.getAuth()
            )
        )
    }
}

export function deleteAuth(): RestSideEffect<repr.Auth.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: DELETE_AUTH },
                { action: RECEIVE_AUTH, meta: { fromDelete: true } },
                { action: DELETE_AUTH_FAILURE },
                () => api.deleteAuth()
            )
        )
    }
}
