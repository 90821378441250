import * as React from 'react'
import * as repr from '../../rest/representers'
import { Container, Radio } from 'semantic-ui-react'
import ViewAnswerButton from '../ViewAnswerButton'
import { ThunkDispatch } from '../../actions/util'

import './TestQuestion.scss'
import { ReactSVG } from 'react-svg'

interface PropsShape {
    testQuestion: repr.TestQuestion.Base
    onAnswerSelection: (questionId: string, answerId: string) => void
    selectedAnswer: string
    revealQuestionResults: boolean
    dispatch: ThunkDispatch
    auth: repr.Auth.Base | null
}

interface StateShape {}

export default class TestQuestion extends React.PureComponent<
    PropsShape,
    StateShape
> {
    render() {
        const {
            testQuestion,
            selectedAnswer,
            revealQuestionResults,
            dispatch,
            auth,
        } = this.props
        return (
            <Container className="TestQuestion">
                <div className="question-text-container">
                    {revealQuestionResults && (
                        <div className="result-illustration-container">
                            {testQuestion.question.correct_answer.includes(
                                selectedAnswer
                            ) ? (
                                <ReactSVG
                                    wrapper="span"
                                    className="correct-result-illustration"
                                    src="/correct-symbol.svg"
                                />
                            ) : (
                                <ReactSVG
                                    wrapper="span"
                                    className="incorrect-result-illustration"
                                    src="/incorrect-symbol.svg"
                                />
                            )}
                        </div>
                    )}
                    <div className="question noselect">
                        {testQuestion.question.question}
                    </div>
                </div>
                <div className="answers noselect">{this.buildAnswers}</div>
                {revealQuestionResults && (
                    <ViewAnswerButton
                        dispatch={dispatch}
                        auth={auth}
                        correctAnswer={testQuestion.question.correct_answer}
                        solution={testQuestion.question.solution[0] || null}
                    />
                )}
            </Container>
        )
    }

    private get buildAnswers() {
        const { testQuestion, onAnswerSelection, selectedAnswer } = this.props
        return testQuestion.question.answers.map((a) => (
            <div className="answer" key={a.id}>
                <Radio
                    checked={selectedAnswer === a.letter}
                    onChange={() =>
                        onAnswerSelection(testQuestion.id, a.letter)
                    }
                />{' '}
                <span className="answer-text">{a.answer}</span>
            </div>
        ))
    }
}
