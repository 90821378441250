import * as lodash from 'lodash'
import * as React from 'react'
import { Container } from 'semantic-ui-react'
import { ThunkDispatch } from '../../actions/util'
import { Course } from '../../models'
import ContentSlider from '../ContentSlider'

import './FeaturedCourses.scss'

interface StateShape {}
interface PropsShape {
    dispatch: ThunkDispatch
    highlightedCourses: Course[]
    setCourseFilterAndGoToBrowse: (courseName: string) => void
}

export default class FeaturedCourses extends React.Component<
    PropsShape,
    StateShape
> {
    render() {
        const { highlightedCourses } = this.props
        return (
            <Container className="FeaturedCourses">
                <h2 className="featured-courses-title">Explore the Courses</h2>
                <h3 className="featured-courses-description">
                    All the questions at your fingertips. Search by year, test
                    type, subtopics, and more!
                </h3>

                <div className="courses-row">
                    <ContentSlider
                        itemWidth={300}
                        numerOfItems={highlightedCourses.length}
                    >
                        {lodash.map(highlightedCourses, (course) =>
                            this.buildCourseCard(course)
                        )}
                    </ContentSlider>
                </div>
            </Container>
        )
    }

    private buildCourseCard = (course: Course) => {
        const { setCourseFilterAndGoToBrowse } = this.props
        return (
            <div className="course-card" key={course.data.id}>
                <span className="course-code">{course.data.course_code}</span>
                <span className="course-name">{course.data.name}</span>
                <div
                    className="course-questions-link"
                    onClick={() =>
                        setCourseFilterAndGoToBrowse(course.data.course_code)
                    }
                >{`${course.data.number_of_questions} Questions`}</div>
                <span className="university-name">
                    {course.data.university_name}
                </span>
            </div>
        )
    }
}
