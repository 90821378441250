import { combineReducers } from 'redux'
import { StateShape as RemoteStateShape, remoteReducer } from './remote'
import { StateShape as AppStateShape, appReducer } from './app'
import { routerReducer, RouterState } from 'react-router-redux'
import { authReducer, StateShape as AuthStateShape } from './auth'

export interface StateShape {
    remote: RemoteStateShape
    router: RouterState
    app: AppStateShape
    auth: AuthStateShape
}

export const rootReducer = combineReducers({
    app: appReducer,
    remote: remoteReducer,
    router: routerReducer,
    auth: authReducer,
})