import * as appActions from '../actions/app'
import * as icepick from 'icepick'

export enum Modal {
    Signup = 'Signup',
    Login = 'Login',
    PasswordReset = 'PasswordReset',
    TestGenerator = "TestGenerator",
    UpgradeModal = "UpgradeModal"
}

export enum TestType {
    TT1 = 'TT1',
    TT2 = 'TT2',
    TT3 = 'TT3',
    Midterm = 'Midterm',
    Final = 'Final'
}

export interface QuestionFilters {
    hasAnswer: boolean | null
    testType: TestType[]
    subtopic: string | null
    year: [number | null, number | null]
    university: string
    course: string
}

export interface StateShape {
    questionFilters: QuestionFilters
    testPackFilters: QuestionFilters;
    modal: Modal | null
    isDropdownMenuExpanded: boolean
}

const defaultState = {
    questionFilters: {
        hasAnswer: null,
        testType: [],
        subtopic: null,
        year: [null, null] as [number | null, number | null],
        university: '',
        course: ''
    },
    testPackFilters: {
        hasAnswer: null,
        testType: [],
        subtopic: null,
        year: [null, null] as [number | null, number | null],
        university: '',
        course: ''
    },
    modal: null,
    isDropdownMenuExpanded: false
}

export function appReducer(
    state: StateShape = defaultState,
    action: appActions.AppAction
) {
    switch (action.type) {
        case appActions.UPDATE_QUESTION_FILTERS:
            return handleUpdateQuestionFilters(state, action.payload)
        case appActions.UPDATE_TEST_PACK_FILTERS:
            return handleUpdateTestPackFilters(state, action.payload)
        case appActions.CLOSE_MODAL:
            return handleCloseModal(state)
        case appActions.DISPLAY_SIGNUP:
            return handleDisplaySignup(state)
        case appActions.DISPLAY_LOGIN:
            return handleDisplayLogin(state)
        case appActions.DISPLAY_UPGRADE:
            return handleDisplayUpgrade(state)
        case appActions.DISPLAY_TEST_GENERATOR:
            return handleDisplayTestGenerator(state)
        case appActions.REVEAL_DROPDOWN_MENU:
            return handleRevealDropdown(state)
        case appActions.HIDE_DROPDOWN_MENU:
            return handleHideDropdown(state)
        case appActions.DISPLAY_PASSWORD_RESET:
            return handleDisplayPasswordReset(state)
    }

    return state
}

function handleUpdateQuestionFilters(
    state: StateShape,
    filters: Partial<QuestionFilters>
) {
    return {
        ...state,
        questionFilters: { ...state.questionFilters, ...filters }
    }
}

function handleUpdateTestPackFilters(
    state: StateShape,
    filters: Partial<QuestionFilters>
) {
    return {
        ...state,
        testPackFilters: { ...state.testPackFilters, ...filters }
    }
}

function handleCloseModal(state: StateShape) {
    return icepick.merge(state, { modal: null })
}

function handleDisplaySignup(state: StateShape) {
    return icepick.merge(state, { modal: Modal.Signup })
}

function handleDisplayTestGenerator(state: StateShape) {
    return icepick.merge(state, { modal: Modal.TestGenerator })
}

function handleDisplayLogin(state: StateShape) {
    return icepick.merge(state, { modal: Modal.Login })
}

function handleDisplayUpgrade(state: StateShape) {
    return icepick.merge(state, { modal: Modal.UpgradeModal })
}

function handleRevealDropdown(state: StateShape) {
    return icepick.merge(state, { isDropdownMenuExpanded: true })
}

function handleHideDropdown(state: StateShape) {
    return icepick.merge(state, { isDropdownMenuExpanded: false })
}

function handleDisplayPasswordReset(state: StateShape) {
    return icepick.merge(state, { modal: Modal.PasswordReset })
}
