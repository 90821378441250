import * as React from 'react'
import * as repr from '../rest/representers'
import { StateShape as ReduxStateShape } from '../reducers'
import { push as pushRoute } from 'react-router-redux'
import { ThunkDispatch } from '../actions/util'
import { displaySignup, displayLogin } from '../actions/app'
import { Menu, Button, MenuItemProps } from 'semantic-ui-react'
import { withRouter, match as MatchShape } from 'react-router'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { deleteAuth } from '../actions/auth'
import * as H from 'history'
import './DropdownMenu.scss'

interface StateShape {}
interface PropsShape {
    dispatch: ThunkDispatch
    onMenuItemClick: () => void
    match: MatchShape<null>
    history: H.History
    location: H.Location
    auth: repr.Auth.Base | null
}

class DropdownMenu extends React.Component<PropsShape, StateShape> {
    render() {
        const { auth, dispatch, onMenuItemClick } = this.props
        return (
            <div className="DropdownMenu">
                <Menu className="options-container" vertical={true}>
                    <this.MenuItem onClick={() => dispatch(pushRoute('/'))}>
                        Home
                    </this.MenuItem>
                    <this.MenuItem className="menu-options">
                        <Menu.Menu className="dropdown-nav">
                            <this.MenuItem onClick={onMenuItemClick}>
                                <Link to="/browse">Browse</Link>
                            </this.MenuItem>
                            <this.MenuItem onClick={onMenuItemClick}>
                                <Link to="/test-yourself">Test Yourself</Link>
                            </this.MenuItem>
                            <this.MenuItem onClick={onMenuItemClick}>
                                <Link to="/contribute">Contribute</Link>
                            </this.MenuItem>
                            <this.MenuItem onClick={onMenuItemClick}>
                                <Link to="/upgrade">Upgrade</Link>
                            </this.MenuItem>
                            {auth && (
                                <this.MenuItem onClick={onMenuItemClick}>
                                    <Link to="/account">Account Settings</Link>
                                </this.MenuItem>
                            )}
                        </Menu.Menu>
                    </this.MenuItem>
                    <this.MenuItem className="dropdown-signup-or-login">
                        {auth
                            ? this.logoutOption
                            : this.signupOrLoginButtonGroup}
                    </this.MenuItem>
                </Menu>
            </div>
        )
    }

    MenuItem = (props: MenuItemProps) => {
        let onClick = this.props.onMenuItemClick
        if (props.onClick) {
            onClick = compose(
                onClick,
                props.onClick
            ) as () => void
        }
        return <Menu.Item {...props} onClick={onClick} as="div" />
    }

    handleLogoutClick = () => {
        const { dispatch, onMenuItemClick } = this.props
        dispatch(deleteAuth()).then(onMenuItemClick)
    }

    get signupOrLoginButtonGroup() {
        const { dispatch } = this.props
        return (
            <Menu.Menu>
                <Button
                    className="dropdown-login"
                    onClick={() => dispatch(displayLogin())}
                >
                    Login
                </Button>
                or
                <Button
                    className="dropdown-signup"
                    onClick={() => dispatch(displaySignup())}
                >
                    Signup
                </Button>
            </Menu.Menu>
        )
    }

    get logoutOption() {
        return (
            <Menu.Item className="logout" onClick={this.handleLogoutClick}>
                Log out
            </Menu.Item>
        )
    }
}

const mapStateToProps = (state: ReduxStateShape) => ({
    auth: state.auth
})

export default withRouter(connect(mapStateToProps)(DropdownMenu))
