import * as React from 'react'
import * as repr from '../rest/representers'
import { StateShape as ReduxStateShape } from '../reducers'
import {
    revealDropdownMenu,
    hideDropdownMenu,
    updateTestPackFilters,
} from '../actions/app'
import { connect } from 'react-redux'
import { ThunkDispatch } from '../actions/util'
import { CSSTransition } from 'react-transition-group'
import TopBar from './TopBar'
import DropdownMenu from './DropdownMenu'
import HomePageHeader from '../components/HomePage/HomePageHeader'
import ValueProp from '../components/HomePage/ValueProp'
import FeaturedCourses from '../components/HomePage/FeaturedCourses'
import Footer from './Footer'
import { Course, CourseFactory } from '../models'
import { fetchCourse } from '../actions/courses'
import { replace as replaceRoute } from 'react-router-redux'

import './HomePage.scss'

interface StateShape {}
interface PropsShape {
    dispatch: ThunkDispatch
    isDropdownMenuExpanded: boolean
    auth: repr.Auth.Base | null
    highlightedCourses: Course[]
}

const PSL301 = 'PSL301(University of Toronto - UTSG)'
const PSY100 = 'PSY100(University of Toronto - UTSG)'
const BCH210 = 'BCH210(University of Toronto - UTSG)'
const PSL300 = 'PSL300(University of Toronto - UTSG)'
const BIO120 = 'BIO120(University of Toronto - UTSG)'
const PHY131 = 'PHY131(University of Toronto - UTSG)'
const HMB265 = 'HMB265(University of Toronto - UTSG)'
const BIO230 = 'BIO230(University of Toronto - UTSG)'
const BIO130 = 'BIO130(University of Toronto - UTSG)'
const ECO100 = 'ECO100(University of Toronto - UTSG)'
const RSM100 = 'RSM100(University of Toronto - UTSG)'
const SOC101 = 'SOC101(University of Toronto - UTSG)'
const IMM250 = 'IMM250(University of Toronto - UTSG)'
const RSM219 = 'RSM219(University of Toronto - UTSG)'
const RSM230 = 'RSM230(University of Toronto - UTSG)'
const RSM250 = 'RSM250(University of Toronto - UTSG)'
const BIO220 = 'BIO220(University of Toronto - UTSG)'
const ENV200 = 'ENV200(University of Toronto - UTSG)'
const AST201 = 'AST201(University of Toronto - UTSG)'

export const featuredCourses = [
    PSL301,
    PSY100,
    BCH210,
    PSL300,
    RSM230,
    BIO120,
    HMB265,
    BIO230,
    BIO130,
    IMM250,
    ECO100,
    RSM100,
    SOC101,
    PHY131,
    RSM219,
    RSM250,
    BIO220,
    ENV200,
    AST201
]

class HomePage extends React.Component<PropsShape, StateShape> {
    componentDidMount() {
        const { dispatch } = this.props

        for (let course of featuredCourses) {
            dispatch(fetchCourse(course))
        }
    }

    render() {
        const { dispatch, isDropdownMenuExpanded, highlightedCourses } =
            this.props
        return (
            <div className="HomePage">
                <TopBar withSearch={true} logo="main" />
                <CSSTransition
                    classNames="appear"
                    timeout={500}
                    in={isDropdownMenuExpanded}
                    unmountOnExit={true}
                >
                    <DropdownMenu onMenuItemClick={this.toggleDropdownMenu} />
                </CSSTransition>
                <HomePageHeader dispatch={dispatch} />

                <FeaturedCourses
                    highlightedCourses={highlightedCourses}
                    dispatch={dispatch}
                    setCourseFilterAndGoToBrowse={
                        this.setCourseFilterAndGoToBrowse
                    }
                />
                <ValueProp />
                <Footer />
            </div>
        )
    }

    private toggleDropdownMenu = () => {
        const { isDropdownMenuExpanded, dispatch } = this.props
        if (isDropdownMenuExpanded) {
            dispatch(hideDropdownMenu())
        } else {
            dispatch(revealDropdownMenu())
        }
    }

    private setCourseFilterAndGoToBrowse = (courseName: string) => {
        const { dispatch } = this.props
        dispatch(updateTestPackFilters({ course: courseName, testType: [] }))
        dispatch(replaceRoute('/browse'))
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const auth = state.auth
    const isDropdownMenuExpanded = state.app.isDropdownMenuExpanded

    const highlightedCourses: Course[] = CourseFactory.fromState(
        state,
        featuredCourses
    )

    return {
        auth,
        isDropdownMenuExpanded,
        highlightedCourses,
    }
}

export default connect(mapStateToProps)(HomePage)
