import * as React from 'react'
import * as repr from '../rest/representers'
import { ThunkDispatch } from '../actions/util'
import { QuestionDetailed, QuestionBare, QuestionFactory } from '../models'
import { connect } from 'react-redux'
import { StateShape as ReduxStateShape } from '../reducers'
import { withRouter } from 'react-router'
import { fetchFilteredQuestions } from '../actions/questions'
import { Container, Loader } from 'semantic-ui-react'
import TopBar from './TopBar'
import {
    revealDropdownMenu,
    hideDropdownMenu,
    updateFilters,
} from '../actions/app'
import { CSSTransition } from 'react-transition-group'
import DropdownMenu from './DropdownMenu'
import Footer from './Footer'
import Helmet from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import { match as MatchShape } from 'react-router'
import { ReactSVG } from 'react-svg'
import { QuestionFilters, TestType } from '../reducers/app'
import { PaginationInfo } from '../reducers/remote/pagination'
import QuestionList from '../components/QuestionList'
import { Pagination } from '../rest/api'
import 'react-toastify/dist/ReactToastify.css'
import './TestPackPage.scss'

interface StateShape {
    showFlagQuestionModal: boolean
    isLoadingQuestions: boolean
}

interface InjectedProps {
    auth: repr.Auth.Base | null
    dispatch: ThunkDispatch
    paginationInfo: PaginationInfo | undefined
    questions: (QuestionDetailed | QuestionBare)[]
    isDropdownMenuExpanded: boolean
}

interface PassedProps {
    match: MatchShape<{ university: string; course: string; testType: string }>
}

type PropsShape = InjectedProps & PassedProps

class TestPackPage extends React.PureComponent<PropsShape, StateShape> {
    state: StateShape = {
        showFlagQuestionModal: false,
        isLoadingQuestions: true,
    }

    componentDidMount() {
        const { match } = this.props
        const testType = match.params.testType

        if (
            testType !== TestType.Final &&
            testType !== TestType.Midterm &&
            testType !== TestType.TT1 &&
            testType !== TestType.TT2 &&
            testType !== TestType.TT3
        ) {
            return
        }

        this.updateFiltersAndFetchQuestions({
            university: match.params.university.replaceAll('-', ' '),
            course: match.params.course,
            testType: [match.params.testType as TestType],
        })
    }

    componentDidUpdate(prevProps: PropsShape) {
        const { match, auth } = this.props
        const testType = match.params.testType

        if (prevProps.auth !== auth) {
            if (
                testType !== TestType.Final &&
                testType !== TestType.Midterm &&
                testType !== TestType.TT1 &&
                testType !== TestType.TT2 &&
                testType !== TestType.TT3
            ) {
                return
            }

            this.updateFiltersAndFetchQuestions({
                university: match.params.university.replaceAll('-', ' '),
                course: match.params.course,
                testType: [match.params.testType as TestType],
            })
        }
    }

    render() {
        const { isLoadingQuestions } = this.state
        const { questions, isDropdownMenuExpanded } = this.props

        if (!questions.length && !isLoadingQuestions) {
            return (
                <Container className="TestPackPage">
                    <TopBar withSearch={true} logo="main" />

                    <CSSTransition
                        classNames="appear"
                        timeout={300}
                        in={isDropdownMenuExpanded}
                        unmountOnExit={true}
                    >
                        <DropdownMenu
                            onMenuItemClick={this.toggleDropdownMenu}
                        />
                    </CSSTransition>

                    <div className="question-not-found">
                        <ReactSVG
                            wrapper="div"
                            src="/empty.svg"
                            className="not-found-icon"
                        />
                        Looks like this test pack is no longer available! Don't
                        worry, you'll find similar questions by clicking the
                        link below.
                        <a href="/browse" className="browse-link">
                            Continue to Browse
                        </a>
                    </div>

                    <Footer />
                </Container>
            )
        } else if (isLoadingQuestions) {
            return (
                <Container className="TestPackPage">
                    <TopBar withSearch={true} logo="main" />

                    <CSSTransition
                        classNames="appear"
                        timeout={300}
                        in={isDropdownMenuExpanded}
                        unmountOnExit={true}
                    >
                        <DropdownMenu
                            onMenuItemClick={this.toggleDropdownMenu}
                        />
                    </CSSTransition>

                    <div className="loader-container">
                        <Loader active inline="centered" />
                    </div>
                    <Footer />
                </Container>
            )
        }

        const question = questions[0]

        return (
            <Container className="TestPackPage">
                <Helmet>
                    <title>
                        {`${question.data.test_type} Test Pack - ${question.data.course.course_code} - ${question.data.course.university_name}`}
                    </title>
                    <meta
                        name="description"
                        content={`${question.data.test_type} test pack for ${question.data.course.course_code}`}
                    />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>

                <TopBar withSearch={true} logo="main" />

                <CSSTransition
                    classNames="appear"
                    timeout={300}
                    in={isDropdownMenuExpanded}
                    unmountOnExit={true}
                >
                    <DropdownMenu onMenuItemClick={this.toggleDropdownMenu} />
                </CSSTransition>

                <Container className="InfoBar">
                    <h1 className="test-pack-title">
                        {question.data.course.course_code} -{' '}
                        {question.data.test_type} Test Pack
                    </h1>

                    <Container className="info-segment">
                        <h3 className="info-header">University</h3>
                        <div>{question.data.course.university_name}</div>
                    </Container>

                    <Container className="info-segment">
                        <h3 className="info-header">Course</h3>
                        <div>
                            {question.data.course.course_code} -{' '}
                            {question.data.course.name}
                        </div>
                    </Container>

                    <Container className="info-segment">
                        <h3 className="info-header">Test Type</h3>
                        <div>{question.data.test_type}</div>
                    </Container>
                </Container>

                <div className="QuestionsBar">
                    <div className="content-container">
                        {this.questionList()}
                    </div>
                </div>
                <ToastContainer autoClose={5000} />
            </Container>
        )
    }

    private questionList() {
        const { questions, paginationInfo, auth, dispatch } = this.props
        return (
            <QuestionList
                auth={auth}
                dispatch={dispatch}
                questions={questions}
                paginationInfo={paginationInfo}
                fetchPage={this.fetchPageOfQuestions}
            />
        )
    }

    private fetchPageOfQuestions = (p: Pagination) =>
        this.props.dispatch(fetchFilteredQuestions(p))

    private updateFiltersAndFetchQuestions = (
        filters: Partial<QuestionFilters>
    ) => {
        const { dispatch } = this.props
        dispatch(updateFilters(filters))
        dispatch(fetchFilteredQuestions()).finally(() =>
            this.setState({ isLoadingQuestions: false })
        )
    }

    private toggleDropdownMenu = () => {
        const { isDropdownMenuExpanded, dispatch } = this.props
        if (isDropdownMenuExpanded) {
            dispatch(hideDropdownMenu())
        } else {
            dispatch(revealDropdownMenu())
        }
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const { questionIdsForDisplay } = state.remote.sets

    const isDropdownMenuExpanded = state.app.isDropdownMenuExpanded
    const auth = state.auth
    const questions = QuestionFactory.fromState(state, questionIdsForDisplay)
    const paginationInfo = state.remote.pagination.properties

    return {
        auth,
        questions,
        isDropdownMenuExpanded,
        paginationInfo,
    }
}

export default withRouter(connect(mapStateToProps)(TestPackPage))
