import { denormalize, schema } from 'normalizr'
import { StateShape } from './reducers'
import * as lodash from 'lodash'
import * as repr from './rest/representers'
import {
    QuestionSchema,
    CourseSchema,
    UniversitySchema,
    UserSchema,
    AuthSchema,
    TestSchema,
} from './schemas'

const EMPTY_ARRAY: Array<{}> = []

export class Model<T> {
    data: T

    constructor(data: T) {
        this.data = data
    }
}

export class ModelFactory<S, T extends Model<S>> {
    schemaForEntity: schema.Entity
    schemaForArray: schema.Array
    modelInstanceFromData: (data: S) => T | undefined

    cacheDependencies: ((state: StateShape) => {})[] | undefined
    cache: { [key: string]: T | T[] | undefined } = {}
    cacheResults: {}[] = []

    constructor(
        s: schema.Entity,
        modelInstanceFromData: (data: S) => T | undefined,
        cacheDependencies?: ((state: StateShape) => {})[]
    ) {
        this.schemaForEntity = s
        this.schemaForArray = new schema.Array(s)
        this.modelInstanceFromData = modelInstanceFromData
        this.cacheDependencies = cacheDependencies
    }

    fromState(state: StateShape, idOrIds: string): T | undefined
    fromState(state: StateShape, idOrIds: Array<string>): T[]
    fromState(state: StateShape): T[]
    fromState(state: StateShape, idOrIds?: string | string[]) {
        if (!idOrIds) {
            idOrIds = Object.keys(
                state.remote.entities[
                    this.schemaForEntity.key as
                        | 'questions'
                        | 'courses'
                        | 'universities'
                        | 'users'
                        | 'auth'
                        | 'tests'
                ]
            )
        }

        if (this.cacheDependencies) {
            const fromCache = this.checkCache(state, idOrIds)
            if (fromCache) {
                return fromCache
            }
        }

        let entityOrEntities
        if (idOrIds instanceof Array) {
            entityOrEntities = this.fromStateMany(state, idOrIds)
        } else {
            entityOrEntities = this.fromStateOne(state, idOrIds)
        }

        this.addToCache(this.toCacheKey(idOrIds), entityOrEntities)

        return entityOrEntities
    }

    protected toCacheKey(input: string | string[] | undefined) {
        return JSON.stringify(input)
    }

    protected isCacheValid(state: StateShape) {
        const nextResults = lodash.map(this.cacheDependencies!, (f) => f(state))
        const zipped = lodash.zip(nextResults, this.cacheResults)
        const isUnchanged = lodash.every(zipped, ([r1, r2]) => r1 === r2)
        return isUnchanged
    }

    protected checkCache(state: StateShape, idOrIds?: string | string[]) {
        if (!this.isCacheValid(state)) {
            this.cache = {}
            this.cacheResults = lodash.map(this.cacheDependencies, (f) =>
                f(state)
            )
        }
        const key = this.toCacheKey(idOrIds)
        return this.cache[key]
    }

    protected addToCache(key: string, entityToAdd: T | T[] | undefined) {
        this.cache[key] = entityToAdd
    }

    protected fromStateMany(state: StateShape, ids: string[]): T[] {
        const denormalized = denormalize(
            ids,
            this.schemaForArray,
            state.remote.entities
        ) as (S | undefined)[]

        const filtered = lodash.compact(denormalized)

        if (filtered.length === 0) {
            return EMPTY_ARRAY as T[]
        }
        const mapped = lodash.map(filtered, (d) =>
            this.modelInstanceFromData(d)
        )
        return lodash.filter(mapped) as T[]
    }

    protected fromStateOne(state: StateShape, id: string): T | undefined {
        const denormalized = denormalize(
            id,
            this.schemaForEntity,
            state.remote.entities
        ) as S | undefined

        return denormalized && this.modelInstanceFromData(denormalized)
    }
}

const questionSelector = (state: StateShape) => state.remote.entities.questions
const testSelector = (state: StateShape) => state.remote.entities.tests
const courseSelector = (state: StateShape) => state.remote.entities.courses
const universitySelector = (state: StateShape) =>
    state.remote.entities.universities
const userSelector = (state: StateShape) => state.remote.entities.users
const authSelector = (state: StateShape) => state.remote.entities.auth

export class QuestionBase<T extends repr.Question.Base> extends Model<T> {}

export class QuestionDetailed extends QuestionBase<repr.Question.Detailed> {}
export class QuestionBare extends QuestionBase<repr.Question.Bare> {}

export const QuestionFactory = new ModelFactory(
    QuestionSchema,
    (d: repr.Question.Bare | repr.Question.Detailed) => {
        if (d.type === 'question_bare') {
            return new QuestionBare(d)
        } else if (d.type === 'question') {
            return new QuestionDetailed(d)
        } else {
            return
        }
    },
    [questionSelector]
)

export const QuestionBareFactory = new ModelFactory(
    QuestionSchema,
    (b: repr.Question.Bare) => new QuestionBare(b),
    [questionSelector]
)
export const QuestionDetailedFactory = new ModelFactory(
    QuestionSchema,
    (q: repr.Question.Bare | repr.Question.Detailed) => {
        if (q.type === 'question_bare') {
            return
        }
        const question = new QuestionDetailed(q)
        return question
    },
    [questionSelector]
)

export class Test extends Model<repr.Test.Base> {}
export const TestFactory = new ModelFactory(
    TestSchema,
    (t: repr.Test.Base) => new Test(t),
    [testSelector]
)

export class Course extends Model<repr.Course.Base> {}
export const CourseFactory = new ModelFactory(
    CourseSchema,
    (c: repr.Course.Base) => new Course(c),
    [courseSelector]
)

export class University extends Model<repr.University.Base> {}
export const UniversityFactory = new ModelFactory(
    UniversitySchema,
    (u: repr.University.Base) => new University(u),
    [universitySelector]
)

export class User extends Model<repr.User.Base> {}
export const UserFactory = new ModelFactory(
    UserSchema,
    (d: repr.User.Base) => new User(d),
    [userSelector]
)

export class Auth extends Model<repr.Auth.Base> {}
export const AuthFactory = new ModelFactory(
    AuthSchema,
    (d: repr.Auth.Base) => new Auth(d),
    [authSelector]
)
