import * as React from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from '../actions/util'
import { StateShape as ReduxStateShape } from '../reducers'
import { Container, Input, Header, Button } from 'semantic-ui-react'
import { closeModal, displayLogin } from '../actions/app'
import { createPasswordResetToken } from '../actions/passwordResetTokens'
import { replace as replaceRoute } from 'react-router-redux'
import './PasswordReset.scss'

const closeIcon = ''

interface StateShape {
    email: string
    errorMessage: string | null
}

interface PropsShape {
    dispatch: ThunkDispatch
    loginAsRedirect?: boolean
    onClose?: () => void
}

class PasswordReset extends React.Component<PropsShape, StateShape> {
    state = {
        email: '',
        errorMessage: null,
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleEnterKeyPress, false)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEnterKeyPress, false)
    }

    render() {
        const { errorMessage } = this.state
        return (
            <div className="PasswordReset">
                <span
                    className="close-icon"
                    dangerouslySetInnerHTML={{ __html: closeIcon }}
                    onClick={this.closeModal}
                />
                <div className="content">
                    <Header.Subheader as="h2" className="modal-header">
                        Password Reset
                    </Header.Subheader>
                    <Container className="reset-message" text={true}>
                        Send password reset instructions
                    </Container>
                    <Input
                        onChange={this.handleEmailChange}
                        placeholder="Email Address"
                    />
                    <Button
                        className="reset-password-button"
                        onClick={this.sendPasswordReset}
                    >
                        Send
                    </Button>
                    <Container className="switch-message" text={true}>
                        Have an account?{' '}
                        <span className="login" onClick={this.showLoginModal}>
                            Login
                        </span>
                    </Container>
                    {errorMessage && (
                        <p className="error-message"> {errorMessage} </p>
                    )}
                </div>
            </div>
        )
    }

    sendPasswordReset = () => {
        const { dispatch } = this.props
        const { email } = this.state
        const lowerCaseEmail = email.toLowerCase()
        dispatch(createPasswordResetToken(lowerCaseEmail))
            .then(() => {
                this.closeModal()
            })
            .catch((e) => this.setState({ errorMessage: e.message }))
    }

    handleEmailChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({ email: e.currentTarget.value })
    }

    closeModal = () => {
        const { dispatch, onClose } = this.props
        if (onClose) {
            onClose()
        }

        dispatch(closeModal())
    }

    showLoginModal = () => {
        const { loginAsRedirect, dispatch } = this.props
        if (loginAsRedirect) {
            dispatch(replaceRoute('/login'))
        } else {
            this.props.dispatch(displayLogin())
        }
    }

    handleEnterKeyPress = (evt: KeyboardEvent) => {
        if (evt.keyCode === 13) {
            this.sendPasswordReset()
        }
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    return {}
}

export default connect(mapStateToProps)(PasswordReset)
