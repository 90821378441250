import * as React from 'react'
import { Container } from 'semantic-ui-react'

import './QuestionAnalytics.scss'

interface StateShape {}
interface PropsShape {}

export default class QuestionAnalytics extends React.Component<
    PropsShape,
    StateShape
> {
    render() {
        return (
            <Container className="QuestionAnalytics">
                <h3 className="header">Question Analytics (Coming Soon)</h3>
                <div className="coming-soon-container">
                    <img
                        className="illustration"
                        src='/not-found.svg'
                        alt="Not found icon"
                    />
                </div>
            </Container>
        )
    }
}
