import * as repr from '../rest/representers'
import * as api from '../rest/api'
import Resource from '../rest/Resource'
import { makeRequest, RestSideEffect } from './util'
import { Action, ActionWithError, ActionWithPayload } from './Action'

export const CREATE_CONTRIBUTION_DOCUMENT = 'CREATE_CONTRIBUTION_DOCUMENT'
export type CREATE_CONTRIBUTION_DOCUMENT = typeof CREATE_CONTRIBUTION_DOCUMENT

export const RECEIVE_CONTRIBUTION_DOCUMENT = 'RECEIVE_CONTRIBUTION_DOCUMENT'
export type RECEIVE_CONTRIBUTION_DOCUMENT = typeof RECEIVE_CONTRIBUTION_DOCUMENT

export const CREATE_CONTRIBUTION_DOCUMENT_FAILURE =
    'CREATE_CONTRIBUTION_DOCUMENT_FAILURE'
export type CREATE_CONTRIBUTION_DOCUMENT_FAILURE = typeof CREATE_CONTRIBUTION_DOCUMENT_FAILURE

export type RequestContributionDocumentActionType = CREATE_CONTRIBUTION_DOCUMENT
export type ReceiveContributionDocumentActionType = RECEIVE_CONTRIBUTION_DOCUMENT
export type RequestContributionDocumentFailedActionType = CREATE_CONTRIBUTION_DOCUMENT_FAILURE

export type CreateContributionDocumentAction = Action<
    CREATE_CONTRIBUTION_DOCUMENT
>
export type ReceiveContributionDocumentAction = ActionWithPayload<
    RECEIVE_CONTRIBUTION_DOCUMENT,
    Resource<repr.ContributionDocument.Base>
>
export type CreateContributionDocumentActionFailure = ActionWithError<
    CREATE_CONTRIBUTION_DOCUMENT_FAILURE,
    Error
>

export type ContributionDocumentAction =
    | CreateContributionDocumentAction
    | ReceiveContributionDocumentAction
    | CreateContributionDocumentActionFailure

export function createContributionDocument(
    contribution_id: string,
    document: { [x: string]: { [k: string]: File } }
): RestSideEffect<repr.ContributionDocument.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: CREATE_CONTRIBUTION_DOCUMENT },
                { action: RECEIVE_CONTRIBUTION_DOCUMENT },
                { action: CREATE_CONTRIBUTION_DOCUMENT_FAILURE },
                ({ authToken }) =>
                    api.postNewContributionDocument(
                        authToken,
                        contribution_id,
                        document
                    )
            )
        )
    }
}
