import * as React from 'react'
import * as repr from '../rest/representers'
import { Container, Tab } from 'semantic-ui-react'
import { StateShape as ReduxStateShape } from '../reducers'
import { revealDropdownMenu, hideDropdownMenu } from '../actions/app'
import { CSSTransition } from 'react-transition-group'
import DropdownMenu from './DropdownMenu'
import { ThunkDispatch } from '../actions/util'
import { connect } from 'react-redux'
import TopBar from './TopBar'
import { replace as replaceRoute } from 'react-router-redux'
import Settings from '../components/AccountPage/Settings'
import Billing from '../components/AccountPage/Billing'
// import Rewards from '../components/AccountPage/Rewards'
import { updateUser } from '../actions/users'

import './AccountPage.scss'

export interface AccountInfo {
    firstName: string
    lastName: string
    email: string
    newPassword: string
    newPasswordConfirm: string
    errorMessage: string
    updateSuccessful: boolean
}

export interface AccountInfo {
    [x: string]: string | boolean
}

interface PropsShape {
    auth: repr.Auth.Base | null
    dispatch: ThunkDispatch
    isDropdownMenuExpanded: boolean
}

type StateShape = AccountInfo

class AccountPage extends React.Component<PropsShape, StateShape> {
    constructor(props: PropsShape) {
        super(props)
        this.state = {
            firstName: this.props.auth ? this.props.auth.user.first_name : '',
            lastName: this.props.auth ? this.props.auth.user.last_name : '',
            email: this.props.auth ? this.props.auth.user.email : '',
            newPassword: '',
            newPasswordConfirm: '',
            errorMessage: '',
            updateSuccessful: false
        }
    }

    componentDidMount() {
        const { dispatch, auth } = this.props
        if (!auth) {
            dispatch(replaceRoute('/'))
        }
    }

    componentDidUpdate(prevProps: PropsShape) {
        const { dispatch, auth } = this.props
        if (prevProps.auth !== auth && !auth) {
            dispatch(replaceRoute('/'))
        }
    }

    render() {
        const { isDropdownMenuExpanded, dispatch, auth } = this.props
        const { errorMessage, updateSuccessful } = this.state
        const panes = [
            {
                menuItem: 'Account',
                render: () => (
                    <Tab.Pane attached={false}>
                        <Settings
                            accountInfo={this.state}
                            onSave={this.saveInfo}
                            updateAccountSettings={this.updateAccountSettings}
                            updateSuccessful={updateSuccessful}
                            errorMessage={errorMessage}
                        />
                    </Tab.Pane>
                )
            },
            {
                menuItem: 'Billing',
                render: () => (
                    <Tab.Pane attached={false}>
                        <Billing dispatch={dispatch} auth={auth} />
                    </Tab.Pane>
                )
            },
            // {
            //     menuItem: 'Rewards',
            //     render: () => (
            //         <Tab.Pane attached={false}>
            //             <Rewards
            //                 numberOfReferrals={
            //                     auth && auth.user.number_of_referrals
            //                 }
            //                 numberOfContributions={
            //                     auth && auth.user.number_of_contributions
            //                 }
            //             />
            //         </Tab.Pane>
            //     )
            // }
        ]
        return (
            <Container className="AccountPage">
                <TopBar withSearch={true} logo="main" />
                <CSSTransition
                    classNames="appear"
                    timeout={500}
                    in={isDropdownMenuExpanded}
                    unmountOnExit={true}
                >
                    <DropdownMenu onMenuItemClick={this.toggleDropdownMenu} />
                </CSSTransition>

                <div className="account-content">
                    <Tab
                        menu={{ secondary: true, pointing: true }}
                        panes={panes}
                    />
                </div>
            </Container>
        )
    }

    updateAccountSettings = (update: Partial<StateShape>) => {
        this.setState(update as StateShape)
    }

    saveInfo = () => {
        const { dispatch, auth } = this.props
        const {
            firstName,
            lastName,
            newPassword,
            newPasswordConfirm
        } = this.state
        this.setState({ updateSuccessful: false, errorMessage: '' })
        if (!auth || !this.validatePassword()) {
            return
        }
        const password =
            newPassword !== '' && newPassword === newPasswordConfirm
                ? newPassword
                : ''
        dispatch(updateUser(firstName, lastName, password))
            .then(() => this.setState({ updateSuccessful: true }))
            .catch(e => this.displayErrorMessage(e.message))
    }

    validatePassword = () => {
        const { newPassword, newPasswordConfirm } = this.state
        if (newPassword === newPasswordConfirm) {
            return true
        } else {
            this.setState({ errorMessage: 'Your passwords do not match.' })
            return false
        }
    }

    displayErrorMessage = (message: string) => {
        this.setState({ errorMessage: message })
    }

    toggleDropdownMenu = () => {
        const { isDropdownMenuExpanded, dispatch } = this.props
        if (isDropdownMenuExpanded) {
            dispatch(hideDropdownMenu())
        } else {
            dispatch(revealDropdownMenu())
        }
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const auth = state.auth
    const isDropdownMenuExpanded = state.app.isDropdownMenuExpanded

    return {
        auth,
        isDropdownMenuExpanded
    }
}

export default connect(mapStateToProps)(AccountPage)
