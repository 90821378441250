import * as React from 'react'
import * as lodash from 'lodash'
import { revealDropdownMenu, hideDropdownMenu } from '../actions/app'
import { CSSTransition } from 'react-transition-group'
import DropdownMenu from './DropdownMenu'
import { ThunkDispatch } from '../actions/util'
import { connect } from 'react-redux'
import { createContribution } from '../actions/contributions'
import { createContributionDocument } from '../actions/contributionDocuments'
import { Container, Dimmer, Loader } from 'semantic-ui-react'
import { StateShape as ReduxStateShape } from '../reducers'
import { displaySignup } from '../actions/app'
import { Auth, AuthFactory } from '../models'
import TopBar from './TopBar'
import DocumentInfoPicker from '../components/ContributionForm/DocumentInfoPicker'
import DocumentUpload from '../components/ContributionForm/DocumentUpload'
import SuccessPage from '../components/ContributionForm/SuccessPage'
import ProgressBar from '../components/ContributionForm/ProgressBar'

import './ContributionForm.scss'

export interface ContributionInfo {}

export interface DocumentInfo {
    universityName: string
    courseName: string
    description: string
    year: string
    documentPreview: { [k: string]: string }
    document: { [k: string]: File }
    errorMessage: string
    isSubmitting: boolean
    step: number
}

export interface DocumentInfo {
    [x: string]:
        | string
        | boolean
        | number
        | { [k: string]: string }
        | { [k: string]: File }
}

interface PropsShape {
    dispatch: ThunkDispatch
    auth: Auth | undefined | null
    isDropdownMenuExpanded: boolean
}

type StateShape = DocumentInfo

class ContributionForm extends React.Component<PropsShape, StateShape> {
    state: StateShape = {
        universityName: '',
        courseName: '',
        description: '',
        year: '',
        errorMessage: '',
        isSubmitting: false,
        step: 1,
        documentPreview: {},
        document: {}
    }

    componentDidMount() {
        const { auth, dispatch } = this.props
        if (!auth) {
            dispatch(displaySignup())
        }
    }

    render() {
        const { errorMessage, isSubmitting, step } = this.state
        const { isDropdownMenuExpanded } = this.props
        return (
            <div>
                {isSubmitting && (
                    <Dimmer active={true}>
                        <Loader />
                    </Dimmer>
                )}

                <Container className="ContributionForm">
                    <TopBar withSearch={true} logo="main" />
                    <CSSTransition
                        classNames="appear"
                        timeout={500}
                        in={isDropdownMenuExpanded}
                        unmountOnExit={true}
                    >
                        <DropdownMenu
                            onMenuItemClick={this.toggleDropdownMenu}
                        />
                    </CSSTransition>
                    <ProgressBar step={step} />
                    {step === 1 && (
                        <DocumentInfoPicker
                            documentInfo={this.state}
                            updateDocumentInfo={this.updateDocumentInfo}
                            nextStep={this.nextStep}
                        />
                    )}
                    {step === 2 && (
                        <DocumentUpload
                            submitForm={this.handleSubmitForm}
                            errorMessage={errorMessage}
                            documentInfo={this.state}
                            prevStep={this.prevStep}
                            updateDocumentInfo={this.updateDocumentInfo}
                        />
                    )}
                    {step === 3 && <SuccessPage />}
                </Container>
            </div>
        )
    }

    private updateDocumentInfo = (update: Partial<StateShape>) => {
        this.setState(update as StateShape)
    }

    private handleSubmitForm = () => {
        const { dispatch, auth } = this.props
        if (!auth) {
            dispatch(displaySignup())
        } else {
            this.setState({ isSubmitting: true })
            let finalState: DocumentInfo = lodash.omit(this.state, 'documentPreview') as DocumentInfo
            dispatch(createContribution(finalState))
                .then(c => this.uploadDocuments(c.data.id))
                .then(() => {
                    this.setState({ isSubmitting: false })
                    this.nextStep()
                })
                .catch(e => this.displayErrorMessage(e.message))
        }
    }

    uploadDocuments = (contributionId: string) => {
        const { document } = this.state
        const { dispatch } = this.props
        lodash.forEach(document, (v, k) => {
            dispatch(
                createContributionDocument(contributionId, {
                    file_upload: { [k]: v }
                })
            )
        })
    }

    displayErrorMessage = (message: string) => {
        this.setState({ isSubmitting: false, errorMessage: message })
    }

    prevStep = () => {
        const { step } = this.state
        this.setState({ step: step - 1 })
    }

    nextStep = () => {
        const { step } = this.state
        this.setState({ step: step + 1 })
    }

    private toggleDropdownMenu = () => {
        const { isDropdownMenuExpanded, dispatch } = this.props
        if (isDropdownMenuExpanded) {
            dispatch(hideDropdownMenu())
        } else {
            dispatch(revealDropdownMenu())
        }
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const isDropdownMenuExpanded = state.app.isDropdownMenuExpanded
    const auth = state.auth && AuthFactory.fromState(state, state.auth.token)
    return { auth, isDropdownMenuExpanded }
}

export default connect(mapStateToProps)(ContributionForm)
