import { makeRequest, RestSideEffect } from './util'
import * as api from '../rest/api'
import * as repr from '../rest/representers'
import { Action, ActionWithError, ActionWithPayload } from './Action'
import Resource from '../rest/Resource'

export const FETCH_COURSES = 'FETCH_COURSES'
export type FETCH_COURSES = typeof FETCH_COURSES

export const RECEIVE_COURSES = 'RECEIVE_COURSES'
export type RECEIVE_COURSES = typeof RECEIVE_COURSES

export const FETCH_COURSES_FAILURE = 'FETCH_COURSES_FAILURE'
export type FETCH_COURSES_FAILURE = typeof FETCH_COURSES_FAILURE

export const FETCH_COURSE = 'FETCH_COURSE'
export type FETCH_COURSE = typeof FETCH_COURSE

export const RECEIVE_COURSE = 'RECEIVE_COURSE'
export type RECEIVE_COURSE = typeof RECEIVE_COURSE

export const FETCH_COURSE_FAILURE = 'FETCH_COURSE_FAILURE'
export type FETCH_COURSE_FAILURE = typeof FETCH_COURSE_FAILURE

export type RequestCoursesActionType = FETCH_COURSES
export type ReceiveCoursesActionType = RECEIVE_COURSES
export type RequestCoursesFailedActionType = FETCH_COURSES_FAILURE

export type RequestCourseActionType = FETCH_COURSE
export type ReceiveCourseActionType = RECEIVE_COURSE
export type RequestCourseFailedActionType = FETCH_COURSE_FAILURE

export type RequestCoursesAction = Action<FETCH_COURSES>
export type ReceiveCoursesAction = ActionWithPayload<
    RECEIVE_COURSES,
    Resource<repr.Course.Base[]>
>
export type RequestCoursesFailedAction = ActionWithError<
    FETCH_COURSES_FAILURE,
    Error
>

export type RequestCourseAction = Action<FETCH_COURSE>
export type ReceiveCourseAction = ActionWithPayload<
    RECEIVE_COURSE,
    Resource<repr.Course.Base>
>
export type RequestCourseFailedAction = ActionWithError<
    FETCH_COURSE_FAILURE,
    Error
>

export type CourseAction =
    | RequestCoursesAction
    | ReceiveCoursesAction
    | RequestCoursesFailedAction
    | RequestCourseAction
    | ReceiveCourseAction
    | RequestCourseFailedAction

export function fetchCourses(): RestSideEffect<repr.Course.Base[]> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: FETCH_COURSES },
                { action: RECEIVE_COURSES },
                { action: FETCH_COURSES_FAILURE },
                () => api.getCourses()
            )
        )
    }
}

export function fetchCourse(
    courseId: string
): RestSideEffect<repr.Course.Base> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: FETCH_COURSE },
                { action: RECEIVE_COURSE },
                { action: FETCH_COURSE_FAILURE },
                () => api.getCourse(courseId)
            )
        )
    }
}
