import * as React from 'react'
import * as repr from '../../rest/representers'
import { Container, Confirm } from 'semantic-ui-react'
import { ThunkDispatch } from '../../actions/util'
import { replace as replaceRoute } from 'react-router-redux'
import {
    cancelSubscription,
    reactivateSubscription,
} from '../../actions/subscriptions'

import './Billing.scss'

interface PropsShape {
    dispatch: ThunkDispatch
    auth: repr.Auth.Base | null
}
interface StateShape {
    showConfirmCancellationMessage: boolean
    showConfirmReactivationMessage: boolean
}

export default class Billing extends React.Component<PropsShape, StateShape> {
    state: StateShape = {
        showConfirmCancellationMessage: false,
        showConfirmReactivationMessage: false,
    }

    render() {
        const {
            showConfirmCancellationMessage,
            showConfirmReactivationMessage,
        } = this.state
        return (
            <Container className="Billing">
                <h3 className="billing-header">Billing</h3>
                {this.billingSettings}
                <Confirm
                    open={showConfirmCancellationMessage}
                    onCancel={() =>
                        this.setState({ showConfirmCancellationMessage: false })
                    }
                    onConfirm={() => this.cancelSubscription()}
                    content="Are you sure you want to cancel your subscription?"
                    cancelButton="No"
                    confirmButton="Yes, cancel it"
                />

                <Confirm
                    open={showConfirmReactivationMessage}
                    onCancel={() =>
                        this.setState({ showConfirmReactivationMessage: false })
                    }
                    onConfirm={() => this.reactivateSubscription()}
                    content="Are you sure you want to reactivate your subscription?"
                    cancelButton="No"
                    confirmButton="Yes"
                />
            </Container>
        )
    }

    private get billingSettings() {
        const { auth } = this.props
        if (
            auth &&
            auth.user.stripe_subscription &&
            auth.user.stripe_subscription.is_active
        ) {
            return this.premiumMemberSettings
        }

        return this.freeMemberSettings
    }

    private get premiumMemberSettings() {
        const { auth } = this.props

        return (
            <>
                <div className="billing-status">
                    You are currently subscribed to the{' '}
                    <span className="billing-plan">
                        {auth &&
                            auth.user.stripe_subscription &&
                            auth.user.stripe_subscription.plan}
                    </span>
                    .
                </div>
                {auth &&
                auth.user.stripe_subscription &&
                !auth.user.stripe_subscription.cancel_at_period_end ? (
                    <>
                        <div className="renewal-message">
                            Your current subscription is set to renew on{' '}
                            {auth &&
                                auth.user.stripe_subscription
                                    .current_period_end}
                            .
                        </div>
                        <button
                            className="cancel-button"
                            onClick={() =>
                                this.setState({
                                    showConfirmCancellationMessage: true,
                                })
                            }
                        >
                            Cancel Subscription
                        </button>
                        <div className="cancellation-message">
                            Upon cancellation, your subscription will remain
                            active until the remainder of the billing cycle.
                        </div>
                    </>
                ) : (
                    <>
                        <div className="renewal-message">
                            Your current subscription is set to end on{' '}
                            {auth &&
                                auth.user.stripe_subscription &&
                                auth.user.stripe_subscription
                                    .current_period_end}
                            .
                        </div>
                        <button
                            className="upgrade-button"
                            onClick={() =>
                                this.setState({
                                    showConfirmReactivationMessage: true,
                                })
                            }
                        >
                            Reactivate Subscription
                        </button>
                        <div className="cancellation-message">
                            Upon reactivation, your subscription will remain
                            active and you will be billed at the start of the
                            next billing cycle.
                        </div>
                    </>
                )}
            </>
        )
    }

    private get freeMemberSettings() {
        return (
            <>
                <div className="billing-status">
                    You are currently on the free tier.
                </div>
                <button
                    className="upgrade-button"
                    onClick={this.goToUpgradePage}
                >
                    Upgrade to Premium
                </button>
            </>
        )
    }

    private goToUpgradePage = () => {
        const { dispatch } = this.props
        dispatch(replaceRoute('/upgrade'))
    }

    private cancelSubscription = () => {
        const { dispatch } = this.props
        dispatch(cancelSubscription()).then(() => {
            this.setState({
                showConfirmCancellationMessage: false,
            })
            window.location.reload()
        })
    }

    private reactivateSubscription = () => {
        const { dispatch } = this.props
        dispatch(reactivateSubscription()).then(() => {
            this.setState({
                showConfirmReactivationMessage: false,
            })
            window.location.reload()
        })
    }
}
