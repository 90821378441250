import * as React from 'react'
import * as repr from '../rest/representers'
import { Container } from 'semantic-ui-react'
import Helmet from 'react-helmet'
import { StateShape as ReduxStateShape } from '../reducers'
import { Link } from 'react-router-dom'
import { revealDropdownMenu, hideDropdownMenu } from '../actions/app'
import { CSSTransition } from 'react-transition-group'
import DropdownMenu from './DropdownMenu'
import { ThunkDispatch } from '../actions/util'
import { connect } from 'react-redux'
import { displayLogin } from '../actions/app'
import TopBar from './TopBar'

import './Contribute.scss'
import Footer from './Footer'

interface StateShape {
    showReferralCode: boolean
}

interface PropsShape {
    auth: repr.Auth.Base | null
    dispatch: ThunkDispatch
    isDropdownMenuExpanded: boolean
}

class Contribute extends React.Component<PropsShape, StateShape> {
    state: StateShape = {
        showReferralCode: false
    }

    render() {
        const { isDropdownMenuExpanded, auth } = this.props
        const { showReferralCode } = this.state
        return (
            <>
                <Helmet>
                    <title>Contribute material to earn credits.</title>
                    <meta
                        name="description"
                        content={'Contribute material to earn credits.'}
                    />
                </Helmet>
                <div className="Contribute">
                    <TopBar withSearch={true} logo="main" />
                    <CSSTransition
                        classNames="appear"
                        timeout={500}
                        in={isDropdownMenuExpanded}
                        unmountOnExit={true}
                    >
                        <DropdownMenu
                            onMenuItemClick={this.toggleDropdownMenu}
                        />
                    </CSSTransition>
                    <Container className="content">
                        <div className="contribution-header-and-link">
                            <h1 className="contribution-header">
                                Upload documents to fuel your education.
                            </h1>
                            <Link
                                to="/contribution-form"
                                className="contribution-form-link"
                            >
                                Get Started
                            </Link>
                        </div>

                        <div className="rewards-row">
                            <div className="upload-document-reward">
                                <span className="reward-title">
                                    Upload a Document
                                </span>
                                <a
                                    className="reward-link"
                                    href="/contribution-form"
                                >
                                    Upload Now
                                </a>
                            </div>

                            <div className="upgrade-friend-reward">
                                <span className="reward-title">
                                    Invite a Friend
                                </span>
                                {showReferralCode ? (
                                    <>
                                        <div className="referral-code">
                                            {auth && auth.user.referral_code}
                                        </div>
                                        <div className="referral-message">
                                            Make sure your friend uses this code
                                            when paying for their first
                                            subscription.
                                        </div>
                                    </>
                                ) : (
                                    <button
                                        className="referral-button"
                                        onClick={this.showReferralCode}
                                    >
                                        Invite
                                    </button>
                                )}
                            </div>
                        </div>
                    </Container>

                    {/* <Container className="contribution-value-props">
                        <h2 className="contribution-value-header">
                            What are credits for?
                        </h2>
                        <Grid.Row>
                            <Grid.Row columns={3}>
                                <Grid.Column className="value-prop">
                                    <h3 className="value-prop-header">
                                        Full Access
                                    </h3>
                                    <p className="value-prop-description">
                                        Get full access to solutions for every
                                        question.
                                    </p>
                                </Grid.Column>
                                <Grid.Column className="value-prop">
                                    <h3 className="value-prop-header">
                                        Test Simulator
                                    </h3>
                                    <p className="value-prop-description">
                                        Unlock extended features including
                                        resubmitting tests and viewing test
                                        history.
                                    </p>
                                </Grid.Column>
                                <Grid.Column className="value-prop">
                                    <h3 className="value-prop-header">
                                        Question Alerts (coming soon)
                                    </h3>
                                    <p className="value-prop-description">
                                        Receive daily alerts with questions
                                        based on your desired criteria.
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid.Row>
                    </Container> */}
                </div>

                <Footer />
            </>
        )
    }

    private toggleDropdownMenu = () => {
        const { isDropdownMenuExpanded, dispatch } = this.props
        if (isDropdownMenuExpanded) {
            dispatch(hideDropdownMenu())
        } else {
            dispatch(revealDropdownMenu())
        }
    }

    private showReferralCode = () => {
        const { auth, dispatch } = this.props
        if (!auth) {
            dispatch(displayLogin())
        } else {
            this.setState({ showReferralCode: true })
        }
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const isDropdownMenuExpanded = state.app.isDropdownMenuExpanded
    const auth = state.auth

    return {
        auth,
        isDropdownMenuExpanded
    }
}

export default connect(mapStateToProps)(Contribute)
