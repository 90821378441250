import * as React from 'react'
import * as lodash from 'lodash'
import * as repr from '../../rest/representers'
import { Container } from 'semantic-ui-react'
import { Test } from '../../models'
import TestQuestion from './TestQuestion'
import { ThunkDispatch } from '../../actions/util'
import { createTestAttempt } from '../../actions/testAttempts'
import { createTestAttemptResponse } from '../../actions/testAttemptResponses'
import { displayUpgrade } from '../../actions/app'

import './TestPage.scss'

interface PropsShape {
    dispatch: ThunkDispatch
    test: Test
    auth: repr.Auth.Base | null
}

interface StateShape {
    testResponses: { [k: string]: string }
    numberOfCorrectAnswers: number | null
    revealQuestionResults: boolean
    hasSubmitted: boolean
    errorMessage: string
}

export default class TestPage extends React.PureComponent<
    PropsShape,
    StateShape
> {
    state: StateShape = {
        testResponses: {},
        numberOfCorrectAnswers: null,
        revealQuestionResults: false,
        hasSubmitted: false,
        errorMessage: '',
    }

    componentDidUpdate(prevProps: PropsShape) {
        const { test } = this.props
        if (prevProps.test !== test) {
            this.setState({
                numberOfCorrectAnswers: null,
                revealQuestionResults: false,
            })
        }
    }

    render() {
        const { test, dispatch, auth } = this.props
        const {
            testResponses,
            numberOfCorrectAnswers,
            revealQuestionResults,
            hasSubmitted,
        } = this.state
        const isUserPremium =
            auth &&
            auth.user.stripe_subscription &&
            auth.user.stripe_subscription.is_active
        const inactivateSubmit =
            (test.data.test_attempts.length > 0 && !isUserPremium) ||
            (!isUserPremium && hasSubmitted)
        return (
            <Container className="TestPage">
                <div className="test-header">
                    <div className="test-info-container">
                        <div className="test-course">{`${test.data.course.course_code} - ${test.data.course.name}`}</div>
                        <div className="number-of-tries">{`Number of Attempts: ${
                            test.data.test_attempts.length
                                ? test.data.test_attempts.length
                                : 0
                        }`}</div>
                    </div>

                    <div className="test-results">
                        {!lodash.isNull(numberOfCorrectAnswers) ? (
                            <div className={`score ${this.scoreLevel}`}>
                                {`  ${numberOfCorrectAnswers}/${
                                    test.data.test_questions.length
                                } points (${
                                    (numberOfCorrectAnswers /
                                        test.data.test_questions.length) *
                                    100
                                }%)`}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>

                    <button
                        className={`submit-test-button ${
                            inactivateSubmit ? 'inactive' : ''
                        }`}
                        onClick={() => {
                            if (inactivateSubmit) {
                                dispatch(displayUpgrade())
                                return
                            }
                            this.submit()
                        }}
                    >
                        Submit
                    </button>
                </div>
                <div className="test-content">
                    {test.data.test_questions.length === 0 && this.noQuestions}
                    {test.data.test_questions.map((q) => (
                        <TestQuestion
                            testQuestion={q}
                            selectedAnswer={testResponses[q.id]}
                            onAnswerSelection={this.onAnswerSelection}
                            revealQuestionResults={revealQuestionResults}
                            dispatch={dispatch}
                            auth={auth}
                        />
                    ))}
                </div>
                <button
                    className={`submit-test-button-mobile ${
                        inactivateSubmit ? 'inactive' : ''
                    }`}
                    onClick={() => {
                        if (inactivateSubmit) {
                            dispatch(displayUpgrade())
                            return
                        }

                        this.submit()
                    }}
                >
                    Submit
                </button>
            </Container>
        )
    }

    private get scoreLevel() {
        const { test } = this.props
        const { numberOfCorrectAnswers } = this.state

        if (!numberOfCorrectAnswers) {
            return null
        }
        const percentage =
            numberOfCorrectAnswers / test.data.test_questions.length
        if (percentage >= 0.8) {
            return 'excellent'
        } else if (percentage >= 0.6) {
            return 'average'
        } else {
            return 'bad'
        }
    }

    private submit = () => {
        const { dispatch, test } = this.props
        const numberOfCorrectAnswers = this.calculateNumberOfCorrectAnswers()
        this.setState({
            numberOfCorrectAnswers,
            revealQuestionResults: true,
            hasSubmitted: true,
        })
        dispatch(createTestAttempt(test.data.id))
            .then((resp) => this.submitTestResponses(resp.data.id))
            .catch((e) => this.setState({ errorMessage: e.message }))
    }

    private submitTestResponses = (testAttemptId: string) => {
        const { dispatch } = this.props
        const { testResponses } = this.state
        lodash.forEach(testResponses, (answer, testQuestionId) => {
            dispatch(
                createTestAttemptResponse(testAttemptId, answer, testQuestionId)
            )
        })
    }

    private calculateNumberOfCorrectAnswers = () => {
        const { test } = this.props
        const { testResponses } = this.state
        let numberOfCorrectAnswers = 0
        lodash.forEach(testResponses, (answer, testQuestionId) => {
            const question = test.data.test_questions.find(
                (q) => q.id === testQuestionId
            )
            if (question && question.question.correct_answer.includes(answer)) {
                numberOfCorrectAnswers += 1
            }
        })
        return numberOfCorrectAnswers
    }

    private onAnswerSelection = (questionId: string, answer: string) => {
        const { testResponses } = this.state
        let newTestResponses = lodash.omit(
            testResponses as { [k: string]: string },
            questionId
        )

        newTestResponses[questionId as unknown as number] = answer

        this.setState({
            testResponses: newTestResponses,
            revealQuestionResults: false,
        })
    }

    private get noQuestions() {
        return (
            <div className="noQuestions">
                <img
                    className="illustration"
                    src="/not-found.svg"
                    alt="Not found icon"
                />
                <h3 className="no-questions-message">
                    Looks like this test is missing its questions
                </h3>
            </div>
        )
    }
}
