import * as React from 'react'
import * as repr from '../rest/representers'
import { connect } from 'react-redux'
import { replace as replaceRoute } from 'react-router-redux'
import { ThunkDispatch } from '../actions/util'
import { RouteComponentProps } from 'react-router'
import { Menu, Dropdown } from 'semantic-ui-react'
import { StateShape as ReduxStateShape } from '../reducers'
import { Link } from 'react-router-dom'
import SearchBar from './SearchBar'
import { withRouter } from 'react-router'
import * as H from 'history'
import {
    displayLogin,
    displaySignup,
    revealDropdownMenu,
    hideDropdownMenu,
} from '../actions/app'
import { deleteAuth } from '../actions/auth'
import { ReactSVG } from 'react-svg'
import './TopBar.scss'

interface StateShape {}

interface PropsShape {
    auth: repr.Auth.Base | null
    location: H.Location
    withSearch: boolean
    dispatch: ThunkDispatch
    isDropdownMenuExpanded: boolean
    logo: 'main' | 'alternate'
}

class TopBar extends React.Component<
    PropsShape & RouteComponentProps<null>,
    StateShape
> {
    render() {
        const { withSearch, auth, isDropdownMenuExpanded } = this.props
        return (
            <div
                className={`TopBar ${isDropdownMenuExpanded ? 'expanded' : ''}`}
            >
                {this.logoForMobile}
                {this.logo}
                {withSearch && <SearchBar />}
                <ul className="nav-container">
                    <li className="nav-item">
                        <Link to="/browse">Browse</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/test-yourself">Test Yourself</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/contribute">Contribute</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="upgrade-link" to="/upgrade">
                            <ReactSVG
                                className="bolt-icon"
                                src="/bolt-solid.svg"
                            />
                            <span>Upgrade</span>
                        </Link>
                    </li>
                    {auth ? (
                        <li className="nav-item logged-in-menu">
                            {this.loggedInMenu}
                        </li>
                    ) : (
                        <>
                            <li
                                className="nav-item"
                                onClick={this.showLoginModal}
                            >
                                Log in
                            </li>
                            <li
                                className="nav-item signup"
                                onClick={this.showSignupModal}
                            >
                                Sign up
                            </li>
                        </>
                    )}
                </ul>
            </div>
        )
    }

    get loggedInMenu() {
        const options = [
            {
                key: 1,
                text: 'Get Unlimited Access',
                value: 1,
                onClick: this.selectUpgrade,
            },
            {
                key: 2,
                text: 'Account Settings',
                value: 2,
                onClick: this.selectAccount,
            },
            { key: 3, text: 'Logout', value: 3, onClick: this.logout },
        ]
        return (
            <Menu compact>
                <Dropdown
                    options={options}
                    icon={
                        <ReactSVG className="user-avatar" src="/avatar.svg" />
                    }
                    direction="left"
                    simple
                    item
                />
            </Menu>
        )
    }

    get logo() {
        const { logo } = this.props
        const logoToUse = logo === 'main' ? '/logo-main.png' : '/logo-white.png'
        return (
            <Link to="https://intellyq.com" className="logo-desktop">
                <img
                    className="intellyq-logo"
                    src={logoToUse}
                    alt="IntellyQ logo"
                />
            </Link>
        )
    }

    get logoForMobile() {
        const { isDropdownMenuExpanded, logo } = this.props
        const logoToUse =
            logo === 'main' || isDropdownMenuExpanded
                ? '/intellyq-Q-logo-main.png'
                : '/intellyq-Q-logo-white.png'
        return (
            <div onClick={this.toggleDropdownMenu} className="logo-container">
                <div>
                    <img
                        className="intellyq-logo"
                        src={logoToUse}
                        alt="IntellyQ logo"
                    />
                </div>
                <img
                    className={`chevron ${
                        isDropdownMenuExpanded ? 'up' : 'down'
                    }`}
                    src="/chevron_down.svg"
                    alt="down icon"
                />
            </div>
        )
    }

    private selectUpgrade = () => {
        const { dispatch } = this.props
        dispatch(replaceRoute('/upgrade'))
    }

    private selectAccount = () => {
        const { dispatch } = this.props
        dispatch(replaceRoute('/account'))
    }

    private showSignupModal = () => this.props.dispatch(displaySignup())

    private showLoginModal = () => this.props.dispatch(displayLogin())

    private logout = () => {
        const { dispatch } = this.props
        dispatch(deleteAuth())
    }

    private toggleDropdownMenu = () => {
        const { isDropdownMenuExpanded, dispatch } = this.props
        if (isDropdownMenuExpanded) {
            dispatch(hideDropdownMenu())
        } else {
            dispatch(revealDropdownMenu())
        }
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const auth = state.auth
    const isDropdownMenuExpanded = state.app.isDropdownMenuExpanded
    return { auth, isDropdownMenuExpanded }
}

export default withRouter(connect(mapStateToProps)(TopBar))
