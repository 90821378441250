import * as React from 'react'
import { Menu } from 'semantic-ui-react'

import './FloatingMenuPill.scss'
import { ReactSVG } from 'react-svg'

interface StateShape {}
interface PropsShape {
    toggleFiltersPage: () => void
}

export default class FloatingMenuPill extends React.Component<
    PropsShape,
    StateShape
> {
    render() {
        const { toggleFiltersPage } = this.props
        return (
            <Menu className="FloatingMenuPill" as="button">
                <Menu.Item onClick={() => toggleFiltersPage()} as="button">
                    <ReactSVG
                        wrapper="span"
                        src="/filter-mobile.svg"
                        className="mobile-icon"
                    />
                    <span className="filter-pill-text">Show Filters</span>
                </Menu.Item>
            </Menu>
        )
    }
}
