import { makeRequest, RestSideEffect } from './util'
import * as api from '../rest/api'
import { Action, ActionWithError, ActionWithPayload } from './Action'
import Resource from '../rest/Resource'

export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION'
export type CREATE_SUBSCRIPTION = typeof CREATE_SUBSCRIPTION

export const RECEIVE_SUBSCRIPTION = 'RECEIVE_SUBSCRIPTION'
export type RECEIVE_SUBSCRIPTION = typeof RECEIVE_SUBSCRIPTION

export const CREATE_SUBSCRIPTION_FAILURE = 'CREATE_SUBSCRIPTION_FAILURE'
export type CREATE_SUBSCRIPTION_FAILURE = typeof CREATE_SUBSCRIPTION_FAILURE

export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'
export type CANCEL_SUBSCRIPTION = typeof CANCEL_SUBSCRIPTION

export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE'
export type CANCEL_SUBSCRIPTION_FAILURE = typeof CANCEL_SUBSCRIPTION_FAILURE

export const REACTIVATE_SUBSCRIPTION = 'REACTIVATE_SUBSCRIPTION'
export type REACTIVATE_SUBSCRIPTION = typeof REACTIVATE_SUBSCRIPTION

export const REACTIVATE_SUBSCRIPTION_FAILURE = 'REACTIVATE_SUBSCRIPTION_FAILURE'
export type REACTIVATE_SUBSCRIPTION_FAILURE = typeof REACTIVATE_SUBSCRIPTION_FAILURE

export type RequestSubscriptionActionType =
    | CREATE_SUBSCRIPTION
    | CANCEL_SUBSCRIPTION
    | REACTIVATE_SUBSCRIPTION
export type ReceiveSubscriptionActionType = RECEIVE_SUBSCRIPTION
export type RequestSubscriptionFailedActionType =
    | CREATE_SUBSCRIPTION_FAILURE
    | CANCEL_SUBSCRIPTION_FAILURE
    | REACTIVATE_SUBSCRIPTION_FAILURE

export type CreateSubscriptionAction = Action<CREATE_SUBSCRIPTION>
export type ReceiveSubscriptionAction = ActionWithPayload<
    RECEIVE_SUBSCRIPTION,
    Resource<{}>
>
export type CreateSubscriptionFailedAction = ActionWithError<
    CREATE_SUBSCRIPTION_FAILURE,
    Error
>

export type CancelSubscriptionAction = Action<CANCEL_SUBSCRIPTION>
export type CancelSubscriptionFailedAction = ActionWithError<
    CANCEL_SUBSCRIPTION_FAILURE,
    Error
>

export type ReactivateSubscriptionAction = Action<REACTIVATE_SUBSCRIPTION>
export type ReactivateSubscriptionFailedAction = ActionWithError<
    REACTIVATE_SUBSCRIPTION_FAILURE,
    Error
>

export type SubscriptionAction =
    | CreateSubscriptionAction
    | ReceiveSubscriptionAction
    | CreateSubscriptionFailedAction
    | CancelSubscriptionAction
    | CancelSubscriptionFailedAction
    | ReactivateSubscriptionAction
    | ReactivateSubscriptionFailedAction

export function createSubscription(
    billingPlan: string,
    referralCode: string,
    stripeToken: string
): RestSideEffect<{}> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: CREATE_SUBSCRIPTION },
                { action: RECEIVE_SUBSCRIPTION },
                { action: CREATE_SUBSCRIPTION_FAILURE },
                ({ authToken }) =>
                    api.postSubscription(
                        billingPlan,
                        referralCode,
                        stripeToken,
                        authToken
                    )
            )
        )
    }
}

export function cancelSubscription(): RestSideEffect<{}> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: CANCEL_SUBSCRIPTION },
                { action: RECEIVE_SUBSCRIPTION },
                { action: CANCEL_SUBSCRIPTION_FAILURE },
                ({ authToken }) => api.deleteSubscription(authToken)
            )
        )
    }
}

export function reactivateSubscription(): RestSideEffect<{}> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: REACTIVATE_SUBSCRIPTION },
                { action: RECEIVE_SUBSCRIPTION },
                { action: REACTIVATE_SUBSCRIPTION_FAILURE },
                ({ authToken }) => api.putSubscription(authToken)
            )
        )
    }
}
