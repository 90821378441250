import * as React from 'react'
import { connect } from 'react-redux'
import { push as pushRoute } from 'react-router-redux'
import { StateShape as ReduxStateShape } from '../reducers'
import { Container, Input, Header, Button } from 'semantic-ui-react'
import { ThunkDispatch } from '../actions/util'
import {
    fetchPasswordResetToken,
    updatePasswordResetToken,
} from '../actions/passwordResetTokens'
import { match as MatchShape } from 'react-router'
import TopBar from './TopBar'
import './PasswordResetPage.scss'

interface StateShape {
    password: string
    confirmPassword: string
    errorMessage: string | null
}

interface PassedProps {
    match: MatchShape<{ token: string }>
}

interface InjectedProps {
    dispatch: ThunkDispatch
}

type PropsShape = PassedProps & InjectedProps

class PasswordResetPage extends React.Component<PropsShape, StateShape> {
    state = {
        password: '',
        confirmPassword: '',
        errorMessage: null,
    }

    componentDidMount() {
        const { dispatch, match } = this.props
        dispatch(fetchPasswordResetToken(match.params.token)).catch((e) =>
            this.setState({ errorMessage: e.message })
        )
        document.addEventListener('keydown', this.handleEnterKeyPress, false)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEnterKeyPress, false)
    }

    render() {
        const { errorMessage, password } = this.state
        return (
            <>
                <TopBar withSearch={true} logo="main" />
                <div className="PasswordResetPage">
                    <Header.Subheader as="h2" className="page-header">
                        Password Reset
                    </Header.Subheader>
                    <Container className="reset-message" text={true}>
                        Enter your new password
                    </Container>
                    <Input
                        onChange={this.handlePasswordOneChange}
                        placeholder="Password"
                        icon="lock"
                        type="password"
                    />
                    {!this.isPasswordValid(password) && (
                        <div className="password-validation-message">
                            Your password must contain at least 8 characters
                        </div>
                    )}
                    <Input
                        onChange={this.handlePasswordTwoChange}
                        placeholder="Confirm Password"
                        icon="lock"
                        type="password"
                    />
                    <Button
                        className="reset-password-button"
                        onClick={() => {
                            if (!this.isPasswordValid(password)) {
                                return
                            }
                            this.handlePasswordReset()
                        }}
                    >
                        Save
                    </Button>
                    {errorMessage && (
                        <p className="error-message"> {errorMessage} </p>
                    )}
                </div>
            </>
        )
    }

    private isPasswordValid = (password: string) => {
        if (password === '') {
            return true
        }
        return password.length >= 8
    }

    private handlePasswordOneChange = (
        e: React.SyntheticEvent<HTMLInputElement>
    ) => {
        this.setState({ password: e.currentTarget.value })
    }

    private handlePasswordTwoChange = (
        e: React.SyntheticEvent<HTMLInputElement>
    ) => {
        this.setState({ confirmPassword: e.currentTarget.value })
    }

    private handlePasswordReset = () => {
        const { dispatch, match } = this.props
        const { password, confirmPassword } = this.state
        if (password === confirmPassword) {
            dispatch(updatePasswordResetToken(match.params.token, password))
                .then(() => dispatch(pushRoute('/browse')))
                .catch((e) => this.setState({ errorMessage: e.message }))
        } else {
            this.setState({ errorMessage: 'Your passwords do not match.' })
        }
    }

    private handleEnterKeyPress = (evt: KeyboardEvent) => {
        if (evt.keyCode === 13) {
            this.handlePasswordReset()
        }
    }
}

function mapStateToProps(state: ReduxStateShape, props: PropsShape) {}

export default connect(mapStateToProps)(PasswordResetPage)
