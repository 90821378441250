import * as React from 'react'
import * as repr from '../rest/representers'
import * as ReactDOM from 'react-dom'
import { ThunkDispatch } from '../actions/util'
import { Grid } from 'semantic-ui-react'
import QuestionCard from './QuestionCard'
import { QuestionDetailed, QuestionBare } from '../models'
import { Pagination, PaginationProps } from 'semantic-ui-react'
import { PaginationInfo } from '../reducers/remote/pagination'
import { Pagination as RestPagination } from '../rest/api'

import './QuestionList.scss'

interface StateShape {}
interface PropsShape {
    auth: repr.Auth.Base | null
    dispatch: ThunkDispatch
    questions: (QuestionDetailed | QuestionBare)[]
    paginationInfo?: PaginationInfo
    fetchPage?: (page: RestPagination) => Promise<void | {}>
}

export default class QuestionList extends React.Component<
    PropsShape,
    StateShape
> {
    render() {
        const { questions, paginationInfo, fetchPage, auth, dispatch } =
            this.props
        return (
            <div className="QuestionList">
                <Grid.Row columns={1} centered={true}>
                    {questions.map((q) => (
                        <QuestionCard
                            key={q.data.id}
                            question={q}
                            clickable={true}
                            auth={auth}
                            dispatch={dispatch}
                        />
                    ))}
                </Grid.Row>
                {paginationInfo && fetchPage && (
                    <Pagination
                        className="question-pagination"
                        totalPages={paginationInfo.pages}
                        pointing={true}
                        secondary={true}
                        activePage={paginationInfo.page}
                        firstItem={null}
                        lastItem={null}
                        onPageChange={this.handlePageChange}
                    />
                )}
            </div>
        )
    }

    handlePageChange = (
        e: React.MouseEvent<HTMLAnchorElement>,
        p: PaginationProps
    ) => {
        const { fetchPage, paginationInfo } = this.props
        fetchPage!({
            page: p.activePage as number,
            pageSize: paginationInfo!.pageSize,
        }).then(() => {
            // react keeps the scroll offset as is when new content is loaded.
            // we scroll to the top of the properties list for a better user experience
            const node = ReactDOM.findDOMNode(this) as HTMLDivElement
            if (node) {
                node.scrollTop = 0
            }
        })
    }
}
