import * as React from 'react'
import { Input, Container } from 'semantic-ui-react'
import { DocumentInfo } from '../../containers/ContributionForm'

import './DocumentInfoPicker.scss'

interface PropsShape {
    documentInfo: DocumentInfo
    updateDocumentInfo: (update: Partial<DocumentInfo>) => void
    nextStep: () => void
}

export default class DocumentInfoPicker extends React.Component<PropsShape> {
    render() {
        const { documentInfo, updateDocumentInfo, nextStep } = this.props
        return (
            <Container className="DocumentInfoPicker">
                <h2 className="document-info-header">
                    Let's get some details about your contribution
                </h2>
                <div className="input-row">
                    <div className="input-section">
                        <h5 className="university">University Name</h5>
                        <Input
                            className="university-name-input"
                            onChange={e =>
                                updateDocumentInfo({
                                    universityName: e.currentTarget.value
                                })
                            }
                            placeholder="University"
                            value={documentInfo.universityName}
                        />
                    </div>

                    <div className="input-section">
                        <h5 className="course">Course Name</h5>
                        <Input
                            className="course-name-input"
                            onChange={e =>
                                updateDocumentInfo({
                                    courseName: e.currentTarget.value
                                })
                            }
                            placeholder="Course"
                            value={documentInfo.courseName}
                        />
                    </div>
                </div>

                <div className="input-row">
                    <div className="input-section">
                        <h5 className="year">Year</h5>
                        <Input
                            className="year-name-input"
                            onChange={e =>
                                updateDocumentInfo({
                                    year: e.currentTarget.value
                                })
                            }
                            placeholder="Year"
                            value={documentInfo.year}
                        />
                    </div>
                    <div className="input-section">
                        <h5 className="document-description">
                            Document Description
                        </h5>
                        <Input
                            className="description-input"
                            onChange={e =>
                                updateDocumentInfo({
                                    description: e.currentTarget.value
                                })
                            }
                            placeholder="Description"
                            value={documentInfo.description}
                        />
                    </div>
                </div>

                <div className="one-contribution-message">
                    *Please ensure only one contribution per submission
                </div>

                <Container className="form-button-container">
                    <div
                        onClick={() => {
                            this.isPageComplete() && nextStep()
                        }}
                        className={
                            this.isPageComplete()
                                ? 'next-button'
                                : 'next-button-disabled'
                        }
                    >
                        Next
                    </div>
                </Container>
            </Container>
        )
    }

    isPageComplete = () => {
        const { documentInfo } = this.props
        return (
            documentInfo.universityName !== '' &&
            documentInfo.courseName !== '' &&
            documentInfo.year !== '' &&
            documentInfo.description !== ''
        )
    }
}
