import { Action, ActionWithError, ActionWithPayload } from './Action'
import Resource from '../rest/Resource'
import { makeRequest, RestSideEffect } from './util'
import * as repr from '../rest/representers'
import * as api from '../rest/api'

const REQUEST_SEARCH = 'REQUEST_SEARCH'
type REQUEST_SEARCH = typeof REQUEST_SEARCH

export const RECEIVE_SEARCH_RESULTS = 'RECEIVE_SEARCH_RESULTS'
export type RECEIVE_SEARCH_RESULTS = typeof RECEIVE_SEARCH_RESULTS

const SEARCH_FAILURE = 'SEARCH_FAILURE'
type SEARCH_FAILURE = typeof SEARCH_FAILURE

export type RequestSearchAction = Action<REQUEST_SEARCH>
export type ReceiveSearchResultsAction = ActionWithPayload<
    RECEIVE_SEARCH_RESULTS,
    Resource<(repr.University.Base | repr.Course.Bare | repr.Question.Bare)[]>
>
export type SearchFailureAction = ActionWithError<SEARCH_FAILURE, Error>

export type RequestSearchActionType = REQUEST_SEARCH
export type ReceiveSearchActionType = RECEIVE_SEARCH_RESULTS
export type RequestSearchFailedActionType = SEARCH_FAILURE

export type SearchAction =
    | RequestSearchAction
    | ReceiveSearchResultsAction
    | SearchFailureAction

export function search(
    text: string
): RestSideEffect<
    (repr.University.Base | repr.Course.Bare | repr.Question.Bare)[]
> {
    return dispatch => {
        return dispatch(
            makeRequest(
                { action: REQUEST_SEARCH },
                { action: RECEIVE_SEARCH_RESULTS },
                { action: SEARCH_FAILURE },
                () => api.search(text)
            )
        )
    }
}
