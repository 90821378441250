import * as React from 'react'
import { Input, Container, Divider } from 'semantic-ui-react'
import { AccountInfo } from '../../containers/AccountPage'

import './Settings.scss'
import { ReactSVG } from 'react-svg'

interface PropsShape {
    accountInfo: AccountInfo
    updateAccountSettings: (update: Partial<AccountInfo>) => void
    onSave: () => void
    updateSuccessful: boolean
    errorMessage: string
}

interface StateShape {}

export default class Settings extends React.Component<PropsShape, StateShape> {
    render() {
        const {
            accountInfo,
            onSave,
            updateAccountSettings,
            errorMessage,
            updateSuccessful,
        } = this.props
        return (
            <Container className="Settings">
                <h3 className="settings-header">Account</h3>
                <div className="input-row">
                    <div className="input-section">
                        <h5 className="name">Email</h5>
                        <div className="email-display">{accountInfo.email}</div>
                    </div>
                </div>
                <div className="input-row">
                    <div className="input-section">
                        <h5 className="name">First Name</h5>
                        <Input
                            className="name-input"
                            onChange={(e) =>
                                updateAccountSettings({
                                    firstName: e.currentTarget.value,
                                })
                            }
                            placeholder="First Name"
                            value={accountInfo.firstName}
                        />
                    </div>
                    <div className="input-section">
                        <h5 className="name">Last Name</h5>
                        <Input
                            className="name-input"
                            onChange={(e) =>
                                updateAccountSettings({
                                    lastName: e.currentTarget.value,
                                })
                            }
                            placeholder="Last Name"
                            value={accountInfo.lastName}
                        />
                    </div>
                </div>

                <Divider />
                <h3 className="settings-header">Security</h3>
                <div className="input-row">
                    <div className="input-section">
                        <h5 className="password">New Password</h5>
                        <Input
                            className="password-input"
                            onChange={(e) =>
                                updateAccountSettings({
                                    newPassword: e.currentTarget.value,
                                })
                            }
                            placeholder="New Password"
                            type="password"
                        />
                    </div>
                    <div className="input-section">
                        <h5 className="name">Confirm New Password</h5>
                        <Input
                            className="name-input"
                            onChange={(e) =>
                                updateAccountSettings({
                                    newPasswordConfirm: e.currentTarget.value,
                                })
                            }
                            placeholder="New Password Confirm"
                            type="password"
                        />
                    </div>
                </div>
                {!this.isPasswordValid(accountInfo.newPassword) && (
                    <div className="password-validation-message">
                        Your password must contain at least 8 characters
                    </div>
                )}

                <Divider />
                <button
                    className="save-button"
                    onClick={() => {
                        if (!this.isPasswordValid(accountInfo.newPassword)) {
                            return
                        }
                        onSave()
                    }}
                >
                    Save
                </button>

                {updateSuccessful && (
                    <div className="success-message">
                        <ReactSVG
                            wrapper="span"
                            src="/checkmark.svg"
                        />
                        Your account has been updated
                    </div>
                )}
                <div className="error-message">{errorMessage}</div>
            </Container>
        )
    }

    private isPasswordValid = (password: string) => {
        if (password === '') {
            return true
        }
        return password.length >= 8
    }
}
