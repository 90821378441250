import * as React from 'react'
import { Container, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import "./SuccessPage.scss"

interface PropsShape {}

export default class SubmitPage extends React.Component<PropsShape> {
    render() {
        return (
            <div className="SuccessPage">
                <Container className="success-container">
                    <h4>Thank you! We've received your contribution.</h4>
                    <h5>
                        Your contribution will be reviewed and your account will be credited.
                    </h5>
                    <div className="links">
                        <Link to="/browse">
                            <a>
                                Browse Questions
                                <Icon name="long arrow alternate right" />
                            </a>
                        </Link>
                    </div>
                </Container>
            </div>
        )
    }
}
