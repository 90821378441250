import * as React from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from '../actions/util'
import { StateShape as ReduxStateShape } from '../reducers'
import { Container, Input, Header, Button } from 'semantic-ui-react'
import { closeModal, displaySignup, displayPasswordReset } from '../actions/app'
import { createAuth } from '../actions/auth'
import { replace as replaceRoute } from 'react-router-redux'
import './Login.scss'

interface StateShape {
    email: string
    password: string
    errorMessage: string | null
}

interface PropsShape {
    dispatch: ThunkDispatch
    signupAsRedirect?: boolean
    passwordResetAsRedirect?: boolean
    onClose?: () => void
    onLogin?: () => void
}

class Login extends React.Component<PropsShape, StateShape> {
    state = {
        email: '',
        password: '',
        errorMessage: null,
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleEnterKeyPress, false)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEnterKeyPress, false)
    }

    render() {
        const { errorMessage } = this.state
        return (
            <div className="Login">
                <img
                    className="close-icon"
                    src="/close.svg"
                    alt="close icon"
                    onClick={this.closeModal}
                />
                <div className="content">
                    <Header.Subheader as="h2" className="modal-header">
                        Log In
                    </Header.Subheader>
                    <Container className="login-message" text={true}>
                        Login to get full access to solutions, daily questions
                        and much more!
                    </Container>
                    <Input
                        onChange={this.handleEmailChange}
                        placeholder="Email Address"
                        type="email"
                    />
                    <Input
                        onChange={this.handlePasswordChange}
                        placeholder="Password"
                        type="password"
                    />
                    <Button
                        className="signup-login-button"
                        onClick={this.login}
                    >
                        Login
                    </Button>
                    <Container className="switch-message" text={true}>
                        <div>
                            New to IntellyQ?{' '}
                            <span
                                className="sign-up"
                                onClick={this.showSignupModal}
                            >
                                Sign Up
                            </span>
                        </div>
                        <div>
                            <span
                                className="sign-up"
                                onClick={this.showPasswordResetModal}
                            >
                                Forgot your password?
                            </span>
                        </div>
                    </Container>
                    {errorMessage && (
                        <p className="error-message"> {errorMessage} </p>
                    )}
                </div>
            </div>
        )
    }

    handleEmailChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({ email: e.currentTarget.value })
    }

    handlePasswordChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({ password: e.currentTarget.value })
    }

    closeModal = () => {
        const { dispatch, onClose } = this.props
        if (onClose) {
            onClose()
        }

        dispatch(closeModal())
    }

    login = () => {
        const { dispatch, onLogin } = this.props
        const { email, password } = this.state
        const lowerCaseEmail = email.toLowerCase();
        dispatch(createAuth(lowerCaseEmail, password))
            .then(() => {
                if (onLogin) {
                    onLogin()
                }

                dispatch(closeModal())
            })
            .catch((e) => this.setState({ errorMessage: e.message }))
    }

    showSignupModal = () => {
        const { signupAsRedirect, dispatch } = this.props
        if (signupAsRedirect) {
            dispatch(replaceRoute('/app/signup'))
        } else {
            dispatch(displaySignup())
        }
    }

    private showPasswordResetModal = () => {
        const { dispatch, passwordResetAsRedirect } = this.props
        if (passwordResetAsRedirect) {
            dispatch(replaceRoute('/password-reset'))
        } else {
            dispatch(displayPasswordReset())
        }
    }

    handleEnterKeyPress = (evt: KeyboardEvent) => {
        if (evt.keyCode === 13) {
            this.login()
        }
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    return {}
}

export default connect(mapStateToProps)(Login)
