import * as React from 'react'
import { Container } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { StateShape as ReduxStateShape } from '../reducers'
import TopBar from './TopBar'
import Footer from './Footer'
import { CSSTransition } from 'react-transition-group'
import DropdownMenu from './DropdownMenu'
import { ThunkDispatch } from '../actions/util'
import { connect } from 'react-redux'
import { revealDropdownMenu, hideDropdownMenu } from '../actions/app'

import './Upgrade.scss'
import { ReactSVG } from 'react-svg'

interface StateShape {}
interface PropsShape {
    dispatch: ThunkDispatch
    isDropdownMenuExpanded: boolean
}

class Upgrade extends React.Component<PropsShape, StateShape> {
    render() {
        const { isDropdownMenuExpanded } = this.props
        return (
            <Container className="Upgrade">
                <TopBar withSearch={true} logo="main" />
                <CSSTransition
                    classNames="appear"
                    timeout={500}
                    in={isDropdownMenuExpanded}
                    unmountOnExit={true}
                >
                    <DropdownMenu onMenuItemClick={this.toggleDropdownMenu} />
                </CSSTransition>
                <Container className="content">
                    <h1 className="upgrade-header">Get Unlimited Access</h1>
                    <h4 className="upgrade-subheader">
                        Unlock answers for any question, full access to the test
                        simulator, as well as our AI teaching assistant.
                    </h4>
                    <Container className="pricing">
                        <Container className="premium-monthly">
                            <h3 className="pricing-header">Monthly</h3>
                            <span className="price-per-month">
                                <span className="price">9.99</span> / month
                            </span>
                            {this.pricingProps}
                            <span className="billing-info">Billed monthly</span>
                            <Link
                                className="upgrade-button"
                                to="upgrade-form?plan=monthly"
                            >
                                Upgrade to Premium
                            </Link>
                        </Container>
                        <Container className="premium-yearly">
                            <span className="most-popular">Highest Value</span>
                            <h3 className="pricing-header">Yearly</h3>
                            <span className="price-per-month">
                                <span className="price">4.99</span> / month
                            </span>
                            {this.pricingProps}
                            <span className="billing-promo">Save 50%</span>
                            <span className="billing-info">
                                Billed 59.88 annually
                            </span>
                            <Link
                                className="upgrade-button"
                                to="upgrade-form?plan=yearly"
                            >
                                Upgrade to Premium
                            </Link>
                        </Container>
                        <Container className="premium-quarterly">
                            <h3 className="pricing-header">Quarterly</h3>
                            <span className="price-per-month">
                                <span className="price">7.49</span> / month
                            </span>
                            {this.pricingProps}
                            <span className="billing-promo">Save 25%</span>
                            <span className="billing-info">
                                Billed 22.47 every 3 months
                            </span>
                            <Link
                                className="upgrade-button"
                                to="upgrade-form?plan=quarterly"
                            >
                                Upgrade to Premium
                            </Link>
                        </Container>
                    </Container>
                </Container>
                <Footer />
            </Container>
        )
    }

    private get pricingProps() {
        return (
            <>
                <Container className="price-details">
                    <ReactSVG
                        wrapper="span"
                        className="checkmark-icon"
                        src="/checkmark.svg"
                    />
                    <h3 className="offered">
                        Full access to question solutions
                    </h3>
                </Container>
                <Container className="price-details">
                    <ReactSVG
                        wrapper="span"
                        className="checkmark-icon"
                        src="/checkmark.svg"
                    />
                    <h3 className="offered">
                        Resubmit and track your progress in the test
                        simulator
                    </h3>
                </Container>
                <Container className="price-details">
                    <ReactSVG
                        wrapper="span"
                        className="checkmark-icon"
                        src="/checkmark.svg"
                    />
                    <h3 className="offered">
                        Access to full solutions from our AI teaching assistant
                    </h3>
                </Container>
            </>
        )
    }

    private toggleDropdownMenu = () => {
        const { isDropdownMenuExpanded, dispatch } = this.props
        if (isDropdownMenuExpanded) {
            dispatch(hideDropdownMenu())
        } else {
            dispatch(revealDropdownMenu())
        }
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const isDropdownMenuExpanded = state.app.isDropdownMenuExpanded

    return {
        isDropdownMenuExpanded,
    }
}

export default connect(mapStateToProps)(Upgrade)
