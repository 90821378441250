import * as React from 'react'
import HomePage from './HomePage'
import Contribute from './Contribute'
import ContributionForm from './ContributionForm'
import Upgrade from './Upgrade'
import Page404 from './Page404'
import App from './App'
import AccountPage from './AccountPage'
import UpgradeForm from './UpgradeForm'
import PasswordResetPage from './PasswordResetPage'
import EmailConfirmedPage from './EmailConfirmedPage'
import PrivacyPolicy from './PrivacyPolicy'
import ToS from './ToS'
import TestSimulatorPage from './TestSimulatorPage'
import Sitemap from './Sitemap'
import QuestionDetailsPage from './QuestionDetailsPage'
import { Switch, Route, Redirect } from 'react-router'
import TestPackPage from './TestPackPage'

interface StateShape {}
interface PropsShape {}

export default class RootRouter extends React.Component<
    PropsShape,
    StateShape
> {
    render() {
        return (
            <Switch>
                <Route path="/browse" component={App} />
                <Route
                    path="/question/:questionText"
                    component={QuestionDetailsPage}
                />
                <Route
                    path="/test-pack/:university/:course/:testType"
                    component={TestPackPage}
                />
                <Route path="/contribute" component={Contribute} />
                <Route path="/contribution-form" component={ContributionForm} />
                <Route path="/upgrade" component={Upgrade} />
                <Route path="/account" component={AccountPage} />
                <Route path="/upgrade-form" component={UpgradeForm} />
                <Route path="/test-yourself" component={TestSimulatorPage} />
                <Route
                    path="/password-reset/:token"
                    component={PasswordResetPage}
                />
                <Route
                    path="/confirm-email/:token"
                    component={EmailConfirmedPage}
                />
                <Route path="/404" component={Page404} />
                <Route path="/" exact={true} component={HomePage} />
                <Route path="/terms-of-service" component={ToS} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/sitemap" component={Sitemap} />
                <Redirect to="/404" />
            </Switch>
        )
    }
}
