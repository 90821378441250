import * as React from 'react'
import * as repr from '../rest/representers'
import { Elements, StripeProvider } from 'react-stripe-elements'
import PaymentForm from '../components/UpgradeForm/PaymentForm'
import {
    Dropdown,
    Transition,
    Button,
    Input,
    Container,
} from 'semantic-ui-react'
import * as qs from 'qs'
import { displayLogin } from '../actions/app'
import { connect } from 'react-redux'
import { ThunkDispatch } from '../actions/util'
import { StateShape as ReduxStateShape } from '../reducers'
import { Link } from 'react-router-dom'
import TopBar from './TopBar'
// import ConfirmYourEmail from '../components/ConfirmYourEmail'
import config from '../app.config'

import './UpgradeForm.scss'
import { ReactSVG } from 'react-svg'

const paymentOptions = [
    {
        key: 'yearly',
        text: 'Yearly - $59.88',
        value: 'yearly',
    },
    {
        key: 'monthly',
        text: 'Monthly - $9.99',
        value: 'monthly',
    },
    {
        key: 'quarterly',
        text: 'Quarterly - $22.47',
        value: 'quarterly',
    },
]

const paymentMessages = {
    yearly: '12 months of unlimited access',
    monthly: '1 month of unlimited access',
    quarterly: '3 months of unlimited access',
}

const paymentPricing = {
    yearly: '$59.88',
    monthly: '$9.99',
    quarterly: '$22.47',
}

interface PropsShape {
    dispatch: ThunkDispatch
    auth: repr.Auth.Base | null
}

interface StateShape {
    billingPlan: 'yearly' | 'monthly' | 'quarterly'
    hasReferral: boolean
    referralCode: string
    showMobileDetails: boolean
}

class UpgradeForm extends React.Component<PropsShape, StateShape> {
    constructor(props: PropsShape) {
        super(props)

        const query = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })
        this.state = {
            billingPlan: query.plan as 'yearly' | 'monthly' | 'quarterly',
            hasReferral: false,
            referralCode: '',
            showMobileDetails: false,
        }
    }

    render() {
        const { dispatch, auth } = this.props
        const { billingPlan, showMobileDetails, referralCode } = this.state

        if (!auth) {
            dispatch(displayLogin())
            return (
                <Container className="payment-success-container">
                    <TopBar withSearch={true} logo="main" />
                    <div className="payment-success-message">
                        Please log in to continue.
                        <button
                            onClick={() => dispatch(displayLogin())}
                            className="login-button"
                        >
                            login
                        </button>
                    </div>
                </Container>
            )
        }

        // if (!auth.user.email_confirmed) {
        //     return <ConfirmYourEmail auth={auth} dispatch={dispatch} />
        // }

        if (
            auth.user.stripe_subscription &&
            auth.user.stripe_subscription.is_active
        ) {
            return (
                <Container className="payment-success-container">
                    <TopBar withSearch={true} logo="main" />
                    <div className="payment-success-message">
                        <ReactSVG
                            wrapper="div"
                            className="payment-success-icon"
                            src="/checkmark.svg"
                        />
                        You are currently subscribed to the{' '}
                        <span className="plan-name">
                            {auth.user.stripe_subscription.plan}
                        </span>
                        .
                        <a href="/browse" className="browse-link">
                            Continue to Browse
                        </a>
                    </div>
                </Container>
            )
        }

        return (
            <div className="UpgradeForm">
                <div className="checkout-container">
                    <div className="intellyq-checkout-logo-container mobile">
                        <Link to="https://intellyq.com" className="intellyq-checkout-logo-link">
                            <img
                                className="intellyq-checkout-logo"
                                src="/logo-main.png"
                                alt="Intellyq logo"
                            />
                        </Link>
                    </div>

                    <div
                        className="mobile-details-dropdown-button"
                        onClick={() =>
                            this.setState({
                                showMobileDetails: !showMobileDetails,
                            })
                        }
                    >
                        Show Summary{' '}
                        <img
                            className={`show-details-chevron ${
                                showMobileDetails ? 'up' : ''
                            }`}
                            src="/chevron_down.svg"
                            alt="Down icon"
                        />
                        <span className="total-price">
                            {paymentPricing[billingPlan]}
                        </span>
                    </div>

                    <Transition
                        visible={showMobileDetails}
                        animation="slide down"
                        duration={500}
                    >
                        <div className="checkout-details mobile">
                            {this.checkoutDetails}
                        </div>
                    </Transition>
                    <StripeProvider apiKey={config.stripeKey}>
                        <div className="checkout-form">
                            <div className="intellyq-checkout-logo-container desktop">
                                <Link
                                    to="https://intellyq.com"
                                    className="intellyq-checkout-logo-link"
                                >
                                    <img
                                        className="intellyq-checkout-logo"
                                        src="/logo-main.png"
                                        alt="Intellyq logo"
                                    />
                                </Link>
                            </div>
                            <div className="payment-steps">
                                <Link
                                    to="/upgrade"
                                    className="choose-plan-link"
                                >
                                    1. Choose Plan
                                </Link>
                                <Link
                                    to="#"
                                    className="payment-method-link"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    2. Payment Method
                                </Link>
                            </div>
                            <Elements>
                                <PaymentForm
                                    billingPlan={billingPlan}
                                    referralCode={referralCode}
                                    dispatch={dispatch}
                                    auth={auth}
                                />
                            </Elements>
                        </div>
                    </StripeProvider>

                    <div className="checkout-details desktop">
                        {this.checkoutDetails}
                    </div>
                </div>
            </div>
        )
    }

    private get checkoutDetails() {
        const { billingPlan, hasReferral, referralCode } = this.state
        return (
            <>
                <Dropdown
                    className="plan-dropdown"
                    placeholder="Select Plan"
                    fluid
                    selection
                    options={paymentOptions}
                    value={billingPlan}
                    onChange={(e, d) =>
                        this.setState({
                            billingPlan: d.value as
                                | 'yearly'
                                | 'monthly'
                                | 'quarterly',
                        })
                    }
                />
                <p className="plan-message">{paymentMessages[billingPlan]}</p>
                <hr className="payment-divider" />

                <Button
                    className="referral-button"
                    content={
                        hasReferral
                            ? 'Have a Referral Code?'
                            : 'Have a Referral Code?'
                    }
                    onClick={() => this.setState({ hasReferral: !hasReferral })}
                />
                <Transition
                    visible={hasReferral}
                    animation="slide down"
                    duration={500}
                >
                    <Input
                        placeholder="Referral Code"
                        onChange={(e) =>
                            this.setState({
                                referralCode: e.currentTarget.value,
                            })
                        }
                        value={referralCode}
                    />
                </Transition>

                <hr className="payment-divider" />
                <div className="total-message">
                    Total{' '}
                    <span className="total-price">
                        {paymentPricing[billingPlan]}
                    </span>
                </div>
                <p className="plan-renewal-message">
                    This plan will auto renew at the end of each cycle. If you
                    wish to discontinue your recurring payment, you can cancel
                    it at anytime before each cycle under Billing Settings.
                    <br />
                    <br />
                    All prices shown are in Canadian Dollars.
                </p>
                <hr className="payment-divider" />
                <div className="stripe-badge-container">
                    <img
                        className="stripe-badge"
                        src="/powered_by_stripe.svg"
                        alt="Stripe badge"
                    />
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const auth = state.auth
    return { auth }
}

export default connect(mapStateToProps)(UpgradeForm)
