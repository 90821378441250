import * as React from 'react'
import { QuestionDetailed, QuestionBare } from '../../models'
import { Container } from 'semantic-ui-react'
import QuestionCard from '../QuestionCard'
import * as repr from '../../rest/representers'
import { ThunkDispatch } from '../../actions/util'
import ContentSlider from '../ContentSlider'

import './SimilarQuestions.scss'

interface StateShape {}

interface PropsShape {
    questions: (QuestionDetailed | QuestionBare)[]
    auth: repr.Auth.Base | null
    dispatch: ThunkDispatch
}

const NUMBER_OF_CARDS = 5
const CARD_WIDTH = 780

export default class SimilarQuestions extends React.Component<
    PropsShape,
    StateShape
> {
    render() {
        const { questions, dispatch, auth } = this.props
        return (
            <Container className="SimilarQuestions">
                <h3 className="header">Similar Questions</h3>
                <ContentSlider
                    numerOfItems={NUMBER_OF_CARDS}
                    itemWidth={CARD_WIDTH}
                >
                    {questions.map(q => (
                        <QuestionCard
                            key={q.data.id}
                            question={q}
                            dispatch={dispatch}
                            auth={auth}
                            clickable={true}
                        />
                    ))}
                </ContentSlider>
            </Container>
        )
    }
}
